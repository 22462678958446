import { FocusEventHandler, PureComponent } from 'react'
import { FieldState, FormFieldEmitter } from '../form/context'
import { Emmitter } from '../form/form'

export interface BaseInputProps<T> {
  defaultValue?: T | null
  id?: string
  name: string
  onChange?: (state: FieldState<any, any> & { target: unknown }) => unknown
  validation?: (value: T | null) => string | null
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  onBlur?: FocusEventHandler
  onFocus?: FocusEventHandler
}

interface RecentState<T> {
  value: T | null | undefined
  valid: boolean | undefined
  enable: boolean | undefined
  validationMessage: string | null
  defaultValue: T | null
  touched: boolean
}

export interface InputInstance<T> {
  isValid: (value?: T | null | undefined) => boolean
  set value(value: T | null | undefined)
  get value(): T | null | undefined
  reset: () => void
  focus: () => void
  scrollIntoView: (options?: ScrollIntoViewOptions) => void
}

export class BaseInput<T, Props extends BaseInputProps<T>, State = {}>
  extends PureComponent<Props, State>
  implements InputInstance<T>
{
  static contextType = FormFieldEmitter

  recentState: RecentState<T> = {
    value: undefined,
    valid: undefined,
    enable: undefined,
    validationMessage: null,
    defaultValue: null,
    touched: false,
  }

  checkCustomValidity(value: T | null | undefined = this.getValue() ?? null): string {
    if (value === undefined) return ''
    const { validation } = this.props
    return validation ? validation(value) || '' : ''
  }

  willValidate({ disabled, name }: BaseInputProps<T> = this.props) {
    return !!name && !disabled
  }

  setValue(value: T | null | undefined) {
    this.broadcastUpdates({ value })
  }

  set value(val: T | null | undefined) {
    this.setValue(val)
  }
  get value(): T | null | undefined {
    return this.getValue()
  }
  getValue(): T | null | undefined {
    throw new Error('define .getValue()')
  }

  isEmpty(value: T | null | undefined = this.getValue()): boolean {
    if (value === null || value === undefined) return false
    if (typeof value === 'string') return value.trim() === ''
    if (typeof value === 'number') return value === 0
    return false
  }

  /**
   * Trigger native validation
   * Sets <state.touched> = true
   */
  validate() {
    this.broadcastUpdates({ touched: true })
  }

  isValid(value: T | null | undefined = this.getValue()): boolean {
    if (!this.willValidate()) return true
    if (this.props.required) return !this.isEmpty(value)
    return true
  }

  equalValues(v1?: T | null, v2?: T | null) {
    if (this.isEmpty(v1) && this.isEmpty(v2)) return true
    return v1 === v2
  }

  private isEqualState(state: RecentState<T>) {
    return (
      this.equalValues(state.value, this.recentState.value) &&
      state.valid === this.recentState.valid &&
      state.enable === this.recentState.enable &&
      state.validationMessage === this.recentState.validationMessage &&
      this.equalValues(state.defaultValue, this.recentState.defaultValue) &&
      state.touched === this.recentState.touched
    )
  }

  protected broadcastUpdates({
    value = this.getValue(),
    valid = this.isValid(value),
    enable = this.willValidate(),
    validationMessage = this.getValidationMessage(),
    defaultValue = (this.props.defaultValue as T | null) ?? null,
    touched = this.recentState.touched,
  } = {}) {
    if (this.isEqualState({ value, valid, enable, validationMessage, defaultValue, touched }))
      return
    const { name } = this.props
    this.recentState = {
      value,
      valid,
      enable,
      validationMessage: validationMessage ?? null,
      defaultValue,
      touched,
    }
    this.emit({ ...this.recentState, name, ref: this })
    this.props.onChange?.({ ...this.recentState, target: this, name })
  }

  protected emit(state: FieldState<Record<string & Props['name'], any>>) {
    const emitter = this.context as Emmitter<Record<string & Props['name'], any>>
    if (!emitter) throw new Error(`Missing statusListener`)
    return emitter(state)
  }

  protected getValidationMessage(): string | null {
    if (!this.willValidate() || this.isValid()) return null
    if (this.isEmpty()) {
      return this.props.required ? 'Required' : null
    }
    return null
  }

  reset(): void {
    this.setValue(this.props.defaultValue ?? null)
  }

  focus(): void {
    throw new Error('focus() not implemented')
  }

  scrollIntoView(options?: ScrollIntoViewOptions): void {
    throw new Error('scrollIntoView() not implemented')
  }

  componentDidUpdate(prevProps?: Readonly<Props>, prevState?: Readonly<unknown>) {
    if (prevProps && !this.equalValues(this.props.defaultValue, prevProps.defaultValue)) {
      this.setValue(this.props.defaultValue ?? null)
    } else {
      this.broadcastUpdates()
    }
  }

  componentWillUnmount() {
    this.broadcastUpdates({ enable: false })
  }
}
