import { ComponentProps, ReactNode, FC } from 'react'
import { cn } from 'utils'
import { CardHeading } from './card-heading'
import styles from './card-section.module.scss'
import { Expandable } from '../expandable'

type Props = Omit<ComponentProps<'div'>, 'title'> & {
  children?: ReactNode | ReactNode[]
  expandable?: boolean
  collapsable?: boolean
  title?: ReactNode
  contentClassName?: string
}

export const CardSection: FC<Props> = ({
  children,
  className,
  title,
  expandable,
  collapsable,
  contentClassName,
  ...props
}) => {
  if (expandable || collapsable) {
    return (
      <div {...props} className={cn(styles.section, className)}>
        <Expandable.Context defaultOpened={collapsable}>
          <CardHeading className={styles.heading}>
            <Expandable.Toggle>{title}</Expandable.Toggle>
            <Expandable.Toggle className={styles.toggle} />
          </CardHeading>
          <Expandable.Content className={contentClassName}>{children}</Expandable.Content>
        </Expandable.Context>
      </div>
    )
  }

  return (
    <div {...props} className={cn(styles.section, className)}>
      {title ? <CardHeading>{title}</CardHeading> : null}
      {children}
    </div>
  )
}
