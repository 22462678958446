import { ComponentProps, useState } from 'react'
import { To } from 'react-router-dom'
import { alertErrorMessage, alertSuccessMessage } from 'utils/toast'
import { Button } from '../button'
import { useLoadingContextWrapper } from '../loader'
import { useNavigate } from '../use-navigate/use-navigate'

type Props<T> = Omit<ComponentProps<typeof Button>, 'onClick'> & {
  onClick: () => Promise<T>
  message?: string | ((result: T) => string)
  redirect?: To | ((result: T) => To)
  preventScrollReset?: boolean
  replace?: boolean
  state?: any
}
export function ActionButton<T>({
  children,
  onClick,
  message,
  redirect,
  disabled,
  preventScrollReset,
  replace,
  state,
  ...props
}: Props<T>) {
  const clickHandler = useLoadingContextWrapper(onClick)
  const [pending, setPending] = useState(false)
  const navigate = useNavigate()

  const action = async () => {
    setPending(true)
    await clickHandler()
      .then((result) => {
        if (message) {
          alertSuccessMessage(typeof message === 'function' ? message(result) : message)
        }
        if (redirect) {
          navigate(typeof redirect === 'function' ? redirect(result) : redirect, {
            preventScrollReset,
            replace,
            state,
          })
        }
      })
      .catch(alertErrorMessage)
    setPending(false)
  }

  return (
    <Button {...props} disabled={disabled || pending} onClick={action}>
      {children}
    </Button>
  )
}
