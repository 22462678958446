import { ComponentProps, FC } from 'react'
import { cn } from 'utils'
import { Link } from './link'
import { ButtonProps } from '../button'
import buttonStyles from '../button/button.module.scss'

type Props = Omit<ComponentProps<typeof Link>, 'theme'> &
  ButtonProps & {
    disabled?: boolean
  }

export const LinkButton: FC<Props> = ({
  className: _className,
  children,
  theme,
  wide,
  preventScrollReset,
  to,
  relative,
  replace,
  state,
  disabled,
  preserveSearch,
  unstyled,
  ...props
}) => {
  const className = cn(
    !unstyled && buttonStyles.button,
    theme && buttonStyles[theme],
    wide && buttonStyles.wide,
    _className,
  )
  if (disabled) {
    return (
      <span {...props} aria-disabled="true" className={className}>
        {children}
      </span>
    )
  }
  const allProps = {
    ...props,
    to,
    replace,
    preventScrollReset,
    relative,
    preserveSearch,
    state,
  }
  return (
    <Link {...allProps} unstyled className={cn(className)}>
      {children}
    </Link>
  )
}
