import { IconPlus } from 'icons'
import { ComponentProps, FC } from 'react'
import { cn } from 'utils'
import { Button } from './button'
import styles from './button-add.module.scss'

interface Props extends ComponentProps<typeof Button> {}

export const ButtonAdd: FC<Props> = ({ children, className, ...props }) => {
  return (
    <Button {...props} className={cn(styles.button, className)}>
      <span>{children}</span>
      <IconPlus />
    </Button>
  )
}
