import { ComponentProps } from 'react'
import { TextInput } from '../../input-text/text-input'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type InputProps = ComponentProps<typeof TextInput>
type Props = Omit<InputProps & BaseFieldProps<string>, 'type' | 'autoComplete'> & {
  autoCompletePrefix?: string
}

export class Field extends BaseField<string, Props> {
  static displayName = 'StreetAddressField'

  renderInput(props: InputProps) {
    return <TextInput {...props} />
  }

  getInputProps() {
    const { autoCompletePrefix, ...props } = super.getInputProps()
    return {
      ...props,
      autoComplete: [autoCompletePrefix, 'street-address'].filter(Boolean).join(' '),
    }
  }
}

export const StreetAddressField = withFormDefaultValues<string, Props>(Field)
