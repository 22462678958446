import { ComponentProps, FC } from 'react'
import { useLocation, useNavigation, useResolvedPath } from 'react-router-dom'
import { cn } from 'utils'
import styles from './animated-loader.module.scss'
import { Outlet } from './outlet'
import { LoaderWrapper } from '../loader'

interface Props extends ComponentProps<typeof Outlet> {
  className?: string
  spinnerClassName?: string
  ignoreAnscestorsAfter?: number
  ignoreExit?: boolean
  ignoreSamePath?: boolean
}

export const AnimatedOutlet: FC<Props> = ({
  context,
  className,
  spinnerClassName,
  ignoreAnscestorsAfter,
  ignoreExit,
  ignoreSamePath,
}) => {
  const thisPathname = useResolvedPath('.', { relative: 'route' }).pathname
  const currentPath = useLocation().pathname
  const currentDeepLevel = getDeepLevel(thisPathname, currentPath)
  const navigation = useNavigation()
  const nextPath = navigation.location?.pathname
  let loading = navigation.state === 'loading'

  if (loading && ignoreSamePath) {
    if (currentPath === nextPath) {
      // console.log(
      //   'ignore loading: same path',
      //   `\n@ ${thisPathname}`,
      //   `\n       ${currentPath}`,
      //   `\n    -> ${nextPath}`,
      // )
      loading = false
    }
  }
  if (loading && ignoreAnscestorsAfter) {
    if (currentDeepLevel > ignoreAnscestorsAfter) {
      // console.log(
      //   `ignore loading: currentDeepLevel(${currentDeepLevel}) > ${ignoreAnscestorsAfter}`,
      //   `\n@ ${thisPathname}`,
      //   `\n       ${currentPath}`,
      //   `\n    -> ${nextPath}`,
      // )
      loading = false
    }
  }
  if (loading && ignoreExit) {
    if (!nextPath?.startsWith(thisPathname)) {
      // console.log(
      //   `ignore loading: exiting`,
      //   `\n@ ${thisPathname}`,
      //   `\n       ${currentPath}`,
      //   `\n    -> ${nextPath}`,
      // )
      loading = false
    }
  }
  // loading &&
  //   console.log(
  //     `LOADING`,
  //     `\n@ ${thisPathname}`,
  //     `\n       ${currentPath}`,
  //     `\n    -> ${nextPath}`,
  //     `\n    deep: ${currentDeepLevel}`,
  //   )

  return (
    <LoaderWrapper
      loading={loading}
      className={cn(styles.loader, className)}
      spinnerClassName={cn(styles.spinner, spinnerClassName)}
    >
      <Outlet context={context} />
    </LoaderWrapper>
  )
}

const getDeepLevel = (currentPath: string, nextPath: string) => {
  if (!nextPath.startsWith(currentPath)) return 0

  return nextPath.split('/').length - currentPath.split('/').length
}
