import { ComponentProps, FC, FocusEventHandler, KeyboardEventHandler, useCallback } from 'react'
import { cn } from 'utils'
import { useDropdownContext } from './context'
import styles from './toggle.module.scss'

interface Props extends Omit<ComponentProps<'input'>, 'onClick'> {
  onOpen?: FocusEventHandler<HTMLInputElement>
}

export const DropdownToggleInput: FC<Props> = ({
  children,
  className,
  onKeyDown,
  onOpen,
  ...props
}) => {
  const { open, close, opened } = useDropdownContext() ?? {}

  const handleOpen = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      open?.()
      onOpen?.(event)
    },
    [onOpen, open],
  )

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      switch (event.key) {
        case 'Esc':
          event.stopPropagation()
          event.preventDefault()
          close?.()
          break
      }
      onKeyDown?.(event)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <input
      autoComplete="off"
      data-dropdown="toggle"
      data-expanded={opened ? 'true' : 'false'}
      {...props}
      className={cn(styles.toggle, className)}
      onFocus={handleOpen}
      onKeyDown={handleKeyDown}
    >
      {children}
    </input>
  )
}
