import { FC, ReactElement, ComponentProps, isValidElement, cloneElement } from 'react'
import { cn } from 'utils'
import styles from './key-value-pair.module.scss'

interface Props extends ComponentProps<'dl'> {
  icon?: FC<{ className?: string }> | ReactElement<{ className?: string; 'aria-hidden': boolean }>
  label: string
  theme?: 'primary' | 'secondary'
}
/**
 * `--icon-color`
 * `--value-color`
 */
export const KeyValuePair: FC<Props> = ({
  icon: Icon,
  label,
  children,
  className,
  theme = 'primary',
  ...props
}) => {
  return (
    <dl {...props} className={cn(styles.block, Icon && styles.hasIcon, styles[theme], className)}>
      {isValidElement(Icon) ? (
        cloneElement(Icon, { className: styles.icon, 'aria-hidden': true })
      ) : Icon ? (
        <Icon className={styles.icon} aria-hidden />
      ) : null}
      <dt className={styles.label}>{label}</dt>
      <dd className={styles.value}>{children}</dd>
    </dl>
  )
}
