import { ComponentProps, FocusEventHandler } from 'react'
import { BaseHtmlValidationInput, BaseHtmlValidationProps } from './base-html-validation-input'

type Props = BaseHtmlValidationProps<string> &
  Omit<ComponentProps<'textarea'>, 'onChange' | 'onBlur' | 'defaulValue' | 'value'>

export class TextArea extends BaseHtmlValidationInput<string, Props, HTMLTextAreaElement> {
  static defaultProps = {
    validationMessages: {},
  }

  onBlur: FocusEventHandler<HTMLTextAreaElement> = (event) => {
    // Trim value on blur, so the native "required" validation works correctly
    const value = event.target.value
    const trimmedValue = value.trim()
    if (value !== trimmedValue) {
      event.target.value = trimmedValue
      this.setValue(trimmedValue)
    }
    this.handleBlur?.(event)
  }

  parse(value: any): string | null {
    const stringValue = String(value).trim()
    return stringValue || null
  }

  render() {
    const { validation, validationMessages, validationMessage, defaultValue, ...props } = this.props

    return (
      <textarea
        {...props}
        defaultValue={this.format(defaultValue)}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
      />
    )
  }
}
