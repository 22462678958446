import { FC, HTMLProps } from 'react'
import { cn } from 'utils'
import { parseDate } from 'utils/date'

type ElementProps = Omit<HTMLProps<HTMLTimeElement>, 'value'>

type Props = Omit<Intl.RelativeTimeFormatOptions, 'style'> &
  ElementProps & {
    formatStyle?: Intl.RelativeTimeFormatOptions['style']
    className?: string
    locales?: string | string[]
    value?: number | string | Date | null
  }

export const RelativeTime: FC<Props> = ({ className, locales = 'en-US', value, ...props }) => {
  const [options, elementProps] = categorizeProps(props)
  const date = parseDate(value)
  if (!date) return null

  const minutes = getMinutesDiff(date)
  const text = new Intl.RelativeTimeFormat(locales, {
    style: 'narrow',
    ...options,
  }).format(...getUnits(minutes))
  return (
    <time
      id={date.toString()}
      dateTime={date.toISOString()}
      {...elementProps}
      className={cn(className)}
    >
      {text}
    </time>
  )
}

const getMinutesDiff = (data: Date, base = new Date()) => {
  const diff = data.getTime() - base.getTime()
  return diff / MS_IN_MIN
}

const MS_IN_MIN = 1000 * 60
const MIN_IN_HOUR = 60
const MIN_IN_DAY = 60 * 24
const MIN_IN_MON = MIN_IN_DAY * 30
const MIN_IN_YEAR = MIN_IN_DAY * 365

const getUnits = (minutes: number): [number, Intl.RelativeTimeFormatUnit] => {
  const val = Math.abs(minutes)
  const [amount, units] =
    val < MIN_IN_HOUR
      ? [minutes, 'minutes']
      : val < MIN_IN_DAY
      ? [minutes / MIN_IN_HOUR, 'hours']
      : val < MIN_IN_MON
      ? [minutes / MIN_IN_DAY, 'days']
      : val < MIN_IN_YEAR
      ? [minutes / MIN_IN_MON, 'months']
      : [minutes / MIN_IN_YEAR, 'years']
  return [Math.floor(amount), units as Intl.RelativeTimeFormatUnit]
}

const categorizeProps = ({
  formatStyle,
  numeric,
  localeMatcher,
  ...props
}: Props): [Intl.RelativeTimeFormatOptions, ElementProps] => {
  return [
    {
      style: formatStyle,
      numeric,
      localeMatcher,
    },
    props,
  ]
}
