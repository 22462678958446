import { IconClose } from 'icons'
import { FC, ComponentProps, KeyboardEventHandler, ReactNode, useEffect, useRef } from 'react'
import { cn } from 'utils'
import styles from './dialog.module.scss'
import { Backdrop } from '../backdrop'
import { IconButton } from '../button'
import { LoaderWrapper, usePathTransition } from '../loader'
import { Portal } from '../portal'

interface Props extends ComponentProps<typeof LoaderWrapper> {
  children?: ReactNode
  open?: boolean
  onClose?: () => void
  extraChildren?: ReactNode
  rootId?: string
  noLoader?: boolean
  backdropClassName?: string
  closeClassName?: string
  unstyled?: boolean
}

export const Dialog: FC<Props> = ({
  children,
  open = true,
  className,
  onClose,
  extraChildren,
  unstyled,
  rootId = unstyled ? undefined : styles.portal,
  noLoader,
  closeClassName,
  backdropClassName,
  ...props
}) => {
  const loading = usePathTransition({ ignoreSamePath: true })
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const handleKeyUp: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.isDefaultPrevented() || event.isPropagationStopped()) return
    if (event.key === 'Escape') {
      event.preventDefault()
      event.stopPropagation()
      onClose?.()
    }
  }
  useEffect(() => {
    open && closeButtonRef.current?.focus({ preventScroll: true })
  }, [open, rootId])

  const closeElement = onClose && (
    <IconButton
      className={cn(!unstyled && styles.close, closeClassName)}
      onClick={onClose}
      ref={closeButtonRef}
      title="close"
    >
      <IconClose />
    </IconButton>
  )

  return (
    <Portal scrollLock rootId={rootId}>
      <Backdrop onClick={onClose} className={cn(!unstyled && styles.backdrop, backdropClassName)} />
      {noLoader ? (
        <div
          className={cn(!unstyled && styles.dialog, className)}
          role="dialog"
          aria-modal="true"
          onKeyUp={handleKeyUp}
        >
          {closeElement}
          {children}
        </div>
      ) : (
        <LoaderWrapper
          {...props}
          className={cn(!unstyled && styles.dialog, className)}
          role="dialog"
          aria-modal="true"
          onKeyUp={handleKeyUp}
          loading={loading}
        >
          {closeElement}
          {children}
        </LoaderWrapper>
      )}

      {extraChildren}
    </Portal>
  )
}
