import { To } from 'react-router-dom'
import { UseNavigateToOptions, useNavigateTo } from './use-navigate-to'

/**
 * @param {To} to '..'
 * @param {boolean} options.back true
 * @param options.preserveSearch true
 * @param options.preventScrollReset true
 * @param options.revalidate true
 */
export const useNavigateOut = (
  to?: To,
  {
    back = true,
    preserveSearch = true,
    revalidate = true,
    preventScrollReset = true,
    ...options
  }: UseNavigateToOptions = {},
) => useNavigateTo(to ?? '..', { back, preserveSearch, revalidate, ...options })
