import { ComponentProps, FC, useState } from 'react'
import { cn } from 'utils'
import styles from './image.module.scss'
import placeholder from './placeholder.png'

interface Props extends Omit<ComponentProps<'img'>, 'onError'> {
  className?: string
}

export const Image: FC<Props> = ({ className, src, ...props }) => {
  const [error, setError] = useState(false)

  return !src || error ? (
    <img alt="Not found" className={cn(styles.image, styles.error, className)} src={placeholder} />
  ) : (
    <img
      alt=""
      {...props}
      className={cn(styles.image, className)}
      src={src}
      onError={() => setError(true)}
    />
  )
}
