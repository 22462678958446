import { ComponentProps, FC, ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'
import { SpecialComponents } from 'react-markdown/lib/ast-to-react'
import { NormalComponents } from 'react-markdown/lib/complex-types'
import { cn } from 'utils'
import styles from './markdown.module.scss'

const COMPONENTS: Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents> = {
  h1: ({ children, node, level, ...props }) => (
    <h1 {...props} id={toHashName(children)}>
      {children}
    </h1>
  ),
  h2: ({ children, node, level, ...props }) => (
    <h2 {...props} id={toHashName(children)}>
      {children}
    </h2>
  ),
  h3: ({ children, node, level, ...props }) => (
    <h3 {...props} id={toHashName(children)}>
      {children}
    </h3>
  ),
}
interface Props extends ComponentProps<typeof ReactMarkdown> {
  children: string
}

export const Markdown: FC<Props> = ({ children, className, components }) => (
  <ReactMarkdown
    className={cn(styles.markdown, className)}
    components={components ? { ...COMPONENTS, ...components } : COMPONENTS}
  >
    {children}
  </ReactMarkdown>
)

function toHashName(content?: ReactNode): string | undefined {
  let str = content?.toString()
  if (!str) return undefined
  str = str.replace(/^\s+|\s+$/g, '').toLowerCase()

  const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaaeeeeiiiioooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }
  return str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
}
