import { ComponentProps, FC, HTMLProps } from 'react'
import { cn } from 'utils'
import { Link } from '../link'
import styles from '../link/link.module.scss'

const enum PhoneFormat {
  national = 'national',
  international = 'international',
}
interface Props extends Omit<HTMLProps<HTMLAnchorElement>, 'href' | 'value' | 'type'> {
  value?: string | null
  className?: string
  local?: boolean
  theme?: ComponentProps<typeof Link>['theme']
  type?: `${PhoneFormat}`
}

export const FormatPhone: FC<Props> = ({
  value,
  className,
  type = PhoneFormat.international,
  theme,
  ...props
}) => {
  if (!value) return null

  return (
    <a
      href={`tel:${value}`}
      {...props}
      className={cn(styles.link, theme && styles[theme], className)}
    >
      {formatPhone(value, type)}
    </a>
  )
}

export function formatPhone(value: string, type: `${PhoneFormat}` = PhoneFormat.international) {
  return type === PhoneFormat.international
    ? value.replace(/\+?(\d)(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4')
    : value.replace(/\+?\d*(\d{3})(\d{3})(\d{4})/, '$1 $2-$3')
}
