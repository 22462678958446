import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import { FilePreview } from './file-preview'
import styles from './files-preview.module.scss'
import { useFormEmitter } from '../field/_base/use-field-control'
import { FormValue } from '../helpers/form-control'

interface Props {
  name: string
  className?: string

  renderFile?: (props: ComponentProps<typeof FilePreview>) => React.ReactNode
}
export const FilesPreview: FC<Props> = ({ name, className, renderFile = defaultRenderFile }) => {
  const emitChanges = useFormEmitter<Record<string, File[]>>()

  const removeFileFactory = (files: File[]) => (file: File) => {
    emitChanges({
      name,
      value: files.filter((f) => f !== file),
      validationMessage: null,
      touched: true,
      enable: true,
    })
  }

  return (
    <FormValue<Record<typeof name, File[] | File | null>>>
      {({ values }) => {
        const value = values[name]
        const files: File[] = !value ? [] : value instanceof File ? [value] : value
        if (!files?.length) return <></>

        const removeFile = removeFileFactory(files)

        return (
          <ul className={cn(styles.list, className)}>
            {files?.map((file) => (
              <li key={file.name + file.type}>
                {renderFile({ file, onRemove: () => removeFile(file) })}
              </li>
            ))}
          </ul>
        )
      }}
    </FormValue>
  )
}

const defaultRenderFile = (props: ComponentProps<typeof FilePreview>) => <FilePreview {...props} />
