import { ComponentProps } from 'react'
import { filterFileList } from '../../upload-form/filter-filelist'
import {
  BaseHtmlValidationProps,
  BaseHtmlValidationInput,
} from '../input-text/base-html-validation-input'

export class FileInput extends BaseHtmlValidationInput<
  FileInput.Value,
  FileInput.Props,
  HTMLInputElement
> {
  getValue(): FileInput.Value | undefined {
    const files = this.element?.files
    if (!files?.length) return undefined
    return this.props.multiple ? filterFileList(files, this.props.accept) : files[0]
  }

  setValue(value?: FileInput.Value | null): void {
    if (!this.element) return
    this.resetCustomValidity()
    this.broadcastUpdates({ value })
  }

  equalValues(a?: FileInput.Value | null, b?: FileInput.Value | null) {
    if (!a && !b) return true
    if (!a || !b) return false
    if (Array.isArray(a) && Array.isArray(b)) {
      return a.every((a: File, i) => a.name === b[i]?.name)
    } else if (!Array.isArray(a) && !Array.isArray(b)) {
      return (a as File).name === (b as File).name
    } else {
      return false
    }
  }

  render() {
    const { validation, defaultValue, ...props } = this.props
    return (
      <input
        {...props}
        ref={this.setElement}
        id={props.id ?? props.name}
        onChange={this.handleChange}
        type="file"
      />
    )
  }
}

export namespace FileInput {
  export type Value = File | File[]
  export type Props = BaseHtmlValidationProps<Value> &
    Pick<ComponentProps<'input'>, 'multiple' | 'accept' | 'capture' | 'className'>
}
