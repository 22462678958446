import { ComponentProps, createElement, ElementType, RefObject } from 'react'
import { cn } from 'utils'
import styles from './list.module.scss'

type Wrapper = keyof JSX.IntrinsicElements
type Props<T, W extends Wrapper = 'ul'> = Omit<ComponentProps<W>, 'children'> & {
  as?: W
  items?: T[]
  renderItem: (item: T, index: number, items: T[]) => JSX.Element
  renderFooter?: (items?: T[]) => JSX.Element | null | false | undefined
  elementRef?: RefObject<ElementType<W>>
}
export function List<T, W extends Wrapper>({
  as,
  items,
  className,
  renderItem,
  renderFooter,
  elementRef,
  ...props
}: Props<T, W>) {
  return createElement(
    as ?? 'ul',
    {
      ...props,
      className: cn(styles.list, className),
      ref: elementRef,
    },
    <>
      {items?.map((item, index) => renderItem(item, index, items))}
      {renderFooter?.(items)}
    </>,
  )
}
