type Options = Intl.DateTimeFormatOptions
type Option = keyof Options

export type DateOptions = Omit<
  Options,
  'fractionalSecondDigits' | 'hour12' | 'hour' | 'hourCycle' | 'minute' | 'second' | 'timeStyle'
>

const PROPS_OPTIONS = new Set<Option>([
  'calendar',
  'dateStyle',
  'day',
  'dayPeriod',
  'era',
  'formatMatcher',
  'fractionalSecondDigits',
  'hour12',
  'hour',
  'hourCycle',
  'localeMatcher',
  'minute',
  'month',
  'numberingSystem',
  'second',
  'timeStyle',
  'timeZone',
  'timeZoneName',
  'weekday',
  'year',
])

function isOption(name: string): name is Option {
  return PROPS_OPTIONS.has(name as Option)
}

export const pickDateTimeFormatOptions = <T extends Options, Rest = Omit<T, keyof Options>>(
  props: T,
): [Options, Rest] => {
  return Object.entries(props).reduce(
    ([options, props], [name, value]) =>
      isOption(name)
        ? [{ ...options, [name]: value }, props]
        : [options, { ...props, [name]: value }],
    [{} as Options, {} as Rest],
  )
}
