import { FC } from 'react'
import { cn } from 'utils'
import styles from './heading-with-border.module.scss'

type Props = {
  title?: string | null
  className?: string
}

export const HeadingWithBorder: FC<Props> = ({ title, className }) => {
  return title ? <h3 className={cn(styles.title, className)}>{title}</h3> : null
}
