import { createContext, useContext } from 'react'

interface DropdownContextValue {
  id: string
  toggle: (opener?: HTMLElement) => void
  open: (opener?: HTMLElement) => void
  close: () => void
  opened: boolean
  opener?: HTMLElement
}

const DropdownContext = createContext<DropdownContextValue | undefined>(undefined)

export const useDropdownContext = () => {
  const context = useContext(DropdownContext)
  return context
}

export const DropdownProvider = DropdownContext.Provider
