import { ComponentProps, FC, useRef } from 'react'
import { Page } from 'react-pdf'
import { useContainerZoom, useVisibilityListener } from 'ui'
import { cn } from 'utils'
import styles from './pdf-page.module.scss'

export type PageArgs = {
  height: number
  id: number
  pageHeight: number
  pageWidth: number
  width: number
  zoom: number
}

type Props = Omit<
  ComponentProps<typeof Page>,
  'width' | 'pageNumber' | 'className' | 'children'
> & {
  className?: string
  pageClassName?: string
  pageWidth: number
  pageHeight: number
  id: number
  children?: (page: PageArgs) => JSX.Element
}
export const PdfPage: FC<Props> = ({
  className,
  pageHeight,
  pageWidth,
  id,
  children,
  pageClassName,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const zoom = useContainerZoom(ref, { pageWidth })
  const visible = useVisibilityListener<HTMLDivElement>(ref)
  const width = Math.round(pageWidth * zoom)
  const height = Math.round(pageHeight * zoom)

  return (
    <div ref={ref} className={cn(styles.container, className)}>
      <div className={cn(styles.page, pageClassName)} style={{ width, height }}>
        {visible && zoom && width ? (
          <Page
            renderAnnotationLayer={false}
            renderTextLayer={false}
            {...props}
            pageNumber={id + 1}
            width={width}
            className={styles.pdf}
          />
        ) : null}
        {children && (
          <div className={styles.overlay}>
            {children({ width, height, id, zoom, pageWidth, pageHeight })}
          </div>
        )}
      </div>
    </div>
  )
}
