import { createRef, ReactNode } from 'react'
import { cn } from 'utils'
import { Dropdown } from '../../../dropdown'
import { List } from '../../../list'
import listStyles from '../../../list/list.module.scss'
import { FieldDropdownCheckbox } from '../../field-dropdown/field-dropdown-checkbox'
import { FieldDropdownMenu } from '../../field-dropdown/field-dropdown-menu'
import { FieldDropdownToggle } from '../../field-dropdown/field-dropdown-toggle'
import styles from '../../field.module.scss'
import { LabelText } from '../../label/label'
import { Option, Options, optionsConverter } from '../../utils/options'
import { withFormDefaultValues, BaseFieldProps } from '../_base'
import { StatefulField } from '../_stateful'

export type Props<T extends string> = BaseFieldProps<T[]> & {
  options: Options<T>
  validationMessage?: string | null
  validationMessages?: Partial<Record<keyof ValidityState, string>>
  disabledOptions?: T[]
  defaultValue?: T[] | null | undefined
  placeholder?: string
  entitiesLabel?: string
}

interface State<T> {
  value: T[]
}

export class Field<T extends string> extends StatefulField<T[], Props<T>, State<T>> {
  static displayName = 'MultiSelect'

  state = {
    value: this.props.defaultValue ?? [],
  }

  optionsRef = createRef<HTMLDivElement>()

  add = (value: T) => {
    this.setValue(Array.from(new Set([...(this.state.value ?? []), value])))
  }
  remove = (value: T | null) => {
    this.setValue(this.state.value?.filter((v) => v !== value))
  }
  toggleValue = (value: T | null) => {
    if (value === null) return
    if (this.state.value?.includes(value)) {
      this.remove(value)
    } else {
      this.add(value)
    }
  }

  override getValueHash(value: T[] | null) {
    return value?.map(String).sort().join('|') ?? ''
  }

  private findOptionsByValue(values?: null | T[]): null | Option<T>[] {
    if (!values || !values.length) return null
    const options = optionsConverter(this.props.options, this.props.disabledOptions)
    if (!options?.length) return null
    return options
      .filter(([, value]) => value && values.includes(value))
      .map(([label, value, disabled]) => ({ label, disabled, value }))
  }

  get className() {
    return cn(super.className, styles.multiselect)
  }

  renderValues(options: null | Option<T>[]) {
    if (!options?.length) return null
    if (options.length > 1) {
      return (
        <span className={styles.amount}>
          {options.length} {this.props.entitiesLabel ?? 'items'}
        </span>
      )
    }
    return options?.map(({ label, value }) => (
      <span key={String(value)} className={styles.pill}>
        {label}
      </span>
    ))
  }

  renderOptions() {
    const options = optionsConverter(this.props.options)
    return (
      <FieldDropdownMenu>
        <List
          as="div"
          items={options}
          renderItem={(item) => this.renderOption(...item)}
          role="listbox"
        />
      </FieldDropdownMenu>
    )
  }

  renderOption(label: ReactNode, value: T | null, disabled: boolean) {
    const checked = Boolean(value && this.state.value?.includes(value))
    return (
      <FieldDropdownCheckbox
        aria-selected={checked}
        checked={checked}
        className={listStyles.item}
        disabled={disabled}
        key={String(value)}
        onToggle={this.toggleValue}
        role="option"
        title={String(label)}
        value={value}
      />
    )
  }

  render() {
    const { disabled, readOnly, name, placeholder } = this.props
    return (
      <Dropdown.Container>
        <div className={this.className} data-field-type="multiselect">
          <LabelText className={this.labelClassName}>{this.label}</LabelText>

          <FieldDropdownToggle
            disabled={disabled || readOnly}
            name={name}
            placeholder={placeholder}
          >
            {this.renderValues(this.findOptionsByValue(this.state.value))}
          </FieldDropdownToggle>

          {this.renderOptions()}
          {this.shouldShowValidity() && this.renderValidationMessage()}
        </div>
      </Dropdown.Container>
    )
  }
}

export const MultiSelect = withFormDefaultValues<string[], Props<string>>(Field)
