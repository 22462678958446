import { FieldState, FormFieldEmitter } from '../../form/context'
import { Emmitter } from '../../form/form'
import { isBlankValue, isEqualState, isEqualValues } from '../../utils/field-state'
import { BaseField, BaseFieldProps } from '../_base'

type StatefulFieldState<Val> = { value: Val | null | undefined }

interface RecentState<Val> {
  value: Val | null | undefined
  valid: boolean | undefined
  enable: boolean | undefined
  validationMessage: string | null
  defaultValue: Val | null
  touched: boolean
}

export class StatefulField<
  Val,
  Props extends BaseFieldProps<Val>,
  State extends StatefulFieldState<Val>,
> extends BaseField<Val, Props, State> {
  static contextType = FormFieldEmitter

  recentState: RecentState<Val> = {
    value: undefined,
    valid: undefined,
    enable: undefined,
    validationMessage: null,
    defaultValue: null,
    touched: false,
  }

  state = {
    value: (this.props.defaultValue as Val) ?? null,
  } as State

  setValue(value: Val | null) {
    this.setState({ value })
    this.broadcastUpdates({ value })
  }

  getValue(): Val | null {
    if (this.isValueBlank(this.state.value)) return null
    return this.state.value ?? null
  }

  reset() {
    this.setValue(this.props.defaultValue ?? null)
  }

  willValidate({ disabled, name } = this.props) {
    return !!name && !disabled
  }

  protected getValidationMessage(): string | null {
    if (this.isValid()) return null
    const { required } = this.props
    const value = this.getValue()
    if (this.isValueBlank(value)) {
      return required ? 'The field is required' : null
    }
    return null
  }

  isValueBlank(value: Val | null = this.getValue()): boolean {
    return isBlankValue(value)
  }

  getValueHash(value: Val | null = this.getValue()): any {
    return value
  }

  private equalValues = (v1: Val | null, v2: Val | null) => {
    const v1Blank = this.isValueBlank(v1)
    const v2Blank = this.isValueBlank(v2)
    if (v1Blank && v2Blank) return true
    if (v1Blank !== v2Blank) return false
    return isEqualValues(this.getValueHash(v1), this.getValueHash(v2))
  }

  private isEqualState(state: RecentState<Val>) {
    return isEqualState(state, this.recentState, this.equalValues)
  }

  protected broadcastUpdates({
    value = this.getValue(),
    valid = this.isValid(value),
    enable = this.willValidate(),
    validationMessage = this.getValidationMessage(),
    defaultValue = (this.props.defaultValue as Val | null) ?? null,
    touched = this.recentState.touched,
  } = {}) {
    if (this.isEqualState({ value, valid, enable, validationMessage, defaultValue, touched }))
      return
    const { name } = this.props
    this.recentState = {
      value,
      valid,
      enable,
      validationMessage: validationMessage ?? null,
      defaultValue,
      touched,
    }
    this.emit({ ...this.recentState, name, ref: this })
    // this.props.onChange?.({ ...this.recentState, target: this, name })
  }

  isValid(value = this.getValue()): boolean {
    if (!this.willValidate()) return true
    if (this.props.required) return !this.isValueBlank(value)
    return true
  }

  private emit(state: FieldState<Record<string & Props['name'], any>>) {
    const emitter = this.context as Emmitter<Record<string & Props['name'], any>>
    if (!emitter) throw new Error(`Missing statusListener`)
    return emitter(state)
  }

  componentDidMount() {
    const value = this.isValueBlank(this.props.defaultValue) ? null : this.props.defaultValue
    // let the parent form knows about this control
    this.broadcastUpdates({ value })
  }

  componentDidUpdate(prevProps?: Readonly<Props>, prevState?: Readonly<unknown>) {
    if (
      prevProps &&
      !this.equalValues(this.props.defaultValue ?? null, prevProps.defaultValue ?? null)
    ) {
      this.setValue(this.props.defaultValue ?? null)
    } else {
      this.broadcastUpdates()
    }
  }

  componentWillUnmount() {
    this.broadcastUpdates({ enable: false })
  }
}
