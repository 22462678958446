import { MimeType } from 'api/document'
import { ComponentProps, FC, ReactElement } from 'react'
import { Link, Tooltip } from 'ui'
import { cn } from 'utils'
import { Optional } from 'utils/type-utils'
import styles from './filename.module.scss'
import { Tag } from '../tag'

type Props = {
  filename: string
  mimetype: string
  className?: string
} & Optional<ComponentProps<typeof Link>>

export const FileName: FC<Props> = ({
  filename = '',
  mimetype,
  className,
  to,
  preserveSearch,
  preventScrollReset,
  state,
  replace,
  relative,
  ...props
}) => {
  let tag: ReactElement
  let name = filename
  switch (mimetype) {
    case MimeType.pdf:
      tag = <Tag theme="success">pdf</Tag>
      name = filename.replace(/\.pdf$/, '')
      break
    case MimeType.jpeg:
    case MimeType.jpg:
    case MimeType.png:
    case MimeType.webp:
      tag = (
        <Tag
          theme="primary"
          data-tooltip-id={Tooltip.ID}
          data-tooltip-content={mimetype}
          className={styles.tag}
        >
          img
        </Tag>
      )
      name = filename.replace(/\.(webp|png|jpeg|jpg)$/, '')
      break
    default:
      tag = <>| {mimetype}</>
      break
  }

  return (
    <span className={cn(styles.filename, className)}>
      {to ? (
        <Link
          {...props}
          to={to}
          preserveSearch={preserveSearch}
          preventScrollReset={preventScrollReset}
          state={state}
          replace={replace}
          relative={relative}
          className={styles.name}
        >
          {name}
        </Link>
      ) : (
        <span {...props} className={styles.name}>
          {name}
        </span>
      )}
      {tag}
    </span>
  )
}
