import { createRef, KeyboardEventHandler } from 'react'
import { cn } from 'utils'
import styles from './select-list.module.scss'
import { List } from '../../../list'
import listStyles from '../../../list/list.module.scss'
import { LabelText } from '../../label/label'
import { ConverterOption, Options, optionsConverter } from '../../utils/options'
import { withFormDefaultValues, BaseFieldProps } from '../_base'
import { StatefulField } from '../_stateful'

export type Props<T extends string> = BaseFieldProps<T[]> & {
  options: Options<T>
  validationMessage?: string | null
  validationMessages?: Partial<Record<keyof ValidityState, string>>
  disabledOptions?: T[]
  defaultValue?: T[] | null | undefined
  placeholder?: string
  entitiesLabel?: string
}

interface State<T> {
  index: number
  value: T[]
}

export class UnboundSelectList<T extends string> extends StatefulField<T[], Props<T>, State<T>> {
  static displayName = 'SelectList'

  state = {
    value: this.props.defaultValue ?? [],
    index: 0,
  }

  optionsRef = createRef<HTMLDivElement>()

  add = (value: T) => {
    this.setValue(Array.from(new Set([...this.state.value, value])))
  }
  remove = (value: T | null) => {
    this.setValue(this.state.value.filter((v) => v !== value))
  }
  toggleValue = (value: T | null) => {
    if (!value) return
    if (this.state.value.includes(value)) {
      this.remove(value)
    } else {
      this.add(value)
    }
  }
  handleOptionKeyUp: KeyboardEventHandler<HTMLLabelElement> = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        this.focusSiblingOption(event.currentTarget, 1)
        break
      case 'ArrowUp':
        event.preventDefault()
        this.focusSiblingOption(event.currentTarget, -1)
        break
    }
  }

  findOptionElement(...selectors: string[]): HTMLLabelElement | undefined {
    if (this.props.disabled || this.props.readOnly) return undefined
    const element = this.optionsRef.current
    if (!element) return undefined
    for (const selector of selectors) {
      const option = element.querySelector(selector)
      if (option) return option as HTMLLabelElement
    }
    return undefined
  }

  focusSiblingOption(element: HTMLLabelElement, offset: 1 | -1) {
    const currentIndex = Number(element.dataset.index)
    if (isNaN(currentIndex)) return

    if (currentIndex + offset < 0 || currentIndex + offset >= this.options.length) {
      element.blur()
      return
    }
    const option = this.findOptionElement(`label[data-index="${currentIndex + offset}"]`)
    if (!option) return
    if (option.getAttribute('aria-disabled') === 'true') {
      this.focusSiblingOption(option, offset)
    } else {
      option.focus()
    }
  }

  focus() {
    this.findOptionElement(
      'label:not([aria-disabled="true"]):has(input:checked)',
      'label:not([aria-disabled="true"])',
    )?.focus()
  }

  getValueHash(value: T[] | null) {
    return value?.map(String).sort().join('|') ?? ''
  }

  get className() {
    return cn(super.className, styles.selectlist)
  }

  get options() {
    return optionsConverter(this.props.options)
  }

  hasValue() {
    return this.state.value.length > 0
  }

  private isFocusableSet = false

  renderOptions() {
    this.isFocusableSet = false
    return (
      <List
        as="div"
        items={optionsConverter(this.props.options)}
        renderItem={(item, index) => this.renderOption(item, index)}
        className={styles.options}
        role="listbox"
      />
    )
  }
  renderOption([label, value, optionDisabled]: ConverterOption<T>, index = 0) {
    const disabled = this.props.disabled || optionDisabled
    const checked = Boolean(value && this.state.value.includes(value))
    const focusable = !this.isFocusableSet && !disabled && (this.hasValue() ? checked : index === 0)
    if (focusable) this.isFocusableSet = true
    return (
      <label
        key={String(value)}
        className={cn(listStyles.item, styles.option, checked && styles.checked)}
        aria-disabled={this.props.readOnly || disabled ? 'true' : 'false'}
        tabIndex={focusable ? 0 : -1}
        data-index={index}
        onKeyUp={this.handleOptionKeyUp}
        role="option"
        aria-selected={checked}
      >
        <input
          name={this.props.name}
          type="checkbox"
          disabled={disabled}
          value={value ? String(value) : ''}
          checked={checked}
          onChange={() => this.toggleValue(value)}
          className={styles.input}
          tabIndex={-1}
        />
        <span className={styles.check} />
        <span className={styles.label}>{label}</span>
      </label>
    )
  }

  render() {
    const { label, disabled } = this.props
    return (
      <div
        className={this.className}
        data-field-type="selectlist"
        aria-disabled={disabled}
        ref={this.optionsRef}
      >
        <LabelText className={this.labelClassName}>{label}</LabelText>
        {this.renderOptions()}
        {this.shouldShowValidity() && this.renderValidationMessage()}
      </div>
    )
  }
}

export const SelectList = withFormDefaultValues<string[], Props<string>>(UnboundSelectList)
