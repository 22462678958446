import { useSubscription, ChatEventHandler } from './use-subscription'
import { getChatChannel } from '../ably/channel'

export const useChatSubscription = (chat_id: string, cb?: ChatEventHandler<ChatEvent>) => {
  useSubscription<ChatEvent>(getChatChannel(chat_id), cb)
}

export const enum ChatEvent {
  MSG_NEW = 'chat-msg-new',
  MSG_READ = 'chat-msg-read',
  MSG_UPD = 'chat-msg-update',
  MSG_DEL = 'chat-msg-delete',
  REACT_NEW = 'chat-msg-react-new',
  REACT_DEL = 'chat-msg-react-delete',
  TYPING = 'chat-user-typing',
  USED_ADD = 'chat-user-add',
  USER_REMOVE = 'chat-user-remove',
}

export interface ChatMessageEvent {
  chat_id: string
  user_id: string
  message_id: string
}
export interface ChatUserEvent {
  chat_id: string
  user_id: string
}
