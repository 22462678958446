import { forwardRef, ReactNode } from 'react'
import { cn } from 'utils'
import styles from './validation-message.module.scss'

type ErrorMessageProps = {
  success?: false
  error: true
}
type SuccessMessageProps = {
  success: true
  error?: false
}
type ValidationMessageProps = {
  children: ReactNode
  className?: string
} & (ErrorMessageProps | SuccessMessageProps)

export const ValidationMessage = forwardRef<HTMLDivElement, ValidationMessageProps>(
  ({ className, error, success, children }, ref) => {
    if (error && success) {
      throw new Error('ValidationMessage can not be "error" and "success" at the same time')
    }
    return (
      <div
        className={cn(styles.message, error && styles.error, success && styles.success, className)}
        ref={ref}
      >
        {children}
      </div>
    )
  },
)

ValidationMessage.displayName = 'ValidationMessage'
