import { Reducer } from 'react'

export interface State {
  submitting?: boolean
  error?: string | null
  result?: any
  files?: File[]
}

export const enum Action {
  Submit = 'submit',
  Succeed = 'success',
  Error = 'error',
  AddFile = 'add-file',
  RemoveFile = 'remove-file',
}
export type ActionType =
  | { type: Action.Submit }
  | { type: Action.Succeed; payload: any }
  | { type: Action.Error; payload: Error }
  | { type: Action.AddFile; payload: File }
  | { type: Action.RemoveFile; payload: File }

export const uploadReducer: Reducer<State, ActionType> = (state, action) => {
  switch (action.type) {
    case Action.Submit:
      return { ...state, submitting: true, error: null }
    case Action.Succeed:
      return { ...state, submitting: false, result: action.payload }
    case Action.Error:
      return { ...state, submitting: false, error: action.payload?.message ?? 'Unknown error' }
    case Action.AddFile:
      return state.files?.find((file) => isSameFile(file, action.payload))
        ? state
        : { ...state, files: [...(state.files ?? []), action.payload] }
    case Action.RemoveFile:
      let files = (state.files ?? []).filter((file) => !isSameFile(file, action.payload))
      return {
        ...state,
        files,
      }
  }
}
export const initialState = {
  submitting: false,
  error: null,
  result: null,
  files: [],
}

function isSameFile(f1: File, f2: File) {
  return f1.name === f2.name && f1.type === f2.type
}
