import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './field-dropdown-menu.module.scss'
import { Dropdown } from '../../dropdown'
import listStyles from '../../list/list.module.scss'

interface Props extends ComponentProps<typeof Dropdown.Menu> {}
export const FieldDropdownMenu: FC<Props> = ({ children, className, ...props }) => {
  return (
    <Dropdown.Menu {...props} className={cn(listStyles.list, styles.menu, className)}>
      {children}
    </Dropdown.Menu>
  )
}
