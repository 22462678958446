import { FC, ReactNode, ComponentProps } from 'react'
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom'
import { resolveLinkParams } from './link'

type Props = ComponentProps<typeof RouterNavLink> &
  ComponentProps<'span'> & {
    children?: ReactNode
    className?: string
    preserveSearch?: boolean
    disabled?: boolean
  }

export const NavLink: FC<Props> = ({
  children,
  className,
  state: _state,
  to: _to,
  preserveSearch,
  preventScrollReset,
  relative,
  replace,
  disabled,
  ...props
}) => {
  const from = useLocation()

  if (disabled) {
    return (
      <span {...props} aria-disabled="true" className={className}>
        {children}
      </span>
    )
  }

  const { state, to } = resolveLinkParams({
    to: _to,
    state: _state,
    from,
    preserveSearch,
  })
  const allProps = {
    ...props,
    to,
    replace,
    preventScrollReset,
    relative,
    state,
  }

  return (
    <RouterNavLink {...allProps} className={className}>
      {children}
    </RouterNavLink>
  )
}
