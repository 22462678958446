import { LogoSVG } from 'icons'
import { FC, HTMLProps } from 'react'
import { To } from 'react-router-dom'
import { cn } from 'utils'
import styles from './logo.module.css'
import { Link } from '../link'

interface LogoProps extends Omit<HTMLProps<HTMLAnchorElement>, 'ref' | 'onCopy' | 'align'> {
  variation?: 'faded' | 'welcome'
  linkTo?: To
}

export const Logo: FC<LogoProps> = ({ className, children, variation, linkTo, ...props }) => {
  return linkTo ? (
    <Link
      unstyled
      to={linkTo}
      className={cn(styles.logo, variation && styles[variation], className)}
      {...props}
    >
      <LogoSVG aria-label="Rello" />
    </Link>
  ) : (
    <a
      className={cn(styles.logo, variation && styles[variation], className)}
      {...props}
      href={undefined}
    >
      <LogoSVG aria-label="Rello" />
    </a>
  )
}
