import { FC, ComponentProps } from 'react'
import { Description } from 'ui'

type Props = Omit<ComponentProps<typeof Description>, 'error' | 'warn'>

export const HeadsUpWarns: FC<Props> = ({ children, ...props }) => {
  return (
    <Description {...props} warn>
      <ul>{children}</ul>
    </Description>
  )
}
