import { BackendError } from 'client'
import { ContextType, createRef, PureComponent, SyntheticEvent } from 'react'
import { cn } from 'utils'
import { FormFieldEmitter, FieldState, FieldComponent, FormPropsProvider, Fields } from './context'
import { LoadingContext, wrapInLoadingContext } from '../../loader'
import styles from '../form.module.scss'
import { ValidationMessage } from '../validation-message'

const SCROLL_OPTIONS: ScrollIntoViewOptions = { behavior: 'smooth', block: 'nearest' }

export interface FormProps<T extends Record<string, any> = {}> {
  id?: string
  className?: string
  readOnly?: boolean
  disabled?: boolean
  defaultValues?: Partial<T>
  onChange?: (data: Partial<T>, state?: FormState<T>, refs?: Record<string, FieldComponent>) => void
  onSubmit: ((data: T) => void) | OnSubmitFormType<T>
  redirect?: ((value: T | void) => void) | ((value?: any) => void)
  validation?: (value: Partial<T>) => string | null | undefined
  children?: React.ReactNode
  instantValidation?: boolean
}

interface FormState<T extends Record<string, any>> {
  fields: Fields<T>
  pending: boolean
  error: string | null
  errors: FormErrors<T>
}

export class Form<T extends Record<string, any>> extends PureComponent<FormProps<T>, FormState<T>> {
  static defaultProps = {
    defaultValues: {},
  }

  element = createRef<HTMLFormElement>()
  errorMessageRef = createRef<HTMLDivElement>()
  fieldRefs: Record<string, FieldComponent> = {}

  private _unmounted?: boolean

  private getStateHash() {
    return Object.values(this.getValues()).flat().join('')
  }

  private setFieldStatus: Emmitter<T> = async ({
    value = null,
    name,
    enable = true,
    defaultValue = null,
    validationMessage = null,
    touched,
    ref,
  }): Promise<void> => {
    if (!name) throw new Error('field name is undefined')
    if (ref) {
      this.fieldRefs[name as keyof T & string] = ref
    }
    const stateBefore = this.props.onChange && this.getStateHash()
    await this.updateFieldState(name, {
      value,
      touched,
      validationMessage,
      defaultValue,
      enable,
    })
    if (this.props.onChange && stateBefore !== this.getStateHash()) {
      try {
        this.props.onChange(this.getValues(), this.state, this.fieldRefs)
      } catch (err) {
        let error = (err as BackendError).message
        let errors = (err as BackendError).errors as FormErrors<T>
        await this.setErrors(error, errors)
      }
    }
  }

  state: FormState<T> = {
    fields: {},
    pending: false,
    error: null,
    errors: {},
  }

  submit = async (event?: SyntheticEvent<HTMLFormElement, Event>) => {
    event?.preventDefault()
    event?.stopPropagation()
    try {
      await this.validate()
    } catch (e) {
      await this.focusFirstInvalidField()
      return
    }
    return this.context
      ? await wrapInLoadingContext(this.context, this.#doSubmit)()
      : await this.#doSubmit()
  }

  handleReset = () => {
    Object.values(this.fieldRefs).forEach((element) => {
      element && element.reset?.()
    })
    this.resetValues()
  }

  static contextType = LoadingContext
  context!: ContextType<typeof LoadingContext>

  #doSubmit = async () => {
    const { onSubmit, redirect } = this.props
    await this.setStateAsync((state) => ({ ...state, pending: true }))
    try {
      const result = await onSubmit(this.getValues() as T)
      if (!this._unmounted && !redirect) {
        await this.resetTouched()
        await this.setStateAsync((state) => ({ ...state, pending: false }))
      }
      redirect && redirect(result)
    } catch (err) {
      let error = (err as BackendError).message
      let errors = (err as BackendError).errors as FormErrors<T>

      await this.setStateAsync((state) => ({ ...state, pending: false }))
      await this.setErrors(error, errors)
      this.scrollToTop()
    }
  }

  compareValues(a: any, b: any) {
    if (Array.isArray(a) !== Array.isArray(b)) {
      return false
    }
    if (Array.isArray(a)) {
      return a.length === b.length && a.every((v, i) => v === b[i])
    } else {
      return a === b
    }
  }

  updateFieldState(
    name: keyof T,
    {
      value,
      touched,
      validationMessage,
      defaultValue,
      enable,
    }: Omit<FieldState<T, keyof T>, 'name'>,
  ): Promise<unknown> {
    if (this._unmounted) return Promise.resolve() // must be a Promise

    return new Promise((resolve) => {
      this.setState(
        (state) => {
          const { [name]: field, ...fields } = state.fields
          if (!enable && !field) {
            // was disabled, now disabled - nothing has changed
            resolve(state)
            return null
          }
          const prev = field
          if (
            prev &&
            this.compareValues(prev.value, value) &&
            prev.touched === touched &&
            prev.validationMessage === validationMessage &&
            this.compareValues(prev.defaultValue, defaultValue) &&
            enable
          ) {
            // when nothing has changed
            resolve(state)
            return null
          }
          if (!enable) {
            // was enabled, now disabled - remove a field
            return { ...state, fields } as FormState<T>
          }

          const next = { ...prev, value, touched, validationMessage, defaultValue, enable }
          return {
            ...state,
            fields: {
              ...fields,
              [name]: next,
            },
            error: null,
          } as FormState<T>
        },
        () => {
          resolve(null)
        },
      )
    })
  }

  resetTouched(value = false) {
    if (this._unmounted) return Promise.resolve() // must be a Promise
    return this.setStateAsync((state) => {
      const entries = Object.entries(state.fields).map((entry) => {
        const [name, fieldState] = entry
        return [name, { ...fieldState, touched: value }]
      })
      return { ...state, fields: Object.fromEntries(entries) }
    })
  }

  async resetValues(): Promise<any> {
    if (this._unmounted) return

    await this.setStateAsync((state) => {
      const entries = Object.entries(state.fields).map((entry) => {
        const [name, fieldState] = entry as [keyof T, FieldState<T>]
        return [name, { ...fieldState, value: fieldState.defaultValue, touched: false }]
      })
      return { ...state, fields: Object.fromEntries(entries) }
    })
  }

  scrollToTop() {
    this.errorMessageRef.current?.scrollIntoView(SCROLL_OPTIONS)
  }

  async setErrors(error?: string | null, errors?: FormErrors<T>): Promise<void> {
    if (this._unmounted) return Promise.resolve() // must be a Promise
    if (!errors || !Object.keys(errors).length) {
      await this.setStateAsync((state) => ({ ...state, error: error ?? null, pending: false }))

      return
    }

    await this.setStateAsync((state) => {
      const nextFields = { ...state.fields }
      const unhandledMessages: string[] = [] as string[]

      Object.entries(errors).forEach((entry) => {
        const [name, validationMessage] = entry
        if (nextFields.hasOwnProperty(name)) {
          //@ts-ignore
          nextFields[name] = {
            ...nextFields[name],
            validationMessage,
          }
        } else {
          unhandledMessages.push(`${validationMessage} (${name})`)
        }
      })

      return {
        ...state,
        fields: nextFields,
        pending: false,
        error: unhandledMessages
          ? [error, ...unhandledMessages].filter(Boolean).join(', ')
          : error ?? null,
      }
    })
  }

  reset() {
    this.element.current && this.element.current.reset()
    this.handleReset()
  }

  getCustomValidationError(values = this.getValues()): string | null {
    const { validation } = this.props
    return validation ? validation(values) ?? null : null
  }

  isValid(values = this.getValues()): boolean {
    if (this.hasInvalidField()) return false
    return !this.getCustomValidationError(values)
  }

  async validate() {
    await this.resetTouched(true)
    if (this.hasInvalidField()) {
      throw new Error('fail')
    }
    const error = (await this.getCustomValidationError()) ?? null

    await this.setStateAsync((state) => ({ ...state, error }))
    if (error) {
      throw new Error('fail')
    }
  }

  setValues(values: Partial<T> = {}) {
    Object.entries(values).forEach(([name, value]) => {
      this.fieldRefs[name]?.setValue(value)
    })
  }

  getValues(fields: Fields<T> = this.state.fields): Partial<T> {
    const entries = Object.entries(fields).map(([name, fieldState]) => [
      name,
      fieldState?.value ?? null,
    ])
    return Object.fromEntries(entries)
  }

  hasInvalidField(fields: Fields<T> = this.state.fields): boolean {
    return Object.values(fields).some((fieldState) => !!fieldState?.validationMessage)
  }

  getFirstInvalidFieldName(fields: Fields<T> = this.state.fields): keyof T | void {
    for (const entry of Object.entries(fields)) {
      const [name, state] = entry
      if (state?.validationMessage) return name
    }
  }

  hasModifiedField(fields: Fields<T> = this.state.fields): boolean {
    return Object.values(fields).some((field) => field?.value !== field?.defaultValue)
  }

  getFieldsValidity(fields: Fields<T> = this.state.fields): Record<keyof T, string | null> {
    const entries = Object.entries(fields).map(([name, fieldState]) => [
      name,
      !fieldState?.validationMessage,
    ])
    return Object.fromEntries(entries)
  }

  focusFirstInvalidField() {
    const name = this.getFirstInvalidFieldName()
    if (!name) return
    const ref = this.fieldRefs[name as string]
    if (!ref) return
    if (ref.focus) {
      return ref.focus()
    }
    if (ref.scrollIntoView) {
      return ref.scrollIntoView(SCROLL_OPTIONS)
    }
  }

  async setStateAsync(state: Parameters<Form<T>['setState']>[0]): Promise<unknown> {
    return await Promise.race([
      new Promise((resolve) => {
        this.setState(state, () => {
          resolve(null)
        })
      }),
      // not sure whether the React's setState
      // executes the callback when state has no changes
      new Promise((resolve) => setTimeout(resolve, 1)),
    ])
  }

  componentWillUnmount() {
    this._unmounted = true
  }

  componentDidMount(): void {
    if (this.props.instantValidation) {
      setTimeout(() => {
        this.validate().catch(() => {})
      }, 1)
    }
  }

  render() {
    const {
      onSubmit,
      children,
      onChange,
      redirect,
      className,
      validation,
      readOnly,
      defaultValues,
      disabled,
      instantValidation,
      ...props
    } = this.props

    const { error, pending, fields } = this.state

    const modified = this.hasModifiedField()
    const values = this.getValues()
    const valid = this.isValid()
    return (
      <form
        {...props}
        className={cn(styles.form, className)}
        onSubmit={this.submit}
        onReset={this.handleReset}
        noValidate
      >
        <ValidationMessage className={styles.error} error ref={this.errorMessageRef}>
          {(!this.hasInvalidField() && error) || null}
        </ValidationMessage>

        <FormFieldEmitter.Provider value={this.setFieldStatus}>
          <FormPropsProvider
            value={{
              id: props.id,
              readOnly,
              defaultValues,
              disabled,
              pending,
              modified,
              valid,
              values,
              fields,
            }}
          >
            {children}
          </FormPropsProvider>
        </FormFieldEmitter.Provider>
      </form>
    )
  }
}

type FormErrors<T extends object> = Partial<Record<keyof T, string>>

export type Emmitter<T extends Record<string, any> = {}, K extends keyof T = keyof T> = (
  state: Partial<FieldState<T, K>>,
) => Promise<void>

export type OnSubmitFormType<T extends Record<string, any> = {}> = (
  value: T | any,
) => Promise<T | void | any>
