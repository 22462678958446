import { ExpandableContainer } from './expandable-container'
import { ExpandebleProvider } from './expandable-context'
import { ToggleButton } from './toggle-button'

type Type = {
  Toggle: typeof ToggleButton
  Content: typeof ExpandableContainer
  Context: typeof ExpandebleProvider
}

export const Expandable: Type = {
  Toggle: ToggleButton,
  Content: ExpandableContainer,
  Context: ExpandebleProvider,
}
