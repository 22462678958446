import { IconClose, IconDoubleArrowLeft, IconDoubleArrowRight } from 'icons'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Navigate, To } from 'react-router-dom'
import { cn } from 'utils'
import styles from './image-modal.module.scss'
import { Backdrop } from '../backdrop'
import { Button } from '../button'
import { Image } from '../image'
import { Portal } from '../portal'
import { useKeydownListener } from '../use-keydown-listener'
import { useNavigate } from '../use-navigate/use-navigate'

interface Props {
  photos: { photo_id: string; url: string }[]
  linkFactory: (photo: { photo_id: string }) => To
  className?: string
  currentId: string
}

export const ImageModal: FC<Props> = ({ linkFactory, currentId, photos, className }) => {
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const [disappearing, setDisappearing] = useState(false)

  const hasPagination = photos?.length > 1

  const observerRef = useRef<IntersectionObserver>(
    new IntersectionObserver(
      (entries) => {
        const element = entries.find((entry) => {
          return entry.isIntersecting
        })?.target as HTMLElement
        const { index } = element?.dataset ?? {}
        index &&
          navigate(linkFactory(photos[Number(index)]), { replace: true, preventScrollReset: true })
      },
      { threshold: 0.85 },
    ),
  )
  const index = photos?.findIndex(({ photo_id }) => photo_id === currentId) ?? -1
  const scrollToIndex = useCallback((index: number, behavior: ScrollBehavior = 'smooth') => {
    const left = document.body.clientWidth * index
    ref.current?.scrollTo({ left, behavior })
  }, [])
  const next = useCallback(
    () => scrollToIndex(nextIndex(index, photos.length)),
    [index, scrollToIndex, photos.length],
  )
  const prev = useCallback(
    () => scrollToIndex(prevIndex(index, photos.length)),
    [index, scrollToIndex, photos.length],
  )
  const close = useCallback(async () => {
    setDisappearing(true)
    navigate(-1)
  }, [navigate])

  // initial scroll to current photo
  useEffect(
    () => {
      scrollToIndex(index, 'auto')
      const img = ref.current?.children?.[index]?.firstElementChild
      img && temporaryAddClass(img, styles.appear)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useKeydownListener(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          event.preventDefault()
          return close()
        case 'ArrowLeft':
          event.preventDefault()
          return prev()
        case 'ArrowRight':
          event.preventDefault()
          return next()
      }
    },
    [next, prev, navigate],
  )

  useEffect(() => {
    const imgs = ref.current?.children
    if (!imgs || imgs.length <= 1) return
    const observer = observerRef.current
    Array.from(imgs).forEach((element) => observer?.observe(element))
    return () => observer.disconnect()
  }, [])

  if (!photos?.length) return <Navigate to=".." replace />

  return (
    <Portal scrollLock>
      <Backdrop />
      <div className={cn(styles.canvas, className)} ref={ref}>
        {photos.map((photo, i) => (
          <div key={photo.photo_id} className={styles.frame} data-index={i} onClick={close}>
            <Image
              src={photo.url}
              className={cn(styles.img, index === i && disappearing && styles.disappear)}
            />
          </div>
        ))}
      </div>
      <Button className={styles.close} onClick={close} title="close">
        <IconClose />
      </Button>
      {hasPagination && (
        <Button className={styles.prev} onClick={prev} title="previous">
          <IconDoubleArrowLeft />
        </Button>
      )}
      {hasPagination && (
        <Button className={styles.next} onClick={next} title="next">
          <IconDoubleArrowRight />
        </Button>
      )}
    </Portal>
  )
}

const nextIndex = (index: number, length: number) => (index + 1 > length - 1 ? 0 : index + 1)
const prevIndex = (index: number, length: number) => (index - 1 < 0 ? length - 1 : index - 1)

const temporaryAddClass = (element: Element, className: string, timeout = 300) =>
  new Promise((resolve) => {
    element?.classList.add(className)
    setTimeout(() => {
      element?.classList.remove(className)
      resolve(undefined)
    }, timeout)
  })
