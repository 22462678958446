import { createRef, ReactNode, CSSProperties, FC } from 'react'
import { cn } from 'utils'
import styles from './infinite-multiselect.module.scss'
import { AutoButton } from '../../../auto-button'
import { Dropdown } from '../../../dropdown'
import { List } from '../../../list'
import listStyles from '../../../list/list.module.scss'
import { FieldDropdownCheckbox } from '../../field-dropdown/field-dropdown-checkbox'
import { FieldDropdownMenu } from '../../field-dropdown/field-dropdown-menu'
import { FieldDropdownToggle } from '../../field-dropdown/field-dropdown-toggle'
import fieldStyles from '../../field.module.scss'
import { LabelText } from '../../label/label'
import { RecentState, isBlankValue } from '../../utils/field-state'
import { withFormDefaultValues, BaseFieldProps } from '../_base'
import { StatefulField } from '../_stateful'

export type InfiniteMultiSelectProps<
  Item,
  HashValue extends string | number | boolean = string,
> = BaseFieldProps<HashValue[] | null> & {
  generator: (config: {
    signal: AbortSignal
    value: string | null
  }) => AsyncGenerator<{ items: Item[]; count: number }, void, unknown>

  serializeItem: (item: Item) => HashValue
  fetchItemsByHash: (hashes: HashValue[]) => Promise<Item[]>
  validationMessage?: string | null
  validationMessages?: Partial<Record<keyof ValidityState, string>>
  disabledOptions?: HashValue[]
  defaultValue?: HashValue[] | null | undefined
  placeholder?: string
  entitiesLabel?: string
  renderLabel: (item: Item) => ReactNode
  renderSelectedLabel?: (item: Item) => ReactNode
}

interface State<Item, HashValue extends string | number | boolean = string> {
  index: number
  value: HashValue[]
  searching: boolean
  done: boolean
  count: number
  items: Item[]
  currentItems: Item[]
}

export class Field<
  Item,
  HashValue extends string | number | boolean = string,
> extends StatefulField<
  HashValue[],
  InfiniteMultiSelectProps<Item, HashValue>,
  State<Item, HashValue>
> {
  static displayName = 'InfiniteMultiSelect'

  state = {
    opened: false,
    value: this.props.defaultValue ?? [],
    index: 0,
    items: [] as Item[],
    searching: false,
    done: false,
    count: 0,
    currentItems: [] as Item[],
  }

  recentState: RecentState<HashValue[] | null> = {
    value: [],
    valid: undefined,
    enable: undefined,
    validationMessage: null,
    defaultValue: [],
    touched: false,
  }

  private abortController: AbortController | null = null
  private generator: AsyncGenerator<{ items: Item[]; count: number }> | null = null

  optionsRef = createRef<HTMLDivElement>()

  add = (value: HashValue) => {
    this.setValue(Array.from(new Set([...(this.state.value ?? []), value])).sort())
    const item = this.findItem(value)
    if (item) {
      this.setState((state) => ({
        currentItems: [...state.currentItems, item],
      }))
    }
  }
  remove = (value: HashValue) => {
    this.setValue(this.state.value?.filter((v) => v !== value) ?? [])
    this.setState((state) => ({
      currentItems:
        state.currentItems?.filter((item) => this.props.serializeItem(item) !== value) ?? [],
    }))
  }
  toggleValue = (value: HashValue) => {
    if (this.state.value?.includes(value)) {
      this.remove(value)
    } else {
      this.add(value)
    }
  }
  clear = () => {
    this.setValue(null)
    this.setState({
      value: [],
      items: [],
      index: -1,
      count: 0,
      done: false,
      searching: false,
      currentItems: [],
    })
  }

  getValue() {
    return this.state.value?.length ? this.state.value : null
  }

  get className() {
    return cn(super.className, fieldStyles.field, styles.field)
  }

  resetResults() {
    this.abortController?.abort()
    this.abortController = new AbortController()
    const { signal } = this.abortController
    this.generator = this.props.generator({ signal, value: null })
    this.setState({ index: -1, count: 0, done: false, items: [] })
  }

  private next = async () => {
    if (!this.generator) return
    this.setState({ searching: true })
    try {
      const { value, done } = await this.generator.next()
      this.setState((state) => ({
        searching: false,
        done: !!done,
        ...(value && {
          count: value.count,
          items: [...(state.items ?? []), ...value.items],
        }),
      }))
      if (done) {
        this.generator = null
        this.abortController = null
      }
    } catch (error) {
      this.setState({ searching: false })
    }
  }

  findItem(hash: HashValue) {
    return this.state.items.find((item) => this.props.serializeItem(item) === hash)
  }

  renderValue() {
    const items = this.state.currentItems
    const length = this.state.value?.length ?? 0
    if (!length) return this.props.placeholder ?? null
    if (length > 1) {
      return (
        <span className={styles.amount}>
          {length} {this.props.entitiesLabel ?? 'items'}
        </span>
      )
    }
    const [item] = items
    return (
      <span key={String(this.props.serializeItem(item))} className={styles.pill}>
        {(this.props.renderSelectedLabel ?? this.props.renderLabel)(item)}
      </span>
    )
  }

  isOptionChecked = (value: HashValue) => {
    return Boolean(this.state.value?.includes(value))
  }
  isOptionDisabled = (value: HashValue) => {
    return this.props.disabled || this.props.disabledOptions?.includes(value)
  }
  renderOptions() {
    return (
      <FieldDropdownMenu noAutoFocus={this.state.index === -1}>
        <List
          as="div"
          items={this.state.items}
          renderItem={(item, index, items) =>
            this.renderOption(item, index, items) ?? this.renderEmpty()
          }
          renderFooter={() => this.renderLoader()}
          role="listbox"
          aria-busy={this.state.searching}
        />
      </FieldDropdownMenu>
    )
  }
  renderOption(item: Item, index: number, items: Item[]) {
    const value = this.props.serializeItem(item)
    const isLast = index === items.length - 1
    return (
      <FieldDropdownCheckbox
        autoFocus={index === 0}
        checked={this.isOptionChecked(value)}
        className={listStyles.item}
        disabled={this.isOptionDisabled(value)}
        key={String(value)}
        onFocus={isLast ? () => this.next() : undefined}
        onToggle={this.toggleValue}
        role="option"
        title={this.props.renderLabel(item)}
        value={value}
      />
    )
  }
  renderLoader() {
    const { items, count, searching, done } = this.state
    if (done) return null
    if (count && count === items.length) return null
    return (
      <AutoButton
        onClick={this.next}
        className={listStyles.more}
        style={{ '--count': count - items.length } as CSSProperties}
        disabled={searching}
      >
        load more
      </AutoButton>
    )
  }
  renderEmpty(items: Item[] = this.state.items) {
    if (items.length) return null
    return (
      <div className={styles.state}>
        {this.state.searching ? 'Searching...' : 'No results. Type to search'}
      </div>
    )
  }
  componentDidMount(): void {
    super.componentDidMount()
    this.resetResults()
    const value = this.getValue()
    if (!isBlankValue(value)) {
      this.props.fetchItemsByHash(value).then((currentItems) => {
        this.setState((state) => ({ ...state, currentItems }))
      })
    }
  }

  get labelClassName() {
    return cn(super.labelClassName, fieldStyles.labelText, this.props.labelClassName)
  }

  render() {
    const { disabled, required, readOnly, name, placeholder } = this.props
    return (
      <Dropdown.Container>
        <div
          className={this.className}
          data-field-type="multiselect"
          aria-required={required ? 'true' : 'false'}
          aria-disabled={disabled ? 'true' : 'false'}
        >
          <LabelText className={this.labelClassName}>{this.label}</LabelText>
          <FieldDropdownToggle
            name={name}
            disabled={disabled || readOnly}
            placeholder={placeholder}
          >
            {this.renderValue()}
          </FieldDropdownToggle>

          {this.renderOptions()}
          {this.shouldShowValidity() && this.renderValidationMessage()}
        </div>
      </Dropdown.Container>
    )
  }
}

export const InfiniteMultiSelect = withFormDefaultValues<
  string[],
  InfiniteMultiSelectProps<string>
>(Field) as FC<InfiniteMultiSelectProps<unknown>>
