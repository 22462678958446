import mapboxgl, { LngLat, LngLatBounds } from 'mapbox-gl'
import { FC, useCallback, useLayoutEffect, useRef, ReactNode, useState, useEffect } from 'react'
import { cn } from 'utils'
import { MapContextProvider } from './context'
import styles from './map.module.scss'

const token = import.meta.env.VITE_MAPBOX_TOKEN
if (token) {
  mapboxgl.accessToken = token
} else {
  // eslint-disable-next-line no-console
  console.warn('Mapbox token is not set')
}

export type MoveEvent = (position: { lng: number; lat: number; zoom: number }) => void

const MAP_OPTIONS: Partial<mapboxgl.MapboxOptions> = {
  style: import.meta.env.VITE_MAPBOX_STYLE ?? 'mapbox://styles/mapbox/streets-v12',
  minZoom: 5,
  fitBoundsOptions: { padding: 96, maxZoom: 16 },
  dragRotate: false,
  touchZoomRotate: false,
} as const

const DEFAULT_POSITION = {
  zoom: 13,
  center: new LngLat(-74, 40.7),
} as const

interface Props {
  className?: string
  lng?: number | undefined
  lat?: number | undefined
  zoom?: number | undefined
  onMove?: MoveEvent
  children?: ReactNode
  bounds?: LngLatBounds
  scrollZoom?: mapboxgl.MapboxOptions['scrollZoom']
}
export const MapContainer: FC<Props> = ({
  className,
  children,
  onMove,
  lng,
  lat,
  zoom,
  bounds,
  scrollZoom,
}) => {
  const mapContainer = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<mapboxgl.Map | null>(null)
  const [height, setHeight] = useState<number>(0)
  const hasCenterAndZoom = !!lng && !!lat && !!zoom

  useLayoutEffect(() => {
    if (!mapContainer.current) return
    const container = mapContainer.current
    const height = container.parentElement?.clientHeight
    setHeight(height ?? 0)
  }, [])

  const handleMove = useCallback(
    (event: mapboxgl.MapboxEvent) => {
      const { lat, lng } = event.target.getCenter()
      const zoom = event.target.getZoom()
      onMove?.({ lat, lng, zoom })
    },
    [onMove],
  )

  useEffect(() => {
    if (!mapContainer.current || map || !height) return

    const _map = new mapboxgl.Map({
      ...MAP_OPTIONS,
      container: mapContainer.current,
      ...(hasCenterAndZoom
        ? { center: new LngLat(lng, lat), zoom }
        : bounds && !bounds.isEmpty()
        ? { bounds }
        : DEFAULT_POSITION),
    })

    setMap(_map)
    return () => {
      _map?.remove()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height])

  useEffect(() => {
    if (!map || scrollZoom === undefined) return
    scrollZoom ? map?.scrollZoom.enable() : map?.scrollZoom.disable()
  }, [map, scrollZoom])

  useEffect(() => {
    if (!map || !onMove) return
    map.on('moveend', handleMove)
    return () => {
      map?.off('moveend', handleMove)
    }
  }, [handleMove, map, onMove])

  useEffect(() => {
    if (!map) return
    if (hasCenterAndZoom) {
      map.setCenter(new LngLat(lng, lat))
    } else if (bounds && !bounds.isEmpty()) {
      map.fitBounds(bounds, MAP_OPTIONS.fitBoundsOptions)
    }
  }, [bounds, hasCenterAndZoom, map, lat, lng])

  return (
    <div className={cn(styles.map, className)}>
      <div ref={mapContainer} style={{ height }} />
      {map && <MapContextProvider value={map}>{children}</MapContextProvider>}
    </div>
  )
}
