import { FC, ReactNode, ComponentProps } from 'react'
import { cn } from 'utils'
import { Link } from './link'
import styles from './link.module.scss'
import { Button } from '../button'
import { useNavigate } from '../use-navigate/use-navigate'

interface Props extends Omit<ComponentProps<typeof Button>, 'onClick' | 'theme'> {
  children?: ReactNode
  className?: string
  theme?: ComponentProps<typeof Link>['theme']
  small?: ComponentProps<typeof Link>['small']
}

export const LinkBack: FC<Props> = ({
  children,
  className,
  theme = 'primary',
  small,
  ...props
}) => {
  const navigate = useNavigate()
  const navigateBack = () => navigate(-1)
  return (
    <Button
      {...props}
      className={cn(styles.link, theme && styles[theme], small && styles.small, className)}
      onClick={navigateBack}
    >
      {children}
    </Button>
  )
}
