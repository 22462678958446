import { FC } from 'react'
import { DropdownButton } from './dropdown-button'
import { DropdownExternalLink } from './dropdown-external-link'
import { DropdownLink } from './dropdown-link'
import { Props, DropdownMenu as _DropdownMenu } from './dropdown-menu'

export const DropdownMenu: FC<Props> & {
  Button: typeof DropdownButton
  External: typeof DropdownExternalLink
  Link: typeof DropdownLink
} = Object.assign(_DropdownMenu, {
  Button: DropdownButton,
  External: DropdownExternalLink,
  Link: DropdownLink,
})
