import { RefObject, useLayoutEffect, useState } from 'react'

export function useContainerZoom(
  ref: RefObject<HTMLDivElement>,
  {
    pageWidth,
  }: {
    pageWidth: number
  },
): number {
  const [zoom, setZoom] = useState(0)

  useLayoutEffect(() => {
    if (!ref.current) return
    const bounds = ref.current.getBoundingClientRect()
    setZoom(bounds.width / pageWidth)
    const observer = new ResizeObserver((entries) => {
      const bounds = entries[0]?.contentRect
      bounds && setZoom(bounds.width / pageWidth)
    })
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [pageWidth, ref])

  return zoom
}
