import { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { cn } from 'utils'
import styles from './scroll-view.module.scss'

type Props = {
  children?: ReactNode | ReactNode[]
  className?: string
  onEdge?: (edge: Record<Edge, boolean>) => void
}

export const ScrollView = forwardRef<ScrollViewAPI, Props>(
  ({ children, className, onEdge }, ref) => {
    const [edge, setEdges] = useState({ start: false, end: false })

    const refView = useRef<HTMLUListElement>(null)
    const refStart = useRef<HTMLLIElement>(null)
    const refEnd = useRef<HTMLLIElement>(null)

    const observerRef = useRef<IntersectionObserver>(
      new IntersectionObserver(
        (entries) => {
          const changes = Object.fromEntries(
            entries.map((entry) => {
              const element = entry.target as HTMLDivElement
              const edge = element.dataset.edge as Edge
              return [edge, entry.isIntersecting]
            }),
          )
          setEdges((edges) => ({ ...edges, ...changes }))
        },
        { threshold: [0, 1] },
      ),
    )

    useEffect(() => {
      const observer = observerRef.current
      refStart.current && observer.observe(refStart.current)
      refEnd.current && observer.observe(refEnd.current)
      return () => {
        observer.disconnect()
      }
    }, [])

    useImperativeHandle(ref, () => {
      return {
        prev() {
          const element = refView.current
          if (!element) return
          element.scrollBy({ left: -element.offsetWidth, behavior: 'smooth' })
        },
        next() {
          const element = refView.current
          if (!element) return
          element.scrollBy({ left: element.offsetWidth, behavior: 'smooth' })
        },
        element: refView.current,
      }
    })

    useEffect(() => {
      onEdge?.(edge)
    }, [edge, onEdge])

    return (
      <ul
        className={cn(
          styles.view,
          edge.start && styles.atStart,
          edge.end && styles.atEnd,
          className,
        )}
        ref={refView}
      >
        <li className={styles.start} data-edge="start" ref={refStart} />
        {children}
        <li className={styles.end} data-edge="end" ref={refEnd} />
      </ul>
    )
  },
)

export const enum Edge {
  start = 'start',
  end = 'end',
}

export type ScrollViewAPI = {
  prev(): void
  next(): void
  element?: HTMLUListElement | null
}

ScrollView.displayName = 'ScrollView'
