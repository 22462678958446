import { FC, ReactNode } from 'react'
import { cn } from 'utils'
import styles from './navbar.module.scss'

interface Props {
  className?: string
  children: ReactNode
}
export const Navbar: FC<Props> = ({ className, children }) => {
  return (
    <nav role="menu" className={cn(styles.nav, className)}>
      <ul className={styles.list}>{children}</ul>
    </nav>
  )
}
