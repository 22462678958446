import { ComponentProps } from 'react'
import { toISODate, DateValue } from 'utils/date'
import {
  BaseHtmlValidationInput,
  BaseHtmlValidationProps,
} from '../input-text/base-html-validation-input'

export class DateInput extends BaseHtmlValidationInput<
  DateInput.Value,
  DateInput.Props,
  HTMLInputElement
> {
  static defaultProps = {
    validationMessages: {},
  }

  format(value: DateInput.Value | null | undefined): string {
    return toISODate(value) || ''
  }

  getValue(): DateInput.Value | null | undefined {
    return toISODate(this.element?.valueAsDate)
  }

  equalValues(a: DateInput.Value, b: DateInput.Value) {
    return toISODate(a) === toISODate(b)
  }

  getValidationMessage() {
    return this.checkValidityProps(VALIDITY_PROPS) || this.checkCustomValidity()
  }

  render() {
    const { validation, validationMessages, validationMessage, defaultValue, ...props } = this.props
    return (
      <input
        {...props}
        defaultValue={this.format(defaultValue)}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        type="date"
      />
    )
  }
}

export namespace DateInput {
  export type Value = DateValue
  export type Props = BaseHtmlValidationProps<Value> & Pick<ComponentProps<'input'>, 'min' | 'max'>
}

const VALIDITY_PROPS: (keyof ValidityState)[] = [
  'valueMissing',
  'badInput',
  'typeMismatch',
  'stepMismatch',
  'rangeOverflow',
  'rangeUnderflow',
]
