import { useCallback } from 'react'
import {
  useRevalidator,
  useNavigate as useRouterNavigate,
  To,
  NavigateOptions as RouterNavigateOptions,
  useLocation,
} from 'react-router'
import { resolveLinkParams } from '../link'

export type UseNavigateOptions = {
  revalidate?: boolean
  back?: boolean
  preserveSearch?: boolean
  replace?: boolean
}

export const useNavigate = ({
  revalidate,
  back,
  preserveSearch,
  replace,
}: UseNavigateOptions = {}) => {
  const location = useLocation()
  const revalidator = useRevalidator()
  const routerNavigate = useRouterNavigate()

  return useCallback(
    (to: To | -1, navigateOptions?: typeof to extends number ? never : RouterNavigateOptions) => {
      if ((to === -1 || back) && location.state?.from) {
        routerNavigate(-1)
      } else if (typeof to === 'number') {
        routerNavigate(to)
      } else {
        const link = resolveLinkParams({
          from: location,
          preserveSearch,
          to,
          state: navigateOptions?.state,
        })
        const options = { replace, ...navigateOptions, state: link.state }
        routerNavigate(link.to, options)
      }
      revalidate && revalidator.revalidate()
    },
    [back, location, revalidate, revalidator, routerNavigate, preserveSearch, replace],
  )
}
