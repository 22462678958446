import { forwardRef, ComponentProps, MouseEventHandler, useCallback } from 'react'
import { Button } from '../../button'
import { useFormEmitter } from '../field/_base/use-field-control'
import { useFormProps } from '../form/context'

export interface SubmitButtonProps extends Omit<ComponentProps<typeof Button>, 'type'> {
  disableUnmodified?: boolean
  disableInvalid?: boolean
}

export const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
  ({ disabled, disableUnmodified, disableInvalid, onClick, ...props }, ref) => {
    const { pending, modified, valid } = useFormProps()
    const emitter = useFormEmitter<Record<string, string>>()

    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        if (!event.currentTarget) return
        const { name, value } = event.currentTarget
        if (name && value !== undefined) {
          emitter({ name, value, enable: true, validationMessage: null })
        }
        onClick?.(event)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onClick],
    )

    return (
      <Button
        theme="primary"
        {...props}
        type="submit"
        ref={ref}
        disabled={
          disabled || pending || (disableUnmodified && !modified) || (disableInvalid && !valid)
        }
        onClick={handleClick}
      />
    )
  },
)

SubmitButton.displayName = 'SubmitButton'
