import { ComponentProps, FC, MouseEventHandler, ReactElement, useCallback, ReactNode } from 'react'
import { cn } from 'utils'
import { useDropdownContext } from './context'
import styles from './link.module.scss'
import { Badge } from '../badge'
import { Button } from '../button'

type Props = Omit<ComponentProps<typeof Button>, 'children' | 'title'> &
  Pick<ComponentProps<typeof Badge>, 'severnity' | 'amount'> & {
    title: ReactNode
    icon?: ReactElement
    className?: string
    preventClosing?: boolean
  }

export const DropdownMenuButton: FC<Props> = ({
  className,
  title,
  icon,
  onClick,
  severnity,
  amount = 0,
  preventClosing,
  ...props
}) => {
  const { close } = useDropdownContext() ?? {}

  const click = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      onClick?.(event)
      !preventClosing && close?.()
    },
    [close, onClick, preventClosing],
  )
  return (
    <Button
      {...props}
      className={cn(props.theme !== 'none' && styles.link, className)}
      onClick={click}
      data-dropdown="button"
      tabIndex={-1}
    >
      {icon}
      <span className={styles.title}>{title}</span>
      {amount > 0 && <Badge amount={amount} severnity={severnity} className={styles.badge} />}
    </Button>
  )
}
