import React, { FC } from 'react'
import { cn } from 'utils'
import styles from './stateful-loader.module.scss'
import { Loader } from '../BaseLoder'

interface Props {
  className?: string
  children?: React.ReactNode | React.ReactNode[]
}

export const StatefulLoader: FC<Props> = ({ children, className, ...props }) => {
  return (
    <div className={cn(styles.wrapper, className)}>
      <Loader className={styles.loader} />
      <span className={styles.label}>{children}</span>
    </div>
  )
}
