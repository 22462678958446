import { ComponentProps, FC, Suspense } from 'react'
import { Outlet as RouterOutlet } from 'react-router-dom'
import { Loader } from '../loader'

interface Props extends ComponentProps<typeof RouterOutlet> {
  className?: string
}

export const Outlet: FC<Props> = ({ className, ...props }) => {
  return (
    <Suspense fallback={<Loader className={className} />}>
      <RouterOutlet {...props} />
    </Suspense>
  )
}
