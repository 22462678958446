import { forwardRef, ComponentProps } from 'react'
import { Button } from '../../button'
import { useFormProps } from '../form/context'

export interface ResetButtonProps extends Omit<ComponentProps<typeof Button>, 'type'> {
  disableUnmodified?: boolean
}

export const ResetButton = forwardRef<HTMLButtonElement, ResetButtonProps>(
  ({ disabled, disableUnmodified, ...props }, ref) => {
    const { pending, modified } = useFormProps()
    return (
      <Button
        {...props}
        type="reset"
        ref={ref}
        disabled={(disableUnmodified && !modified) || disabled || pending}
      />
    )
  },
)

ResetButton.displayName = 'ResetButton'
