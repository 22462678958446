import { FC, ReactNode, ComponentProps } from 'react'
import { Link as RouterLink, useLocation, To, Location } from 'react-router-dom'
import { cn } from 'utils'
import styles from './link.module.scss'

type Props = ComponentProps<typeof RouterLink> & {
  children?: ReactNode
  className?: string
  theme?: 'primary' | 'secondary' | 'ternary'
  small?: boolean
  preserveSearch?: boolean
  unstyled?: boolean
}

export const Link: FC<Props> = ({
  children,
  className: _className,
  small,
  theme = 'primary',
  state: _state,
  to: _to,
  preserveSearch,
  unstyled,
  ...props
}) => {
  const from = useLocation()
  const { state, to } = resolveLinkParams({
    to: _to,
    state: _state,
    from,
    preserveSearch,
  })
  const className = unstyled
    ? _className
    : cn(styles.link, styles[theme], small && styles.small, _className)
  return (
    <RouterLink {...props} to={to} className={className} state={state}>
      {children}
    </RouterLink>
  )
}

function joinSearch(search?: string, query?: string) {
  if (query && search) return `${search}&${query.replace(/^\?/, '')}`
  return search || query || ''
}

export function resolveLinkParams({
  to: _to,
  state: _state,
  from,
  preserveSearch,
}: {
  to: To
  state: any
  from: Pick<Location, 'pathname' | 'search'>
  preserveSearch?: boolean
}) {
  const to = getObjectTo(_to)
  if (preserveSearch) to.search = joinSearch(to.search, from.search)
  const state = { ..._state, from: { pathname: from.pathname, search: from.search } }
  return { to, state }
}

function getObjectTo(to: To): Exclude<To, string> {
  if (typeof to === 'string') {
    const [pathname, search] = to.split('?')
    return { pathname, search }
  }
  return to ?? {}
}
