import { cn } from 'utils'
import styles from './one-time-code.module.scss'
import { TextInput, TextInputProps } from '../../input-text/text-input'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'
type Props = Omit<TextInputProps, 'type' | 'autoComplete' | 'autoCapitalize' | 'autoCorrect'> &
  BaseFieldProps<string>
export class Field extends BaseField<string, Props> {
  static displayName = 'OneTimeCodeField'

  renderInput(props: TextInputProps) {
    return (
      <TextInput
        pattern="\d{6}"
        validationMessages={MSG}
        placeholder="⦁⦁⦁⦁⦁⦁"
        {...props}
        className={cn(props.className, styles.input)}
        type="text"
        autoComplete="one-time-code"
        autoCorrect="off"
        inputMode="numeric"
      />
    )
  }
}

const MSG: Partial<Record<keyof ValidityState, string>> = {
  patternMismatch: 'Please enter a valid code',
}

export const OneTimeCodeField = withFormDefaultValues<string, Props>(Field)

OneTimeCodeField.defaultProps = {}
