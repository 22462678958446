import { NAME_ERROR, NAME_REGEXP } from './validation'
import { TextInput, TextInputProps } from '../../input-text/text-input'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'
type Props = Omit<TextInputProps & BaseFieldProps<string>, 'autoComplete' | 'autoCapitalize'>
class Field extends BaseField<string, Props> {
  static displayName = 'LastNameField'

  getInputProps() {
    return {
      ...super.getInputProps(),
      autoComplete: 'family-name',
      autoCapitalize: 'words',
    }
  }

  renderInput({ validation, ...props }: TextInputProps) {
    return (
      <TextInput
        {...props}
        validation={(value) => {
          if (!value?.trim()) return null
          if (!NAME_REGEXP.test(value)) return `${this.props.label} ${NAME_ERROR}`
          return validation?.(value) ?? null
        }}
      />
    )
  }
}

export const LastNameField = withFormDefaultValues<string, Props>(Field)
