import {
  ComponentProps,
  ChangeEventHandler,
  Children,
  cloneElement,
  isValidElement,
  LegacyRef,
  ReactElement,
} from 'react'
import { BaseInput, BaseInputProps } from '../input-text/base-input'
import { optionsConverter, Options } from '../utils/options'

type Value = string | number | boolean | null
type E = HTMLDivElement

type OptionProps = Pick<ComponentProps<'input'>, 'value' | 'disabled' | 'onChange'>
export type InputRadioProps = BaseInputProps<Value> & {
  className?: string
  options?: Options<Value>
  optionComponent?: 'input'
  children?: ReactElement<OptionProps> | ReactElement<OptionProps>[]
}

export class InputRadio extends BaseInput<Value, InputRadioProps> {
  element: E | null = null

  setElement: LegacyRef<E> = (element: E) => {
    this.element = element
    if (!element) return
    const value = this.props.defaultValue || null
    // element.addEventListener('change', (event) => {
    //   if ((event.target as E)?.matches('input')) {
    //     this.handleChange(event as unknown as ChangeEvent<HTMLInputElement>)
    //   }
    // })
    // let the parent form knows about this control
    this.broadcastUpdates({ value: this.normalizeValue(value) })
  }

  handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value, checked } = event.target
    if (!checked) return
    this.broadcastUpdates({ value: this.normalizeValue(value), touched: true })
  }

  normalizeValue(value: Value | string | null): Value | null {
    if (value === null) {
      return null
    }

    const options = this.props.options

    const option = Array.isArray(options)
      ? options.find((option) => typeof option !== 'string' && String(option?.value) === value)
      : null

    return option ? option.value : value
  }

  isValid() {
    if (!this.element) return false
    if (this.props.required && this.getValue() === null) return false
    if (this.checkCustomValidity()) return false
    return true
  }

  getValidationMessage() {
    if (this.props.required && this.getValue() === null) return 'Required field'
    return this.checkCustomValidity() || null
  }

  setValue(value = null) {
    if (!this.element) return
    const radio: HTMLInputElement | null = this.element.querySelector(`input[value="${value}"]`)
    if (radio) {
      radio.checked = true
      this.broadcastUpdates({ value })
    }
  }

  getValue(): Value | null {
    if (!this.element) return null
    const radio = this.findCheckedInput()
    const value = radio && radio.value
    return value === '' ? null : this.normalizeValue(value)
  }

  findInput(extraSelector?: string): HTMLInputElement | null {
    const selector = `input[name="${this.props.name}"]:enabled${extraSelector ?? ''}`
    return this.element?.querySelector(selector) ?? null
  }
  findCheckedInput() {
    return this.findInput(':checked')
  }
  findByValue(value: Value) {
    return this.findInput(`[value="${value}"]`)
  }

  focus() {
    const option = this.findCheckedInput() || this.findInput()
    option ? option.focus() : this.scrollIntoView()
  }

  scrollIntoView(options: ScrollIntoViewOptions = { behavior: 'smooth' }) {
    this.element?.scrollIntoView(options)
  }

  renderOptions(options: Options<Value>, props: ComponentProps<'input'>) {
    const { optionComponent: Option = 'input', name } = this.props
    if (!Option) {
      throw new Error('RadioInputGroup: invalid prop: optionComponent')
    }
    return optionsConverter(options).map(([label, value]) => (
      <label key={String(value)}>
        <Option
          type="radio"
          data-lpignore="true"
          {...props}
          value={String(value)}
          name={name}
          key={name + value}
          defaultChecked={this.equalValues(value, this.props.defaultValue)}
        />
        <span>{label}</span>
      </label>
    ))
  }

  render() {
    const {
      disabled,
      validation,
      options,
      className,
      id,
      children,
      required,
      optionComponent,
      readOnly,
      defaultValue,
      ...restProps
    } = this.props

    const props: ComponentProps<'input'> = {
      ...restProps,
      onChange: this.handleChange,
      disabled: readOnly || disabled,
    }

    return (
      <div ref={this.setElement} id={id} className={className}>
        {options
          ? this.renderOptions(options, props)
          : Children.map(children, (child) => isValidElement(child) && cloneElement(child, props))}
      </div>
    )
  }
}
