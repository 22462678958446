import { ReactNode } from 'react'
import { cn } from 'utils'
import styles from './custom-select.module.scss'
import { Dropdown } from '../../../dropdown'
import { List } from '../../../list'
import listStyles from '../../../list/list.module.scss'
import { FieldDropdownMenu } from '../../field-dropdown/field-dropdown-menu'
import { FieldDropdownToggle } from '../../field-dropdown/field-dropdown-toggle'
import fieldStyles from '../../field.module.scss'
import { LabelText } from '../../label/label'
import { Option, Options, optionsConverter } from '../../utils/options'
import { withFormDefaultValues, BaseFieldProps } from '../_base'
import { StatefulField } from '../_stateful'

export type Props<T extends string | number | boolean | null> = BaseFieldProps<T> & {
  options: Options<T>
  validationMessage?: string | null
  validationMessages?: Partial<Record<keyof ValidityState, string>>
  disabledOptions?: T[]
  defaultValue?: T | null | undefined
  placeholder?: string
}

interface State<T> {
  value: T | null
}

export class Field<T extends string | number | boolean | null> extends StatefulField<
  T,
  Props<T>,
  State<T>
> {
  static displayName = 'CustomSelect'

  state = {
    value: this.props.defaultValue ?? null,
  }

  private getPlaceholderAsOption(): [ReactNode, T | null, boolean] {
    return [this.props.placeholder ?? 'Please Select...', null, !!this.props.required]
  }

  private getOptions() {
    return [
      this.getPlaceholderAsOption(),
      ...optionsConverter(this.props.options, this.props.disabledOptions),
    ]
  }

  private findOptionByValue(v?: null | T): null | Option<T> {
    const options = optionsConverter(this.props.options)
    if (!options?.length) return null
    const option = options.find(([, value]) => value === v)
    if (!option) return null
    const [label, value, disabled] = option
    return { label, value, disabled }
  }

  get className() {
    return cn(super.className, fieldStyles.multiselect)
  }

  renderValue(option: null | Option<T>) {
    if (!option) return null
    return option.label
  }

  renderOptions() {
    const options = this.getOptions()
    return (
      <FieldDropdownMenu>
        <List
          as="div"
          items={options}
          renderItem={(item) => this.renderOption(...item)}
          role="listbox"
        />
      </FieldDropdownMenu>
    )
  }

  renderOption(label: ReactNode, value: T | null, disabled: boolean) {
    const selected = Boolean(this.state.value === value)
    return (
      <Dropdown.Button
        aria-selected={selected}
        className={cn(listStyles.item, styles.option)}
        disabled={disabled}
        key={String(value)}
        onClick={() => this.setValue(value)}
        role="option"
        theme="none"
        title={String(label)}
        value={String(value)}
      />
    )
  }

  isValueBlank(value?: T | null): boolean {
    return value === null
  }

  render() {
    const { label, disabled, readOnly, name, placeholder } = this.props
    return (
      <Dropdown.Container>
        <div className={this.className} data-field-type="multiselect">
          <LabelText>{label}</LabelText>

          <FieldDropdownToggle
            disabled={disabled || readOnly}
            name={name}
            placeholder={placeholder}
          >
            {this.renderValue(this.findOptionByValue(this.state.value))}
          </FieldDropdownToggle>

          {this.renderOptions()}
          {this.shouldShowValidity() && this.renderValidationMessage()}
        </div>
      </Dropdown.Container>
    )
  }
}

export const CustomSelect = withFormDefaultValues<any, Props<any>>(Field)
