import { FC } from 'react'
import { Loader } from 'ui'
import styles from './text-loader.module.scss'

interface Props {
  text: string
}
export const TextLoader: FC<Props> = ({ text }) => {
  return (
    <div className={styles.loader}>
      <Loader />
      <span>{text}</span>
    </div>
  )
}
