import { ComponentProps, FC } from 'react'
import { cn } from 'utils'
import styles from './address.module.scss'

interface Props extends Omit<ComponentProps<'address'>, 'children'> {
  short?: boolean
  value: {
    district?: string
    street: string
    city: string
    state?: string
    zip: string
  }
}
export const Address: FC<Props> = ({ className, value, short, ...props }) => {
  return (
    <address className={cn(styles.address, className)} {...props}>
      {short ? (
        value.street
      ) : (
        <>
          {join([value.street, value.district])}
          <br />
          {join([value.city, value.state, value.zip])}
        </>
      )}
    </address>
  )
}

const join = (arr: (string | null | undefined)[], separator = ', ') =>
  arr.filter(Boolean).join(separator)
