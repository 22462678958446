import axios, {
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { logErrorResponse, logSuccessResponse } from './axios-logger'
import { BackendError } from './backend-error'
import { baseURL } from './base-url'

export type GetConfig = Omit<AxiosRequestConfig, 'params' | 'method'>
export type PostConfig = Omit<AxiosRequestConfig, 'data' | 'method'>
export type DeleteConfig = Omit<AxiosRequestConfig, 'method'>

interface Auth {
  ready: Promise<any>
  signOut: () => Promise<any>
  getToken: () => Promise<string | null>
}

const instance = axios.create({ baseURL })
export class Client {
  static instance = instance
  static auth: Auth
  static connectWithAuthProvider(auth: Auth) {
    Client.auth = auth
  }
  static async getAuthHeader(): Promise<string | undefined> {
    const token = await Client.auth.getToken()
    return token ? `Bearer ${token}` : undefined
  }
  static async handleRequest(config: InternalAxiosRequestConfig) {
    const auth = await Client.getAuthHeader()
    if (auth) config.headers.set('Authorization', auth)
    return config
  }

  static handleFailedResponse(error: AxiosError<any, { error?: string }>) {
    const backendError = new BackendError(error.message, { cause: error })
    if (!backendError.isCancelled) logErrorResponse(error)
    return Promise.reject(backendError)
  }

  static handleSuccededResponse(response: AxiosResponse) {
    return logSuccessResponse(response)
  }

  async isAuthorized() {
    await Client.auth.ready
    const token = await Client.getAuthHeader()
    return !!token
  }

  async get<Result, Query = undefined>(
    url: string,
    params?: Query,
    config?: GetConfig,
  ): Promise<Result> {
    await Client.auth.ready
    const response = await Client.instance.get<Result>(url, { ...config, params })
    return response.data
  }

  async delete(url: string, config?: DeleteConfig): Promise<void> {
    await Client.auth.ready
    await Client.instance.delete(url, config)
  }

  async post<D, T>(url: string, data?: D, config?: PostConfig): Promise<T> {
    await Client.auth.ready
    const response = await Client.instance.post<T>(url, data, config)
    return response.data
  }
  async put<D, T>(url: string, data?: D, config?: PostConfig): Promise<T> {
    await Client.auth.ready
    const response = await Client.instance.put<T>(url, data, config)
    return response.data
  }
}

instance.interceptors.request.use(Client.handleRequest)
instance.interceptors.response.use(Client.handleSuccededResponse, Client.handleFailedResponse)
