import { FC, ComponentProps } from 'react'
import { HeadsUpErrors } from './heads-up-errors'

type Props = ComponentProps<typeof HeadsUpErrors>

export const HeadsUpError: FC<Props> = ({ children, ...props }) => {
  return (
    <HeadsUpErrors {...props}>
      <li>{children}</li>
    </HeadsUpErrors>
  )
}
