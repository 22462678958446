import { ComponentProps, FC, ReactElement } from 'react'
import { cn } from 'utils'
import { useDropdownContext } from './context'
import styles from './link.module.scss'
import { Badge } from '../badge'
import { ExternalLink } from '../link'

export type Props = Omit<ComponentProps<typeof ExternalLink>, 'children'> &
  Pick<ComponentProps<typeof Badge>, 'severnity' | 'amount'> & {
    title: string
    icon?: ReactElement
    className?: string
  }

export const DropdownMenuExternalLink: FC<Props> = ({
  className,
  title,
  icon,
  onClick,
  severnity,
  amount = 0,
  ...props
}) => {
  const { close } = useDropdownContext() ?? {}

  return (
    <ExternalLink
      {...props}
      className={cn(styles.link, className)}
      onClick={close}
      tabIndex={-1}
      data-dropdown="link"
    >
      {icon}
      <span className={styles.title}>{title}</span>
      {amount > 0 && <Badge amount={amount} severnity={severnity} className={styles.badge} />}
    </ExternalLink>
  )
}
