import { ComponentProps } from 'react'
import { DateValue, fromInputDatetime, toInputDatetime } from 'utils/date'
import {
  BaseHtmlValidationInput,
  BaseHtmlValidationProps,
} from '../input-text/base-html-validation-input'

export class DatetimeLocalInput extends BaseHtmlValidationInput<
  DatetimeLocalInput.Value,
  DatetimeLocalInput.Props,
  HTMLInputElement
> {
  static defaultProps = {
    validationMessages: {},
  }

  format(value: DatetimeLocalInput.Value | null | undefined): string {
    return toInputDatetime(value) || ''
  }

  getValue(): DatetimeLocalInput.Value | null | undefined {
    const value = this.element?.valueAsNumber
    return value ? fromInputDatetime(value) : null
  }

  equalValues(a: DatetimeLocalInput.Value, b: DatetimeLocalInput.Value) {
    return toInputDatetime(a) === toInputDatetime(b)
  }

  getValidationMessage() {
    return this.checkValidityProps(VALIDITY_PROPS) || this.checkCustomValidity()
  }

  render() {
    const { validation, validationMessages, validationMessage, defaultValue, ...props } = this.props
    return (
      <input
        step="any"
        {...props}
        defaultValue={this.format(defaultValue)}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        type="datetime-local"
      />
    )
  }
}

export namespace DatetimeLocalInput {
  export type Value = DateValue
  export type Props = BaseHtmlValidationProps<Value> &
    Pick<ComponentProps<'input'>, 'step' | 'min' | 'max' | 'autoComplete'>
}

const VALIDITY_PROPS: (keyof ValidityState)[] = [
  'valueMissing',
  'badInput',
  'typeMismatch',
  'stepMismatch',
  'rangeOverflow',
  'rangeUnderflow',
]
