import { ComponentProps, FC, ReactElement, useEffect, useMemo, useState } from 'react'
import { cn } from 'utils'
import { parseDate } from 'utils/date'
import styles from './timer.module.scss'

type TimerLabel = {
  day?: (amount: number) => string
  hour?: (amount: number) => string
  minute?: (amount: number) => string
  second?: (amount: number) => string
}

const LABELS: TimerLabel = {
  day: (n: number) => `${n}d`,
  hour: (n: number) => `${n}h`,
  minute: (n: number) => `${n}m`,
  second: (n: number) => `${n}s`,
}

interface Props extends Omit<ComponentProps<'time'>, 'children'> {
  className?: string
  value: Date | string | number | null | undefined
  onComplete?(): void
  completeLabel?: string
  colorful?: boolean
  append?: string | ReactElement
  prepend?: string | ReactElement
  labels?: TimerLabel
}

const _Timer: FC<Props> = ({
  value,
  completeLabel,
  onComplete,
  colorful,
  append,
  prepend,
  className,
  labels = LABELS,
  ...props
}) => {
  const [now, setNow] = useState(Date.now())
  const [complete, setComplete] = useState(false)
  const text = useMemo(() => {
    const periodSeconds = getPeriodSeconds(value ?? now, now)
    if (periodSeconds <= 0) return null
    const [days, hours, minutes, seconds] = getDaysHoursMinutesSeconds(periodSeconds)
    return [
      days && labels.day && labels.day(days),
      (days || hours) && labels.hour && labels.hour(hours),
      (days || hours || minutes) && labels.minute && labels.minute(minutes),
      labels.second && labels.second(seconds),
    ]
      .filter(Boolean)
      .slice(0, 3)
      .join(' ')
  }, [now, value, labels])

  useEffect(() => {
    const date = parseDate(value)
    if (!date) return
    const interval = setInterval(() => {
      const now = Date.now()

      if (date.getTime() < now) {
        setComplete(true)
        onComplete?.()
        clearInterval(interval)
      } else {
        setNow(now)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [onComplete, value])

  if (!value) return null

  const isWrapperNeeded = Boolean(prepend || append)
  const color = colorful && styles[getState(getPeriodSeconds(value))]

  const time = (
    <time
      {...props}
      className={isWrapperNeeded ? undefined : cn(color, className)}
      dateTime={parseDate(value)?.toISOString()}
    >
      {complete ? completeLabel : text}
    </time>
  )

  return prepend || append ? (
    <span className={cn(styles.wrapper, color, className)}>
      {prepend}
      {time}
      {append}
    </span>
  ) : (
    time
  )
}

export const Timer = Object.assign(_Timer, {
  DHMS: LABELS,
  DHM: { ...LABELS, second: undefined },
})

const SEC_IN_MIN = 60
const SEC_IN_HOUR = 60 * SEC_IN_MIN
const SEC_IN_DAY = 24 * SEC_IN_HOUR

const getPeriodSeconds = (value: Date | string | number | null, now = Date.now()) => {
  const date = parseDate(value)
  if (!date) return 0
  return Math.floor((date.getTime() - now) / 1000)
}

const getState = (periodSeconds: number) => {
  if (periodSeconds < SEC_IN_HOUR) return 'high'
  if (periodSeconds < SEC_IN_DAY) return 'mid'
  return 'low'
}

const getDaysHoursMinutesSeconds = (periodSeconds: number) => {
  return [
    Math.floor(periodSeconds / SEC_IN_DAY),
    Math.floor((periodSeconds % SEC_IN_DAY) / SEC_IN_HOUR),
    Math.floor((periodSeconds % SEC_IN_HOUR) / SEC_IN_MIN),
    periodSeconds % SEC_IN_MIN,
  ]
}
