import { FC } from 'react'

interface Props extends Omit<Intl.NumberFormatOptions, 'style'> {
  className?: string
  locales?: string
  value?: number
  integer?: boolean
  title?: string
  formatStyle?: Intl.NumberFormatOptions['style'] & ('decimal' | 'percent' | 'currency' | 'unit')
}

/**
 * @see [Intl.NumberFormat()](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters)
 */
export const Num: FC<Props> = ({
  value: amount = 0,
  locales = 'en-US',
  integer = false,
  formatStyle = 'decimal',
  compactDisplay,
  notation,
  signDisplay,
  unit,
  unitDisplay,
  currencyDisplay,
  currencySign,
  minimumFractionDigits,
  maximumFractionDigits,
  ...props
}) => {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits,
    maximumFractionDigits,
    style: formatStyle,
    compactDisplay,
    notation,
    signDisplay,
    unit,
    unitDisplay,
    currencyDisplay,
    currencySign,
    ...(integer && {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  }

  return <span {...props}>{new Intl.NumberFormat(locales, options).format(amount)}</span>
}
