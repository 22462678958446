import { useSubscription, ChatEventHandler } from './use-subscription'
import { getUserChatChannel } from '../ably/channel'

export const useUserChatSubscription = (user_id: string, cb: ChatEventHandler<UserChatEvent>) => {
  useSubscription<UserChatEvent>(getUserChatChannel(user_id), (event) => {
    if (event.data?.user_id !== user_id) cb(event)
  })
}

export const enum UserChatEvent {}
