import { MimeType } from 'api/user-file'
import { IconFileJPG, IconFilePDF, IconFilePNG, IconPhoto } from 'icons'
import { ComponentProps, FC } from 'react'

interface Props extends ComponentProps<'svg'> {
  type: `${MimeType}`
}
export const FileIcon: FC<Props> = ({ type, ...props }) => {
  if (!(type in ICONS)) return null

  const Icon = ICONS[type]

  return <Icon {...props} />
}

const ICONS: Record<`${MimeType}`, FC<ComponentProps<'svg'>>> = {
  [MimeType.webp]: IconPhoto,
  [MimeType.jpg]: IconFileJPG,
  [MimeType.jpeg]: IconFileJPG,
  [MimeType.png]: IconFilePNG,
  [MimeType.pdf]: IconFilePDF,
}
