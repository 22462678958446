import { TextInput, TextInputProps } from '../../input-text/text-input'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = Omit<TextInputProps, 'type' | 'autoComplete' | 'autoCapitalize' | 'autoCorrect'> &
  BaseFieldProps<string>
export class Field extends BaseField<string, Props> {
  static displayName = 'EmailField'

  renderInput(props: TextInputProps) {
    return (
      <TextInput
        {...props}
        type="email"
        autoComplete="email"
        autoCapitalize="off"
        autoCorrect="off"
      />
    )
  }
}

export const EmailField = withFormDefaultValues<string, Props>(Field)

EmailField.defaultProps = {
  label: 'Email',
}
