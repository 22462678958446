import { useCallback, useState } from 'react'
import { isSetterFunctionalArg, SetterFunctionalArg, SetterValueArg, SetterArg } from 'utils/setter'

function parse<T>(item?: string | null): T | null {
  try {
    return item ? JSON.parse(item) : null
  } catch (error) {
    return null
  }
}

function stringify(value?: null): null
function stringify<T>(value: T): string
function stringify<T>(value?: T | null): string | null {
  return value === null || value === undefined ? null : JSON.stringify(value)
}

function createStorageItem(storage: Storage) {
  return {
    set(key: string, value: string | null): string | null {
      if (value === null || value === undefined) {
        storage.removeItem(key)
        return null
      } else {
        storage.setItem(key, value)
        return value
      }
    },
    get(key: string): string | null {
      return storage.getItem(key)
    },
  }
}

function createStorageHook(_storage: Storage) {
  const storage = createStorageItem(_storage)
  return function <T>(
    key: string,
    defaultValue: SetterFunctionalArg<T> | SetterValueArg<T> = null,
  ): [value: SetterValueArg<T>, save: (value: SetterArg<T>) => void] {
    const [state, setState] = useState<T | null>(() => {
      const currentValue = parse<T>(storage.get(key))
      if (isSetterFunctionalArg(defaultValue)) {
        return defaultValue(currentValue)
      } else {
        return currentValue ?? defaultValue
      }
    })

    const save = useCallback(
      (value: SetterArg<T>) => {
        if (isSetterFunctionalArg(value)) {
          const currentValue = parse<T>(storage.get(key))
          const nextValue = value(currentValue)
          storage.set(key, stringify(nextValue))
          setState(value)
        } else {
          storage.set(key, stringify(value))
          setState(value)
        }
      },
      [key],
    )

    return [state, save]
  }
}

export const useSessionStorage = createStorageHook(sessionStorage)
export const useLocalStorage = createStorageHook(localStorage)
