import { RefObject, useEffect, useState } from 'react'

export const useVisibilityListener = <T extends HTMLElement>(ref: RefObject<T>): boolean => {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    if (!ref.current) return
    const observer = new IntersectionObserver(([entry]) => setVisible(entry.isIntersecting), {
      threshold: 0,
    })
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [ref])

  return visible
}
