import { IconOpenInNew } from 'icons'
import { FC } from 'react'
import { cn } from 'utils'
import styles from './document-preview.module.scss'
import { IconButton } from '../button'
import { Description } from '../description'
import { Dialog } from '../dialog'
import { Image } from '../image'

interface Props {
  url?: string
  type?: string
  title: string
  className?: string
}

export const DocumentPreview: FC<Props> = ({ url, type, title, className }) => {
  const isPDF = type ? !!type.match(/pdf$/) : false
  const isImage = type ? !!type.match(/^image\/.*/) : false
  const error = !url
    ? 'Not found'
    : !type
    ? 'File type is undefined'
    : !isPDF && !isImage
    ? 'Unsupported file type'
    : undefined

  const open = () => {
    url && window.open(url, '_blank')
  }

  return (
    <Dialog
      unstyled
      className={cn(styles.dialog, isImage && styles.image, isPDF && styles.pdf, className)}
      closeClassName={styles.close}
    >
      <IconButton className={styles.open} onClick={open}>
        <IconOpenInNew />
      </IconButton>

      {error ? (
        <Description error className={styles.error}>
          <ul>
            <li>{error}</li>
          </ul>
        </Description>
      ) : isPDF ? (
        <iframe className={styles.iframe} title={title} src={`${url}#view=fitH&toolbar=0`} />
      ) : isImage ? (
        <Image src={url} className={cn(styles.img)} />
      ) : null}
    </Dialog>
  )
}
