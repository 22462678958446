import { ComponentProps, HTMLProps } from 'react'
import { cn } from 'utils'
import { Link } from './link'
import linkStyles from './link.module.scss'
import { Button } from '../button'
import buttonStyles from '../button/button.module.scss'

enum Theme {
  button = 'button',
  link = 'link',
}

type ButtonTheme = ComponentProps<typeof Button>['theme']
type LinkTheme = ComponentProps<typeof Link>['theme']

interface Props<T extends Theme> extends Omit<HTMLProps<HTMLAnchorElement>, 'href'> {
  className?: string
  themeOf?: `${T}`
  theme?: `${T extends Theme.button ? ButtonTheme : LinkTheme}`
  small?: boolean
  email?: string
}

export function EmailLink<T extends Theme>({
  className,
  small,
  themeOf = Theme.link as `${T}`,
  theme,
  email,
  children,
  ...props
}: Props<T>): JSX.Element {
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      {...props}
      href={`mailto:${email}`}
      className={cn(
        themeOf === Theme.button && cn(buttonStyles.button, theme && buttonStyles[theme]),
        themeOf === Theme.link &&
          cn(linkStyles.link, theme && linkStyles[theme], small && linkStyles.small),
        className,
      )}
    >
      {children ?? email}
    </a>
  )
}
