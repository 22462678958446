import { ReactNode, ComponentProps, HTMLProps } from 'react'
import { cn } from 'utils'
import { Link } from './link'
import linkStyles from './link.module.scss'
import { Button } from '../button'
import buttonStyles from '../button/button.module.scss'

enum Theme {
  button = 'button',
  link = 'link',
}

type ButtonTheme = ComponentProps<typeof Button>['theme']
type LinkTheme = ComponentProps<typeof Link>['theme']

interface Props<T extends Theme> extends HTMLProps<HTMLAnchorElement> {
  children?: ReactNode
  className?: string
  themeOf?: `${T}`
  theme?: `${T extends Theme.button ? ButtonTheme : LinkTheme}`
  small?: boolean
  wide?: T extends Theme.button ? boolean : never
}

export function ExternalLink<T extends Theme>({
  children,
  className: _className,
  small,
  themeOf = Theme.link as `${T}`,
  theme,
  wide,
  disabled,
  ...props
}: Props<T>): JSX.Element {
  const className = cn(
    themeOf === Theme.button && cn(buttonStyles.button, theme && buttonStyles[theme]),
    themeOf === Theme.link &&
      cn(linkStyles.link, theme && linkStyles[theme], small && linkStyles.small),
    wide && buttonStyles.wide,
    _className,
  )

  if (disabled)
    return (
      <span {...props} aria-disabled="true" className={className}>
        {children}
      </span>
    )

  return (
    <a rel="noopener noreferrer" target="_blank" {...props} className={className}>
      {children}
    </a>
  )
}
