import { ChangeEvent, FocusEventHandler, InputHTMLAttributes } from 'react'
import { BaseInput, BaseInputProps } from '../input-text/base-input'

type HTMLInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value' | 'defaultValue' | 'name' | 'onFocus' | 'onBlur' | 'defaultChecked'
>

export interface CheckboxInputProps extends BaseInputProps<boolean>, HTMLInputProps {
  name: string
  defaultValue?: boolean | null
  validationMessage?: string | null
}

export class CheckboxInput extends BaseInput<boolean, CheckboxInputProps> {
  static defaultProps = {
    defaultValue: false,
  }

  element?: HTMLInputElement

  setElement = (element: HTMLInputElement) => {
    this.element = element
    if (!element) return
    // let the parent form knows about this control
    this.broadcastUpdates({ value: this.props.defaultValue ?? false })
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    // let the parent form knows about changes
    this.broadcastUpdates({ value: checked, touched: true })
  }

  handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    this.validate()
    this.props.onBlur && this.props.onBlur(event)
  }

  getValidationMessage() {
    if (this.props.required && !this.element?.checked) {
      return 'Required'
    }
    return this.checkCustomValidity()
  }

  isValid() {
    if (!this.element) return false
    if (this.props.required && !this.element.checked) return false
    if (this.checkCustomValidity()) return false
    return true
  }

  getValue() {
    if (!this.element) return undefined
    return this.element.checked || false
  }

  setValue(value = false) {
    if (!this.element) return
    this.element.checked = value
    this.broadcastUpdates()
  }

  focus() {
    this.element?.focus()
  }

  scrollIntoView(arg: boolean | ScrollIntoViewOptions | undefined) {
    this.element?.scrollIntoView(arg)
  }

  render() {
    const { disabled, readOnly, validation, validationMessage, defaultValue, ...props } = this.props
    return (
      <input
        // data-lpignore="true"
        {...props}
        ref={this.setElement}
        id={props.id || props.name}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        disabled={disabled || readOnly}
        type="checkbox"
        defaultChecked={defaultValue ?? false}
      />
    )
  }
}
