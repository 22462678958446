import { ReactNode } from 'react'

export type Option<T> = {
  label: ReactNode
  value: T | null
  disabled?: boolean
}

export type Options<T extends number | string | boolean | null = number | string | boolean> =
  | Record<string, T>
  | Option<T>[]

export type ConverterOption<T> = [ReactNode | string, T | null, boolean]

export function optionsConverter<T extends number | string | boolean | null>(
  options: Options<T>,
  disabledOptions?: T[],
): ConverterOption<T>[] {
  return Array.isArray(options)
    ? options.map(({ value, label, disabled }) => [
        label,
        value,
        ((value && disabledOptions?.includes(value)) || disabled) ?? false,
      ])
    : Object.entries(options).map(([label, value]) => [
        label,
        value,
        disabledOptions?.includes(value) ?? false,
      ])
}
