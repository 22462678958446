import { ComponentProps, FC, FormEvent, useCallback, useReducer } from 'react'
import { cn } from 'utils'
import { alertErrorMessage } from 'utils/toast'
import styles from './form.module.scss'
import { UploadDispatchProvider, UploadStateProvider } from './upload-context'
import { Action, initialState, uploadReducer } from './upload-reducer'
import { LoaderWrapper } from '../loader'

interface Props extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  onSubmit: (data: FormData, index: number, total: number) => Promise<unknown>
  onFilesChange?: (files: File[]) => void
  onCompleted?: () => void
  sizeLimit?: number
}

const MEGA = 1024 * 1024
const SIZE_LIMIT = 5 * MEGA // 5 MB

export const UploadForm: FC<Props> = ({
  children,
  onSubmit,
  onCompleted,
  className,
  onFilesChange,
  sizeLimit = SIZE_LIMIT,
  ...props
}) => {
  const [state, dispatch] = useReducer<typeof uploadReducer>((state, action) => {
    const nextState = uploadReducer(state, action)
    if (action.type === Action.AddFile || action.type === Action.RemoveFile)
      onFilesChange?.(nextState.files ?? [])
    return nextState
  }, initialState)

  const submit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      try {
        if (!state.files) throw new Error('Select file(s) to upload')
        let index = -1
        for await (const file of state.files) {
          if (file.size > sizeLimit) {
            throw new Error(
              `File ${file.name} is too big. Max size is ${Math.floor(sizeLimit / MEGA)} MB`,
            )
          }

          dispatch({ type: Action.Submit })
          const data = new FormData()
          data.append('file', file, file.name)
          const result = await onSubmit(data, ++index, state.files.length)
          dispatch({ type: Action.RemoveFile, payload: file })
          dispatch({ type: Action.Succeed, payload: result })
        }
        onCompleted?.()
      } catch (error) {
        alertErrorMessage((error as Error).message)
        dispatch({ type: Action.Error, payload: error as Error })
      }
    },
    [state.files, onCompleted, sizeLimit, onSubmit],
  )

  return (
    <LoaderWrapper
      {...props}
      className={cn(styles.form, className)}
      tagName="form"
      loading={state.submitting}
      onSubmit={submit}
    >
      <UploadStateProvider value={state}>
        <UploadDispatchProvider value={dispatch}>{children}</UploadDispatchProvider>
      </UploadStateProvider>
    </LoaderWrapper>
  )
}
