import { useChannel } from '@ably-labs/react-hooks'
import { Types } from 'ably'
import { useEffect, useState } from 'react'
import { Log } from 'utils/log'
import { IS_ABLY_ENABLED } from '../ably/channel'

type Message<T = string, D = any> = Omit<Types.Message, 'name'> & { name: T; data: D }
export type ChatEventHandler<T = string, D = any> = (message: Message<T, D>) => void

export const useSubscription = IS_ABLY_ENABLED
  ? <T = string, D = any>(channelName: string, cb?: ChatEventHandler<T, D>) => {
      const [messages, updateMessages] = useState<Message<T, D>[]>([])
      const [channel] = useChannel(channelName, (message) => {
        logMessage(channel, message)
        updateMessages((messages) => {
          const result = [...messages, message as Message<T>]
          return result
        })
        cb?.(message as Message<T, D>)
      })
      useEffect(() => {
        logConnect(channel)
        const cb: Types.paginatedResultCallback<Message> = (
          err: any,
          result?: Types.PaginatedResult<Types.Message>,
        ) => {
          if (err || !result) return
          if (Array.isArray(result.items)) {
            updateMessages((messages) => [...messages, ...(result.items as Message<T, D>[])])
          }
          if (result.hasNext()) {
            result.next(cb)
          }
        }
        channel.history({}, cb)
      }, [channel])
      return messages
    }
  : <T = string, D = any>(channelName: string, cb?: ChatEventHandler<T, D>) => {
      return [] as Message<T, D>[]
    }

const CSS_CONNECT = `
    color: #00a4ff;
    font-weight:normal;
`

function logConnect(channel: Types.RealtimeChannelCallbacks) {
  if (import.meta.env.MODE !== 'development') return
  try {
    new Log()
      .text(`& CONNECT`, CSS_CONNECT)
      .groupCollapsed()
      .heading('channel name')
      .stringValue(channel.name)
      .objectValue('channel', channel)
      .log()
      .groupEnd()
  } catch (e) {
    //
  }
}

function logMessage(channel: Types.RealtimeChannelCallbacks, message: Message) {
  if (import.meta.env.MODE !== 'development') return
  try {
    new Log()
      .text(`& MESSAGE`, CSS_CONNECT)
      .groupCollapsed()
      .heading('channel name')
      .stringValue(channel.name)
      .heading('message name')
      .stringValue(message.name)
      .objectValue('data', message.data)
      .log()
      .groupEnd()
  } catch (e) {
    //
  }
}
