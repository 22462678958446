import { createContext, useContext, FC, ReactNode } from 'react'
import { Auth } from '../firebase'
import { FirebaseUserProvider } from '../use-firebase-user'

const Context = createContext<Auth<any> | null>(null)
const Provider = Context.Provider

interface Props {
  children?: ReactNode
  auth: Auth<any>
}
export const AuthProvider: FC<Props> = ({ children, auth }) => {
  return (
    <Provider value={auth}>
      <FirebaseUserProvider auth={auth}>{children}</FirebaseUserProvider>
    </Provider>
  )
}
export function useAuth<T>(): Auth<T> {
  return useContext(Context)! as Auth<T>
}
