import { IconUpload } from 'icons'
import { ComponentProps, FC, FormEvent, ReactNode, useCallback } from 'react'
import { cn } from 'utils'
import styles from './dropzone.module.scss'
import { filterFileList } from './filter-filelist'
import { useUploadDispatch, useUploadState } from './upload-context'
import { Action } from './upload-reducer'

interface Props
  extends Omit<ComponentProps<'input'>, 'type' | 'checked' | 'defaultChecked' | 'onChange'> {
  className?: string
  children?: ReactNode
  label?: string
}
export const Dropzone: FC<Props> = ({
  children,
  multiple,
  label = 'files',
  className,
  accept,
  ...props
}) => {
  const dispatch = useUploadDispatch()
  const { files } = useUploadState()
  const isAddingFileRestricted = !multiple && files && files.length === 1

  const handleChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      filterFileList(event.currentTarget.files, accept).forEach((file) => {
        dispatch({ type: Action.AddFile, payload: file })
      })
    },
    [dispatch, accept],
  )

  return (
    <div className={cn(styles.dropzone, className)}>
      {isAddingFileRestricted ? null : (
        <>
          <input
            {...props}
            type="file"
            multiple={multiple}
            className={styles.input}
            onChange={handleChange}
            accept={accept}
          />
          <p className={styles.content}>
            <IconUpload className={styles.icon} aria-hidden="true" />
            <span>
              {`Drag & drop ${label} here`}
              <br /> or <span className={styles.upload}>{`upload ${label}`}</span>
            </span>
          </p>
        </>
      )}
    </div>
  )
}
