/**
 * Documentation:
 * @see https://github.com/logdna/logdna-browser#example-usage
 * Account:
 * @see https://app.mezmo.com/
 *
 * key can be found in Settings > Organization > API Keys
 */

import logdna from '@logdna/browser'
import config from './package.json'

let ready = false
export const initLogger = (
  onChange: (callback: (user: { email: string } | null) => any) => void,
  key?: string,
) => {
  if (key) {
    logdna.init(key, {
      console: {
        enable: true,
        integrations: ['warn', 'error'],
      },
    })
    const app = import.meta.env.VITE_LOGGER_APP
    logdna.addContext({
      version: config.version,
      env: import.meta.env.NODE_ENV === 'development' ? 'dev' : 'prod',
      ...(app && { app }),
    })
    onChange((user) => {
      user && logdna.addContext({ user: user.email })
    })
    ready = true
  } else {
    // eslint-disable-next-line no-console
    console.warn('No LogDNA Key found')
  }
}

export const logError = (message: any, lineContext?: object | undefined) => {
  // eslint-disable-next-line no-console
  console.error(message, lineContext)
  ready && logdna.error(message, lineContext)
}
