import { IconMarkerHouse } from 'icons'
import mapboxgl from 'mapbox-gl'
import { FC, MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { cn } from 'utils'
import { useMapContext } from './context'
import styles from './map.module.scss'
import { Badge } from '../badge'

interface Props extends mapboxgl.MarkerOptions {
  latitude: number
  longitude: number
  className?: string
  popup?: ReactNode
  popupOptions?: mapboxgl.PopupOptions
  onMove?: (position: { latitude: number; longitude: number }) => void
  amount?: number
  onSelect?: (visible: boolean) => void
}

export const MapMarker: FC<Props> = ({
  latitude,
  longitude,
  className,
  popup,
  popupOptions,
  onMove,
  amount,
  onSelect,
}) => {
  const map = useMapContext()
  const isAddedToMapRef = useRef(false)
  const [element] = useState(() => document.createElement('div'))
  const [marker] = useState<mapboxgl.Marker>(
    () => new mapboxgl.Marker({ element, anchor: 'bottom' }),
  )

  const [popupElement] = useState(() => document.createElement('div'))

  // handle position
  useEffect(() => {
    if (!map || !latitude || !longitude || !marker) return
    marker.setLngLat([longitude, latitude])
    if (!isAddedToMapRef.current) {
      marker.addTo(map)
      isAddedToMapRef.current = true
    }
    return () => {
      // marker?.remove()
    }
  }, [latitude, longitude, map, marker])

  // handle draggable
  useEffect(() => {
    const draggable = !!onMove
    if (marker.isDraggable() !== draggable) marker.setDraggable(draggable)
    if (!onMove) return
    const onDragEnd = () => {
      const { lng, lat } = marker.getLngLat()
      onMove({ latitude: lat, longitude: lng })
    }
    marker.on('dragend', onDragEnd)
    return () => {
      marker.off('dragend', onDragEnd)
    }
  }, [marker, onMove])

  useEffect(() => {
    if (!marker || !popup) return
    const mapPopup = new mapboxgl.Popup({
      maxWidth: 'auto',
      ...popupOptions,
    }).setDOMContent(popupElement)

    marker.setPopup(mapPopup)
    return () => {
      mapPopup.remove()
    }
  }, [marker, popup, popupElement, popupOptions])

  const focus = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (!onSelect) return
      event.currentTarget.focus()
      onSelect(true)
    },
    [onSelect],
  )

  return (
    <>
      {createPortal(
        <div
          className={cn(styles.marker, className)}
          onClick={focus}
          onBlur={() => onSelect?.(false)}
          tabIndex={0}
        >
          {amount && amount > 1 ? <Badge amount={amount} className={styles.badge} /> : null}
          <IconMarkerHouse className={styles.icon} tabIndex={0} />
        </div>,
        element,
      )}
      {popup && createPortal(<>{popup}</>, popupElement)}
    </>
  )
}
