export type DateValue = number | string | Date
type OptionalDateValue = number | string | Date | null | undefined

export function parseDate(value?: OptionalDateValue): Date | null {
  if (!value) return null
  const date = new Date(value)
  return isNaN(date.valueOf()) ? null : date
}

export const isDatePast = (value: DateValue) => {
  const date = parseDate(value)
  return date ? date <= new Date() : null
}
export const isDateFuture = (value: DateValue) => {
  const date = parseDate(value)
  return date ? date >= new Date() : null
}

export function fromServerDate(value: DateValue) {
  return value
}

export function formatYYMMDD(date: Date) {
  const yy = date.getFullYear()
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const dd = String(date.getDate()).padStart(2, '0')
  return `${yy}-${mm}-${dd}`
}

export function formatISO(date: Date) {
  return (
    [
      String(date.getUTCFullYear()).padStart(4, '0'),
      String(date.getUTCMonth() + 1).padStart(2, '0'),
      String(date.getUTCDate()).padStart(2, '0'),
    ].join('-') + `T00:00:00Z`
  )
}

export function toServerDate(value: DateValue): string | undefined {
  const date = parseDate(value)
  if (!date) return
  return `${formatYYMMDD(date)}T00:00:00Z`
}

export function isoToServerDate(value: string): string {
  return value && /^\d{4}-\d{2}-\d{2}$/.test(value) ? `${value}T00:00:00Z` : value
}

export function isoToServerDateEOD(value: string): string {
  return value && /^\d{4}-\d{2}-\d{2}$/.test(value) ? `${value}T23:59:59Z` : value
}

export function serverToISODate(value: string): string {
  return value.split('T')[0]
}

export const addDays = (date: DateValue, days: number) => {
  const newDate = parseDate(date)
  if (!newDate) throw new Error('Invalid date')
  newDate.setDate(newDate.getDate() + days)
  return newDate
}
export const addMonths = (date: DateValue, months: number) => {
  const newDate = parseDate(date)
  if (!newDate) throw new Error('Invalid date')
  newDate.setMonth(newDate.getMonth() + months)
  return newDate
}
export const startOfDay = (date: DateValue = new Date()) => {
  const newDate = parseDate(date)
  if (!newDate) throw new Error('Invalid date')
  newDate.setHours(0, 0, 0, 0)
  return newDate
}
export const endOfDay = (date: DateValue = new Date()) => {
  const newDate = parseDate(date)
  if (!newDate) throw new Error('Invalid date')
  newDate.setHours(23, 59, 59, 999)
  return newDate
}
export const startOfMonth = (date: DateValue = new Date()) => {
  const newDate = parseDate(date)
  if (!newDate) throw new Error('Invalid date')
  newDate.setDate(1)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}
export const addYears = (date: DateValue, years: number) => {
  const newDate = parseDate(date)
  if (!newDate) throw new Error('Invalid date')
  newDate.setFullYear(newDate.getFullYear() + years)
  return newDate
}
export const subtractDays = (date: DateValue, days: number) => addDays(date, -days)

export function toISODate(value: OptionalDateValue): string | null {
  if (!value) return null
  const date = parseDate(value)
  return date ? date.toISOString().split('T')[0] : null
}

export function toISODatetime(value: OptionalDateValue): string | null {
  if (!value) return null
  const date = parseDate(value)
  return date ? date.toISOString() : null
}

const DATE_LENGTH = '2023-02-14'.length
const DATETIME_LENGTH = '2023-02-14T15:47:01'.length
export function toInputDatetime(value: OptionalDateValue): string {
  if (!value) return ''
  const date = parseDate(value)
  if (!date) return ''
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
    .toISOString()
    .slice(0, DATETIME_LENGTH)
}
export function fromInputDatetime(value: OptionalDateValue): string {
  if (!value) return ''
  const date = parseDate(value)
  if (!date) return ''
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000).toISOString()
}

export function isYMD(value: any) {
  return typeof value === 'string' && /\d{4}-\d{2}-\d{2}$/.test(value)
}
export function clearTime(date: string) {
  return date.slice(0, DATE_LENGTH)
}

export const formatDateTime = (
  date: Date,
  options?: Intl.DateTimeFormatOptions,
  locales: string | string[] = 'en-US',
): string => new Intl.DateTimeFormat(locales, options).format(date)

export const formatUTCDateTimeAsLocal = (
  date: Date,
  options?: Intl.DateTimeFormatOptions,
  locales: string | string[] = 'en-US',
): string => {
  return formatDateTime(
    new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000),
    options,
    locales,
  )
}

export const getTomorrowUTC = () => {
  return new Date(formatYYMMDD(addDays(new Date(), 1)) + `T00:00:00Z`)
}

export const startOfDayUTC = (date: DateValue = new Date()) => {
  const newDate = parseDate(date)
  if (!newDate) throw new Error('Invalid date')
  return clearTime(newDate.toISOString()) + `T00:00:00Z`
}
export const endOfDayUTC = (date: DateValue = new Date()) => {
  const newDate = parseDate(date)
  if (!newDate) throw new Error('Invalid date')
  return clearTime(newDate.toISOString()) + 'T23:59:59Z'
}

export const isTodayLocal = (date: DateValue) => {
  const time = parseDate(date)?.getTime()
  if (!time) return false
  return time > startOfDay().getTime() && time < endOfDay().getTime()
}

export const isSameDayLocal = (date: DateValue, date2: DateValue = new Date()) => {
  const isoDate = toISODate(date)
  const isoDate2 = toISODate(date2)
  return !!isoDate && !!isoDate2 && isoDate === isoDate2
}

export const isSameYearLocal = (date: DateValue, date2: DateValue = new Date()) => {
  const year = parseDate(date)?.getFullYear()
  return !!year && year === parseDate(date2)?.getFullYear()
}

export const getSecondsFromNow = (expiration: string) => {
  const date = parseDate(expiration)
  if (!date) return undefined
  return Math.floor((date.getTime() - Date.now()) / 1000)
}
