import { ComponentProps, FC, useEffect, useRef } from 'react'

interface Props extends Omit<ComponentProps<'button'>, 'onClick'> {
  onClick?: (e: { currentTarget: HTMLButtonElement }) => void
  threshold?: number | number[]
}
export const AutoButton: FC<Props> = ({
  children,
  disabled,
  onClick,
  threshold = 0.01,
  ...props
}) => {
  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (disabled) return

    const element = ref.current
    if (!element) return

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries.find(
          (entry) => entry.isIntersecting && !(entry.target as HTMLButtonElement).disabled,
        )
        entry &&
          entry.target instanceof HTMLButtonElement &&
          onClick?.({ currentTarget: entry.target })
      },
      { threshold },
    )
    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [onClick, disabled, threshold])

  return (
    <button disabled={disabled} onClick={onClick} {...props} ref={ref} type="button">
      {children}
    </button>
  )
}
