import { ComponentProps, FC } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { cn } from 'utils'
import styles from './tooltip.module.scss'
import 'react-tooltip/dist/react-tooltip.css'

type Props = ComponentProps<typeof ReactTooltip>

type Type = FC<Props> & {
  ID: string
}
/**
 * `--tooltip-background-color` \
 * `--tooltip-color` \
 * `--tooltip-opacity` \
 * `--tooltip-padding` \
 * `--tooltip-border-radius` \
 * `--tooltip-box-shadow`
 */
export const Tooltip: Type = ({ className, ...props }) => {
  return <ReactTooltip {...props} className={cn(styles.tooltip, className)} />
}

Tooltip.ID = 'tooltip'
