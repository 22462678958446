import { FC, HTMLProps } from 'react'
import { DateValue } from 'utils/date'
import { DateOptions } from 'utils/intl'
import { DayUTC } from './day-utc'

type ElementProps = Omit<HTMLProps<HTMLTimeElement>, 'value' | 'start'>

type Props = DateOptions &
  ElementProps & {
    className?: string
    locales?: string | string[]
    start: DateValue
    end: DateValue
  }

export const PeriodUTC: FC<Props> = ({
  className,
  locales,
  start,
  end,
  dateStyle = 'medium',
  ...props
}) => {
  return (
    <span className={className}>
      <DayUTC {...props} value={start} dateStyle={dateStyle} />
      &nbsp;&ndash; <DayUTC {...props} value={end} dateStyle={dateStyle} />
    </span>
  )
}
