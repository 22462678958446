import { FC, ComponentProps } from 'react'
import { useLocation } from 'react-router-dom'
import { cn } from 'utils'
import styles from './dropdown-menu.module.scss'
import { Badge } from '../badge'
import { useDropdownContext } from '../dropdown/context'
import { DropdownMenuLink } from '../dropdown/link'
import { LinkButton, resolveLinkParams } from '../link'

type Props = ComponentProps<typeof DropdownMenuLink> & {
  theme?: 'warn'
}
export const DropdownLink: FC<Props> = ({
  className,
  theme,
  severnity,
  amount = 0,
  state: _state,
  to: _to,
  preserveSearch,
  title,
  icon,
  ...props
}) => {
  const from = useLocation()
  const dropdownContext = useDropdownContext()
  const { state, to } = resolveLinkParams({
    to: _to,
    state: _state,
    from,
    preserveSearch,
  })

  return (
    <LinkButton
      {...props}
      className={cn(styles.link, theme && styles[theme], className)}
      onClick={dropdownContext?.close}
      tabIndex={-1}
      data-dropdown="link"
      state={state}
      to={to}
    >
      {icon}
      <span className={styles.title}>{title}</span>
      {amount > 0 && <Badge amount={amount} severnity={severnity} className={styles.badge} />}
    </LinkButton>
  )
}
