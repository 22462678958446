import { ComponentProps } from 'react'
import { HiddenInput } from '../../input-hidden'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type HiddenInputProps = ComponentProps<typeof HiddenInput>
type Props = HiddenInputProps & BaseFieldProps<HiddenInput.Value>
export class Field extends BaseField<string | number, Props> {
  static displayName = 'HiddenField'

  renderInput(props: HiddenInputProps) {
    return <HiddenInput {...props} />
  }

  render() {
    return this.renderInput(this.getInputProps())
  }
}

export const HiddenField = withFormDefaultValues<string | number | boolean, Props>(Field)
