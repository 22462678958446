import { ComponentProps } from 'react'
import { TextArea } from '../../input-text/text-area'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = ComponentProps<typeof TextArea> & BaseFieldProps<string>
export class Field extends BaseField<string, Props> {
  static displayName = 'TextAreaField'

  renderInput(props: ReturnType<typeof this.getInputProps>) {
    return <TextArea {...props} />
  }

  setValue(value: string | null) {
    if (this.element?.current) {
      this.element.current.value = value
    }
  }
}

export const TextAreaField = withFormDefaultValues<string, Props>(Field)
