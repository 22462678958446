import { FC, HTMLProps } from 'react'
import { clearTime, formatUTCDateTimeAsLocal, parseDate } from 'utils/date'
import { DateOptions, pickDateTimeFormatOptions } from 'utils/intl'

type ElementProps = Omit<HTMLProps<HTMLTimeElement>, 'value'>

type Props = DateOptions &
  ElementProps & {
    className?: string
    locales?: string | string[]
    value?: number | string | Date | null
  }

export const DayUTC: FC<Props> = ({ className, locales, value, ...props }) => {
  const date = parseDate(value)
  if (!date) return null

  const [optionsProps, elementProps] = pickDateTimeFormatOptions(props)
  const hasOptions = Object.keys(optionsProps).length > 0
  const options = hasOptions ? optionsProps : DEFAULT_OPTIONS

  return (
    <time dateTime={clearTime(date.toISOString())} {...elementProps} className={className}>
      {formatUTCDateTimeAsLocal(date, options, locales)}
    </time>
  )
}

const DEFAULT_OPTIONS: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
}
