import { ComponentProps, FC, KeyboardEventHandler, MouseEventHandler, useCallback } from 'react'
import { cn } from 'utils'
import { useDropdownContext } from './context'
import styles from './toggle.module.scss'
import { Button } from '../button'

interface Props extends Omit<ComponentProps<typeof Button>, 'onClick' | 'onKeyDown'> {}

export const DropdownToggle: FC<Props> = ({ children, className, ...props }) => {
  const { toggle, opened, id, open, close } = useDropdownContext() ?? {}

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      toggle?.(event.currentTarget)
    },
    [toggle],
  )

  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    switch (event.key) {
      case 'Esc':
      case 'ArrowUp':
        return close?.()
      case 'ArrowDown':
        return open?.(event.currentTarget)
    }
  }

  return (
    <Button
      {...props}
      className={cn(styles.toggle, className)}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      data-dropdown="toggle"
      aria-expanded={opened ? 'true' : 'false'}
      aria-controls={`${id}-menu`}
    >
      {children}
    </Button>
  )
}
