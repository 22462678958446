import { useSubscription, ChatEventHandler } from './use-subscription'
import { getLeaseChannel } from '../ably/channel'

export const useLeaseSubscription = (lease_id: string, cb?: ChatEventHandler) => {
  return useSubscription<LeaseEvent>(getLeaseChannel(lease_id), cb)
}

export const enum LeaseEvent {
  SIGN = 'checklist-signature-completed',
  LEASE_PAYMENT_SUCCESSFUL = 'lease-payment-successful',
  LEASE_PAYMENT_FAILED = 'lease-payment-failed',
  DEPOSIT_PAYMENT_SUCCESSFUL = 'deposit-payment-successful',
  DEPOSIT_PAYMENT_FAILED = 'deposit-payment-failed',
}
