export type SetterFunctionalArg<T> = (storedValue: T | null) => T | null
export type SetterValueArg<T> = T | null
export type SetterArg<T> = SetterValueArg<T> | SetterFunctionalArg<T>
export type Setter<T, R = void> = (arg: SetterArg<T>) => R

export function isSetterFunctionalArg<T>(value: SetterArg<T>): value is SetterFunctionalArg<T> {
  return typeof value === 'function'
}

export function solveSetterArg<T>(
  value: SetterArg<T>,
  currentValue: SetterValueArg<T>,
): SetterValueArg<T> {
  return isSetterFunctionalArg(value) ? value(currentValue) : value
}
