import { KeyboardEvent } from 'react'
import { cn } from 'utils'
import styles from '../../field.module.scss'
import { NumberInput, NumberInputProps } from '../../input-number/input-number'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = Omit<NumberInputProps, 'type' | 'name'> & BaseFieldProps<number>

export class Field extends BaseField<number, Props> {
  static displayName = 'MoneyField'

  handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        this.inc()
        break
      case 'ArrowDown':
        e.preventDefault()
        this.dec()
        break
    }
  }

  hasMin() {
    return !isNaN(Number(this.props.min))
  }
  getMin() {
    return this.hasMin() ? Number(this.props.min) : 0
  }
  hasMax() {
    return !isNaN(Number(this.props.max))
  }
  getMax() {
    return this.hasMax() ? Number(this.props.max) : null
  }

  inc(d = 1) {
    const _value = this.getValue()
    const value = _value ?? this.getMin() ?? 0
    this.setValue(this.hasMax() ? Math.min(value + d, this.getMax() as number) : value + d)
  }

  dec(d = 1) {
    const _value = this.getValue()
    const value = _value ?? this.getMax() ?? 0
    this.setValue(Math.max(value - d, this.getMin()))
  }

  get inputClassName() {
    return cn(super.inputClassName, styles.number, styles.money)
  }

  renderInput({ defaultValue, ...props }: NumberInputProps) {
    return (
      <NumberInput
        min={0}
        step={0.01}
        placeholder="0"
        {...props}
        defaultValue={toMoney(defaultValue)}
        onKeyDown={this.handleKeyDown}
      />
    )
  }
  setValue(value: number | null) {
    if (!this.element.current) return
    this.element.current.value = toMoney(value)
  }
}

export const MoneyField = withFormDefaultValues<number, Props>(Field)

function toMoney(value?: number | null) {
  if (value === null) return null
  if (value === undefined) return undefined
  return +(Math.ceil(value * 100) / 100).toFixed(2)
}
