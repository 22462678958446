import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './loader.module.css'
import LoaderImage from './Spin.svg?react'

interface ILoaderProps extends ComponentProps<'svg'> {
  className?: string
  unstyled?: boolean
}

export const Loader: FC<ILoaderProps> = ({ className, unstyled, ...props }) => {
  return <LoaderImage {...props} className={cn(!unstyled && styles.loader, className)} />
}
