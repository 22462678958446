import { useState, ComponentPropsWithoutRef, ElementType } from 'react'
import { cn } from 'utils'
import styles from './loader-wrapper.module.scss'
import { Loader } from '../BaseLoder'
import { LoadingContextProvider } from '../loading-context'

type Tag = ElementType

type Props<T extends Tag> = {
  tagName?: T
  loading?: boolean
  spinnerClassName?: string
} & ComponentPropsWithoutRef<T>

export function LoaderWrapper<T extends Tag>({
  children,
  className,
  tagName,
  loading = false,
  spinnerClassName,
  ...props
}: Props<T>) {
  const [innerLoading, setLoading] = useState(false)
  const [active, setActive] = useState(true)
  const isLoading = active ? loading || innerLoading : false
  const Component = tagName ?? 'div'

  return (
    <Component
      className={cn(styles.container, isLoading && styles.loading, className)}
      {...props}
      aria-busy={isLoading ? 'true' : 'false'}
      aria-live="polite"
    >
      <LoadingContextProvider loadingSetter={setLoading} activeSetter={setActive}>
        {children}
      </LoadingContextProvider>
      {isLoading && <Loader className={cn(styles.loader, spinnerClassName)} />}
    </Component>
  )
}
