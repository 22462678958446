import { AccountIcon } from 'icons'
import { FC, DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react'
import { cn } from 'utils'
import styles from './avatar.module.css'

interface Props
  extends Omit<
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    'src' | 'className' | 'alt'
  > {
  src?: string | null
  className?: string
  initials?: string
}
/**
 * `--size`
 */
export const Avatar: FC<Props> = ({ className, src, initials, ...props }) => {
  const [error, setError] = useState(false)
  return (
    <picture className={cn(styles.avatar, className)}>
      {!error && src ? (
        <img
          {...props}
          src={src}
          alt="avatar"
          onError={() => setError(true)}
          referrerPolicy="no-referrer"
        />
      ) : initials ? (
        <span>{initials}</span>
      ) : (
        <AccountIcon />
      )}
    </picture>
  )
}
