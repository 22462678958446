import { FocusEventHandler } from 'react'
import { BaseTextInput, TextInputProps as BaseTextInputProps } from './base-text-input'

export type TextInputProps = BaseTextInputProps

export class TextInput extends BaseTextInput<HTMLInputElement> {
  static defaultProps = {
    type: 'text',
    validationMessages: {},
  }

  onBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    // Trim value on blur, so the native "required" validation works correctly
    // password inputs are excluded because they should allow leading/trailing spaces
    if (this.props.type !== 'password') {
      const value = event.target.value
      const trimmedValue = value.trim()
      if (value !== trimmedValue) {
        event.target.value = trimmedValue
        this.setValue(trimmedValue)
      }
    }
    this.handleBlur?.(event)
  }

  render() {
    const { validation, validationMessages, validationMessage, defaultValue, ...props } = this.props
    return (
      <input
        {...props}
        defaultValue={this.format(defaultValue)}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.onBlur}
        onChange={this.handleChange}
      />
    )
  }
}
