import { BackendError } from 'client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type InstancePropertyType<TObj, TProp extends keyof TObj> = TObj[TProp]
type AbortSignal = InstancePropertyType<AbortController, 'signal'>

type ArgFunction<T> = (arg?: { signal: AbortSignal }) => Promise<T>
type LoadingResult<T> = [null, true, Dispatch<SetStateAction<T | null>>]
type ResolvedResult<T> = [T, false, Dispatch<SetStateAction<T | null>>]
type Result<T> = LoadingResult<T> | ResolvedResult<T>

export function useAsyncEffect<T>(fn: ArgFunction<T>, deps: any[] = []): Result<T> {
  const [loading, setLoading] = useState<boolean>(true)
  const [result, setResult] = useState<T | null>(null)

  useEffect(() => {
    setLoading(true)
    const controller = new AbortController()
    fn({ signal: controller.signal })
      .then((result) => {
        setResult(result)
        setLoading(false)
      })
      .catch((error) => {
        if (!(error as BackendError).isCancelled) {
          setLoading(false)
        }
      })
    return () => controller.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return [result, loading, setResult] as Result<T>
}
