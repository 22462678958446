import { HeadsUpError } from './heads-up-error'
import { HeadsUpErrors } from './heads-up-errors'
import { HeadsUpWarn } from './heads-up-warn'
import { HeadsUpWarns } from './heads-up-warns'

export const HeadsUp = {
  Error: HeadsUpError,
  Errors: HeadsUpErrors,
  Warn: HeadsUpWarn,
  Warns: HeadsUpWarns,
}
