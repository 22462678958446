import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './dropdown-menu.module.scss'
import { DropdownMenuButton } from '../dropdown/button'

type Props = ComponentProps<typeof DropdownMenuButton> & {
  theme?: 'warn'
}
export const DropdownButton: FC<Props> = ({ className, theme, ...props }) => {
  return (
    <DropdownMenuButton {...props} className={cn(styles.link, theme && styles[theme], className)} />
  )
}
