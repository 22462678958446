import { IconClose, IconSearch } from 'icons'
import { CSSProperties, ChangeEvent, KeyboardEventHandler } from 'react'
import { cn } from 'utils'
import styles from './lookup.module.scss'
import { AutoButton } from '../../../auto-button'
import { IconButton } from '../../../button'
import { Dropdown } from '../../../dropdown'
import { List } from '../../../list'
import listStyles from '../../../list/list.module.scss'
import { FieldDropdownMenu } from '../../field-dropdown/field-dropdown-menu'
import { Label } from '../../label/label'
import { Option } from '../../utils/options'
import { BaseFieldProps, withFormDefaultValues } from '../_base'
import { StatefulField } from '../_stateful'

type Props<Item> = BaseFieldProps<string> & {
  generator: (config: {
    signal: AbortSignal
    value: string | null
  }) => AsyncGenerator<{ items: Item[]; count: number }, void, unknown>
  convertItem: (item: Item) => Option<string>
  defaultValue?: string | null
  options?: Item[]
  onRecordChange?: (item: Item | null) => void
  exclude?: (item: Item) => boolean
  placeholder?: string
  fetchRecord?: (value: string) => Promise<Item | null>
}
type State<Item> = {
  value: string
  options: Item[]
  item: Item | null
  index: number
  searching: boolean
  done: boolean
  count: number
}

export class Field<Item = any> extends StatefulField<string, Props<Item>, State<Item>> {
  static displayName = 'InfiniteLookupField'

  state = {
    value: this.props.defaultValue ?? null,
    options: [],
    item: this.findItemByValue(this.props.defaultValue, this.props.options),
    index: -1,
    count: 0,
    searching: false,
    done: true,
  } as State<Item>

  abortController: AbortController | null = null

  generator: AsyncGenerator<{ items: Item[]; count: number }> | null = null

  onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    return this.search(event.target.value)
  }

  select = (item: Item) => {
    const { value } = this.props.convertItem(item)
    if (value) {
      this.setValue(value)
      this.setState({ item })
      this.props.onRecordChange?.(item)
    }
  }
  clear = () => {
    this.setValue(null)
    this.setState({ item: null, options: [], index: -1, count: 0, done: false })
    this.props.onRecordChange?.(null)
  }

  handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        event.stopPropagation()
        event.preventDefault()
        this.setState((state) => {
          if (state.searching || !state.options) return null
          return { index: state.index + 1 < state.options.length ? state.index + 1 : 0 }
        })
        break

      case 'ArrowUp':
        event.stopPropagation()
        event.preventDefault()
        this.setState((state) => {
          if (state.searching || !state.options) return null
          return { index: state.index - 1 < 0 ? state.options.length - 1 : state.index - 1 }
        })
        break
    }
  }

  private findItemByValue(value: string | null | undefined, options: Item[] = []) {
    const { convertItem } = this.props
    if (!value || !options?.length) return null
    return options.find((option) => convertItem(option).value === value) ?? null
  }

  async search(value: string | null) {
    this.abortController?.abort()
    this.abortController = new AbortController()
    const { signal } = this.abortController
    this.generator = this.props.generator({ signal, value })
    this.setState({ index: -1, count: 0, done: false })
    await this.next('replace')
  }

  private next = async (behavior: 'replace' | 'append' = 'append') => {
    if (!this.generator) return
    this.setState({ searching: true })
    try {
      const { value, done } = await this.generator.next()
      const toValue = (item: Item) => this.props.convertItem(item).value

      if (behavior === 'append') {
        this.setState((state) => {
          const existing = new Set(state.options.map(toValue))
          const filter = (item: Item) => !existing.has(toValue(item))
          return {
            searching: false,
            done: !!done,
            ...(value && {
              count: value.count,
              options: [...state.options, ...value.items.filter(filter)],
            }),
          }
        })
      } else if (behavior === 'replace') {
        this.setState({
          searching: false,
          done: !!done,
          count: value.count,
          options: value.items,
        })
      }
      if (done) {
        this.generator = null
        this.abortController = null
      }
    } catch (error) {
      this.setState({ searching: false })
    }
  }

  get className(): string {
    return cn(super.className, styles.field)
  }

  getInputProps() {
    const {
      // get rid of these props:
      validationMessage,
      validationMessages,
      label,
      hideValidationMessage,
      className,
      children,
      touched,
      onRecordChange,
      exclude,
      fetchRecord,
      // the rest are passed to input
      ...props
    } = this.props
    return {
      ...props,
      ref: this.element,
      className: cn(this.inputClassName, styles.input),
    }
  }

  renderValue(item: Item) {
    const { label } = this.props.convertItem(item)
    return <output className={styles.value}>{label}</output>
  }
  renderSearchInput() {
    const { ref, generator, convertItem, defaultValue, options, ...props } = this.getInputProps()
    return (
      <Dropdown.ToggleInput
        {...props}
        onChange={this.onSearch}
        form={`${props.name}__lookup_form`}
        onKeyDown={this.handleKeyDown}
        onOpen={this.onSearch}
        aria-haspopup="listbox"
      />
    )
  }
  renderSearchResults() {
    const { exclude } = this.props
    const { options, index } = this.state
    const includedOptions = exclude ? options?.filter((item: Item) => !exclude(item)) : options
    return (
      <FieldDropdownMenu noAutoFocus={index === -1}>
        <List
          as="div"
          items={includedOptions}
          renderItem={(item, index, items) => this.renderOption(item, index, items)}
          renderFooter={() => this.renderLoader() ?? this.renderEmpty(includedOptions)}
          role="listbox"
          aria-busy={this.state.searching}
        />
      </FieldDropdownMenu>
    )
  }
  renderOption(item: Item, index: number, items: Item[]) {
    const { value, label, disabled } = this.props.convertItem(item)
    const isLast = index === items.length - 1
    const selected = this.value === value
    return (
      <Dropdown.Button
        aria-selected={selected}
        key={String(value)}
        disabled={disabled}
        onClick={() => this.select(item)}
        value={String(value)}
        className={cn(listStyles.item, styles.item, selected && styles.current)}
        title={label}
        theme="none"
        role="option"
        onFocus={isLast ? () => this.next() : undefined}
      />
    )
  }
  renderLoader() {
    const { options, count, searching, done } = this.state
    if (done) return null
    if (count && count === options.length) return null
    return (
      <AutoButton
        onClick={() => this.next()}
        className={listStyles.more}
        style={{ '--count': count - options.length } as CSSProperties}
        disabled={searching}
      >
        load more
      </AutoButton>
    )
  }

  renderEmpty(includedOptions: Item[]) {
    if (includedOptions.length) return null
    return (
      <div className={styles.state}>
        {this.state.searching ? 'Searching...' : 'No results. Type to search'}
      </div>
    )
  }

  componentDidMount() {
    super.componentDidMount()
    if (this.state.item) {
      this.props.onRecordChange?.(this.state.item)
    } else if (this.props.fetchRecord && this.state.value) {
      this.props.fetchRecord(this.state.value).then((item) => {
        if (item) {
          this.setState({ item })
          this.props.onRecordChange?.(item)
        }
      })
    }
  }

  render() {
    return (
      <Dropdown.Container>
        <Label
          aria-required={this.props.required ? 'true' : 'false'}
          aria-disabled={this.props.disabled ? 'true' : 'false'}
          label={this.label}
          name={this.props.name}
          className={this.className}
        >
          {this.renderSearchInput()}
          {this.state.item && this.renderValue(this.state.item)}
          {this.state.item ? (
            <IconButton title="Clear" className={styles.clear} onClick={this.clear}>
              <IconClose />
            </IconButton>
          ) : (
            <IconSearch className={styles.icon} />
          )}

          {this.renderSearchResults()}
          {this.shouldShowValidity() && this.renderValidationMessage()}
        </Label>
      </Dropdown.Container>
    )
  }
}

export const LookupField = withFormDefaultValues<string, Props<any>>(Field)
