//
import { LegacyRef, ChangeEventHandler, FocusEventHandler } from 'react'
import { BaseInput, BaseInputProps } from './base-input'

export interface BaseHtmlValidationProps<T> extends BaseInputProps<T> {
  validationMessage?: string | null
  validationMessages?: Partial<Record<keyof ValidityState, string>>
}

export class BaseHtmlValidationInput<
  T extends any,
  P extends BaseHtmlValidationProps<T>,
  E extends HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement = HTMLInputElement,
> extends BaseInput<T, P> {
  element?: E

  setElement: LegacyRef<E> = (element: E) => {
    this.initElement(element)
  }

  handleBlur: FocusEventHandler<E> = (e) => {
    this.validate()
    this.props.onBlur?.(e)
  }

  handleChange: ChangeEventHandler<E> = (event) => {
    const value = this.getValue()
    // update the DOM element validity
    this.resetCustomValidity({ value })
    // let the parent form knows about changes
    this.broadcastUpdates()
  }

  initElement(element: E) {
    this.element = element
    if (!element) return
    const value = this.props.defaultValue || null
    // update the DOM element validity
    this.resetCustomValidity({ value })
    // let the parent form knows about this control
    this.broadcastUpdates({ value })
  }

  format(value: T | null | undefined): string {
    return value ? String(value) : ''
  }

  parse(value: any): T | null {
    return value as T
  }

  willValidate(): boolean {
    return (this.props.name && !this.props.disabled) || false
  }

  isValid() {
    if (!this.element) return false
    if (!this.element.validity.valid) return false
    if (this.checkCustomValidity()) return false
    return true
  }

  resetCustomValidity({ value = this.getValue() }: { value?: T | null } = {}) {
    if (!this.element) return

    const current = this.getValidationMessage()
    const willValidate = this.willValidate()

    if (!willValidate) {
      current && this.element?.setCustomValidity('')
      return
    }
    // @ts-ignore
    const customValidationMessage = this.checkCustomValidity(value)
    if (customValidationMessage) {
      this.element?.setCustomValidity(customValidationMessage)
      return
    }

    const { validationMessage } = this.props
    if (
      validationMessage &&
      validationMessage !== current &&
      validationMessage !== this.recentState.validationMessage
    ) {
      this.element.setCustomValidity(validationMessage)
      return
    }

    this.element.setCustomValidity('')
  }

  checkValidityProps(props: (keyof ValidityState)[]) {
    if (!this.element) return

    const { validationMessages } = this.props
    const { validity } = this.element
    const invalidOverriddenProp =
      validationMessages && props.find((prop) => validity[prop] && validationMessages[prop])

    const htmlValidationMessage = invalidOverriddenProp
      ? validationMessages[invalidOverriddenProp]
      : this.element?.validationMessage

    return htmlValidationMessage || null
  }

  getValidationMessage() {
    return this.checkValidityProps(VALIDITY_PROPS) || this.checkCustomValidity() || null
  }

  focus() {
    this.element?.focus()
  }

  scrollIntoView(options: ScrollIntoViewOptions): void {
    this.element?.scrollIntoView(options)
  }

  getValue(): T | null | undefined {
    if (!this.element) return undefined
    return this.parse(this.element.value)
  }

  setValue(value: T | null = null) {
    if (!this.element) return
    this.element.value = this.format(value)
    this.resetCustomValidity()
    this.broadcastUpdates()
  }

  /**
   * @public
   * @override
   */
  validate() {
    this.resetCustomValidity()
    this.broadcastUpdates({ touched: true })
  }

  // @ts-ignore
  componentDidUpdate(...agrs) {
    this.resetCustomValidity()
    super.componentDidUpdate(...agrs)
  }
}

const VALIDITY_PROPS: (keyof ValidityState)[] = [
  'valueMissing',
  'badInput',
  'typeMismatch',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
]
