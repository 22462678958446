import { FC, ComponentProps } from 'react'
import { HeadsUpWarns } from './heads-up-warns'

type Props = ComponentProps<typeof HeadsUpWarns>

export const HeadsUpWarn: FC<Props> = ({ children, ...props }) => {
  return (
    <HeadsUpWarns {...props}>
      <li>{children}</li>
    </HeadsUpWarns>
  )
}
