import { BackendError } from 'client'
import { toast, ToastOptions, ToastPromiseParams } from 'react-toastify'
import { logError } from './remote-logger'

const ERROR: ToastOptions = {
  autoClose: 5000,
  pauseOnHover: true,
}

export const alertErrorMessage = (
  error?: Error | BackendError | string | null,
  options?: ToastOptions,
): void => {
  let message = !error
    ? 'An unexpected error occured'
    : typeof error === 'string'
    ? error
    : BackendError.is(error) && error.isCancelled
    ? null
    : error.message
  if (!message) return

  toast.error(message, { ...ERROR, ...options })
  logError(message, { context: 'Alert Message' })
}

const SUCCESS: ToastOptions = {
  hideProgressBar: true,
  autoClose: 1000,
}
export const alertSuccessMessage = (message?: string, options?: ToastOptions): void => {
  toast.success(message, { ...SUCCESS, ...options })
}

export function alertPromise<T>(
  promise: Promise<T> | (() => Promise<T>),
  options: ToastPromiseParams,
) {
  return toast.promise(promise, options)
}

type PollOptions = {
  interval?: number
  count?: number
}
const POLL: PollOptions = {
  interval: 2000,
  count: 5,
}
export async function poll<T>(
  promise: () => Promise<T>,
  unless: (result: T) => boolean,
  { interval = 2000, count = 5 }: PollOptions = POLL,
): Promise<T | null> {
  const poll = (count: number): Promise<T | null> => {
    if (count <= 0) {
      return Promise.resolve(null)
    }
    return new Promise<T>((resolve) =>
      delay(interval)
        .then(promise)
        .then((result) => {
          if (unless(result)) resolve(result)
          return poll(count - 1)
        }),
    )
  }
  return poll(count)
}

const delay = (timeout: number) => new Promise((r) => setTimeout(r, timeout))
