import { ComponentProps, FC } from 'react'
import { NavigateOptions, To } from 'react-router-dom'
import { Dialog as PureDialog } from './dialog'
import { useNavigateOut } from '../use-navigate'

interface Props extends Omit<ComponentProps<typeof PureDialog>, 'onClose'> {
  exitPath?: To | null
  exitReplace?: NavigateOptions['replace']
  exitState?: NavigateOptions['state']
  exitResetScroll?: boolean
}

export const Dialog: FC<Props> = ({
  exitPath = '..',
  exitReplace: replace,
  exitState: state,
  exitResetScroll,
  ...props
}) => {
  const out = useNavigateOut(undefined, {
    revalidate: false,
    replace,
    state,
    preventScrollReset: !exitResetScroll,
  })
  return <PureDialog {...props} onClose={exitPath ? out : undefined} />
}
