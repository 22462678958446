import { TextInput, TextInputProps } from '../../input-text/text-input'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = TextInputProps &
  BaseFieldProps<string> & {
    placeholder?: string
  }

export class Field extends BaseField<string, Props> {
  static displayName = 'TextField'

  renderInput(props: TextInputProps) {
    return <TextInput {...props} />
  }
}

export const TextField = withFormDefaultValues<string, Props>(Field)
