import { IconGoogleColor } from 'icons'
import { FC } from 'react'
import { cn } from 'utils'
import { useAuth } from 'web-auth'
import styles from './sign-in-with-google.module.scss'
import { ActionButton } from '../action-button'

interface Props {
  onSignIn?: (user: any) => Promise<any> | any
  onError?: (error: Error) => any
  className?: string
  label?: string
}

export const SignInWithGoogle: FC<Props> = ({ onError, onSignIn, className, label = 'Google' }) => {
  const auth = useAuth<any>()
  const handleSuccess = async (user: any) => {
    // if user is null, it means that the popup was closed
    if (!user) return
    await onSignIn?.(user)
  }
  const googleSignUp = () => auth.signInWithGoogle().then(handleSuccess).catch(onError)
  return (
    <ActionButton theme="secondary" className={cn(styles.button, className)} onClick={googleSignUp}>
      <IconGoogleColor className={styles.icon} />
      <span className={styles.label}>{label}</span>
    </ActionButton>
  )
}
