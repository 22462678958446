import { SelectHTMLAttributes, ChangeEventHandler, FocusEventHandler, ReactNode } from 'react'
import { BaseHtmlValidationInput } from '../input-text/base-html-validation-input'
import { BaseInputProps } from '../input-text/base-input'
import { Options, optionsConverter } from '../utils/options'

type InputSelectHTMLProps = Omit<
  SelectHTMLAttributes<HTMLSelectElement>,
  'onCopy' | 'onChange' | 'value' | 'defaultValue' | 'name' | 'onFocus' | 'onBlur'
>
export interface InputSelectProps extends BaseInputProps<string>, InputSelectHTMLProps {
  placeholder?: string
  options: Options<string>
  validationMessage?: string | null
  validationMessages?: Partial<Record<keyof ValidityState, string>>
  disabledOptions?: (string | null)[]
}

export class Select extends BaseHtmlValidationInput<string, InputSelectProps, HTMLSelectElement> {
  static defaultProps = {
    placeholder: 'Please Select...',
    disabledOptions: [],
  }

  private onBlur: FocusEventHandler<HTMLSelectElement> = (e) => {
    this.validate()
    this.props.onBlur?.(e)
  }

  private onChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { value } = event.target
    // update the DOM element validity
    this.resetCustomValidity({ value })
    // let the parent form knows about changes
    this.broadcastUpdates({ value: this.normalizeValue(value) })
  }

  setValue(value: string | null) {
    if (!this.element) return
    this.element.value = value ?? ''
    this.broadcastUpdates({ value: this.normalizeValue(value) })
  }

  getValue(): string | null {
    return this.normalizeValue(this.element?.value)
  }

  normalizeValue(value?: string | null): string | null {
    if (!value) return null
    const { options } = this.props
    const option = Array.isArray(options)
      ? options.find((option) => typeof option !== 'string' && String(option?.value) === value)
      : null
    return option ? option.value : value
  }

  renderOption([label, value]: [ReactNode, string | null, boolean]) {
    const disabled = this.props.disabledOptions?.includes(value)
    return (
      <option value={value ?? ''} key={this.props.name + '-option-' + value} disabled={disabled}>
        {label}
      </option>
    )
  }

  render() {
    const {
      disabled,
      validation,
      validationMessage,
      validationMessages,
      options,
      placeholder,
      readOnly, // there's no readOnly field in <select>
      disabledOptions,
      className,
      defaultValue,
      ...props
    } = this.props
    return (
      <select
        // tells last pass to ignore it
        data-lpignore="true"
        {...props}
        ref={this.setElement}
        id={props.id || props.name}
        onBlur={this.onBlur}
        onChange={this.onChange}
        className={className}
        data-read-only={readOnly ? 'true' : undefined}
        defaultValue={defaultValue ?? ''}
        disabled={readOnly || disabled}
      >
        <option value="">{placeholder}</option>
        {options && optionsConverter(options).map(this.renderOption, this)}
      </select>
    )
  }
}
