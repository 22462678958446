import { cn } from 'utils'
import styles from './radio.module.scss'
import { InputRadio, InputRadioProps } from '../../input-radio/input-radio'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type RadioTheme = 'button' | 'radio'
type Props = Omit<BaseFieldProps<string | number | boolean>, 'children'> &
  InputRadioProps & {
    theme?: RadioTheme
  }

export class Field extends BaseField<string, Props> {
  static displayName = 'RadioField'

  get className() {
    return cn(styles.radio, this.props.className)
  }
  get inputClassName() {
    const { touched, validationMessage, theme, inputClassName } = this.props
    return cn(
      styles.options,
      theme && styles[theme],
      touched && styles.touched,
      this.shouldShowValidity() && validationMessage && styles.invalid,
      inputClassName,
    )
  }
  get labelClassName() {
    return cn(styles.label, this.props.labelClassName)
  }

  getInputProps() {
    const { id, theme, ...props } = super.getInputProps()
    return props
  }

  render() {
    const { name, children, disabled, readOnly, style } = this.props
    const ariaDisabled = disabled || readOnly ? 'true' : undefined
    return (
      <fieldset
        name={name}
        className={this.className}
        aria-disabled={ariaDisabled}
        style={style}
        aria-invalid={this.shouldShowValidity() && this.props.validationMessage ? 'true' : 'false'}
      >
        <span className={this.labelClassName}>{this.label}</span>
        <InputRadio {...this.getInputProps()}>{children}</InputRadio>
        {this.shouldShowValidity() && this.renderValidationMessage()}
      </fieldset>
    )
  }
}

export const RadioField = withFormDefaultValues<string | number | boolean, Props>(Field)
