import { useSubscription, ChatEventHandler } from './use-subscription'
import { getAdminSupportChannel } from '../ably/channel'

export const useAdminSupportSubscription = (
  cb?: ChatEventHandler<SupportEvent, SupportEventData>,
) => {
  useSubscription<SupportEvent, SupportEventData>(getAdminSupportChannel(), cb)
}

export const enum SupportEvent {
  NEW = 'support-new',
}

export interface SupportEventData {
  chat_id: string
  message_id: string
  user_id: string
}
