import { To, NavigateOptions } from 'react-router-dom'
import { UseNavigateOptions, useNavigate } from './use-navigate'

export type UseNavigateToOptions = UseNavigateOptions & NavigateOptions

export const useNavigateTo = (
  to: To,
  {
    revalidate,
    back,
    preserveSearch,
    preventScrollReset,
    relative,
    replace,
    state,
  }: UseNavigateToOptions = {},
) => {
  const navigate = useNavigate({ revalidate, back, preserveSearch, replace })
  return () => {
    navigate(to, {
      preventScrollReset,
      relative,
      state,
    })
  }
}
