import { IconExpand } from 'icons'
import { FC, ComponentProps, ReactNode } from 'react'
import { cn } from 'utils'
import styles from './dropdown-menu.module.scss'
import { Dropdown } from '../dropdown'

export type Props = ComponentProps<typeof Dropdown.Toggle> & {
  label?: ReactNode | string
  attach?: 'left' | 'right'
  icon?: ReactNode | null
  size?: 'small' | 'large'
  menuClassName?: string
}
export const DropdownMenu: FC<Props> = ({
  children,
  className,
  label,
  attach = 'left',
  icon = <IconExpand />,
  theme = 'secondary',
  size = 'large',
  menuClassName,
  ...props
}) => {
  return (
    <div className={cn(styles.div, className)}>
      <Dropdown.Container>
        <Dropdown.Toggle theme={theme} {...props} className={cn(styles.toggle, styles[theme])}>
          {label && <span className={styles.label}>{label}</span>}
          {icon}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={cn(styles.menu, styles[attach], styles[size], menuClassName)}
          position="fixed"
          attachment={attach}
        >
          {children}
        </Dropdown.Menu>
      </Dropdown.Container>
    </div>
  )
}
