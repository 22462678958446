import { IconClose } from 'icons'
import { FC, ComponentProps } from 'react'
import { To } from 'react-router-dom'
import { cn } from 'utils'
import { CardHeading } from './card-heading'
import styles from './card.module.scss'
import { IconButton } from '../button'
import { Link } from '../link'

interface Props extends ComponentProps<'div'> {
  onRemove?: () => void
  removeLink?: To
  removeLinkState?: object
  removeTitle?: string
  removeLinkResetsScroll?: boolean
  title?: string
}
export const Card: FC<Props> = ({
  className,
  children,
  onRemove,
  removeLink,
  removeLinkState,
  removeLinkResetsScroll,
  removeTitle = 'remove',
  title,
  ...props
}) => {
  return (
    <div {...props} className={cn(styles.card, className)}>
      {title && <CardHeading>{title}</CardHeading>}

      {children}

      {removeLink && (
        <Link
          unstyled
          to={removeLink}
          className={styles.close}
          title={removeTitle}
          state={removeLinkState}
          preventScrollReset={!removeLinkResetsScroll}
        >
          <IconClose />
        </Link>
      )}
      {onRemove && (
        <IconButton className={styles.close} onClick={onRemove} title={removeTitle}>
          <IconClose />
        </IconButton>
      )}
    </div>
  )
}
