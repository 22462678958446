import { DateInput } from '../../input-date'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = DateInput.Props &
  BaseFieldProps<DateInput.Value> & {
    autoComplete?: string
    max?: string
    min?: string
  }

export class Field extends BaseField<string, Props> {
  static displayName = 'DateField'

  renderInput(props: DateInput.Props) {
    return <DateInput {...props} />
  }
}

export const DateField = withFormDefaultValues<DateInput.Value, Props>(Field)
