import { FC, ReactNode } from 'react'
import { useMediaQueryMatch } from './use-media-query-match'
export interface Props {
  children: ReactNode | ReactNode[]
}

export function createMediaQueryComponent(displayName: string, mediaQuery: string) {
  const Component: FC<Props> = ({ children }) => {
    const isVisible = useMediaQueryMatch(mediaQuery)
    return isVisible ? <>{children}</> : null
  }
  Component.displayName = displayName
  return Component
}
