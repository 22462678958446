import { MimeType } from 'api/document'
import { FC } from 'react'
import { cn } from 'utils'
import styles from './file-list.module.scss'
import { useUploadDispatch, useUploadState } from './upload-context'
import { Action } from './upload-reducer'
import { Card } from '../card'
import { FileIcon } from '../file-icon'
import { KeyValuePair } from '../key-value-pair'

interface Props {
  className?: string
}
export const FileList: FC<Props> = ({ className }) => {
  const { files } = useUploadState()
  const dispatch = useUploadDispatch()

  if (!files?.length) return null
  return (
    <ul className={cn(styles.list, className)}>
      {files.map((file) => (
        <li key={file.name + file.type}>
          <Card
            className={styles.card}
            onRemove={() => dispatch({ type: Action.RemoveFile, payload: file })}
          >
            <KeyValuePair label="Document Name" icon={<FileIcon type={file.type as MimeType} />}>
              {file.name}
            </KeyValuePair>
          </Card>
        </li>
      ))}
    </ul>
  )
}
