export interface RecentState<T> {
  value: T | null | undefined
  valid: boolean | undefined
  enable: boolean | undefined
  validationMessage: string | null
  defaultValue: T | null
  touched: boolean
}

export function isBlankValue(
  value: any,
): value is Extract<any, undefined | null | 0 | '' | [] | false> {
  if (value === null || value === undefined || value === false) return true
  if (typeof value === 'string') return value.trim() === ''
  if (typeof value === 'number') return value === 0
  if (Array.isArray(value)) return value.length === 0
  return false
}

export function isEqualValues<T>(v1?: T | null, v2?: T | null): boolean {
  const blank1 = isBlankValue(v1)
  const blank2 = isBlankValue(v2)
  if (blank1 && blank2) return true
  if (blank1 !== blank2) return false
  if (Array.isArray(v1) && Array.isArray(v2)) {
    if (v1.length !== v2.length) return false
    return v1.every((value, index) => value === v2[index])
  }
  return Object.is(v1, v2)
}

export function isEqualState<T>(
  state1: RecentState<T>,
  state2: RecentState<T>,
  isEqual: (v1: T | null, v2: T | null) => boolean = isEqualValues,
) {
  return (
    Object.is(Boolean(state1.enable), Boolean(state2.enable)) &&
    Object.is(Boolean(state1.touched), Boolean(state2.touched)) &&
    Object.is(Boolean(state1.valid), Boolean(state2.valid)) &&
    Object.is(state1.validationMessage, state2.validationMessage) &&
    isEqual(state1.value ?? null, state2.value ?? null) &&
    isEqual(state1.defaultValue ?? null, state2.defaultValue ?? null)
  )
}
