import { User as FirebaseUser } from 'firebase/auth'
import { createContext, useContext, FC, useState, useEffect, ReactNode } from 'react'
import { Auth } from '../firebase'

const Context = createContext<FirebaseUser | null | undefined>(null)
const Provider = Context.Provider

interface Props {
  children?: ReactNode
  auth: Auth<any>
}

export const FirebaseUserProvider: FC<Props> = ({ children, auth }) => {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null | undefined>(undefined)
  useEffect(() => {
    return auth.onTokenChange(({ user }) => setFirebaseUser(user))
  }, [auth])
  return <Provider value={firebaseUser}>{children}</Provider>
}

export const useFirebaseUser = () => {
  return useContext(Context)
}
