import { useEffect } from 'react'

export function useKeydownListener(listener: (event: KeyboardEvent) => void, deps: any[]) {
  useEffect(() => {
    window.addEventListener('keydown', listener, true)
    return () => {
      window.removeEventListener('keydown', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listener, ...deps])
}
