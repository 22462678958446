import { IconExpand } from 'icons'
import { ComponentProps, FC, useEffect, useRef } from 'react'
import { cn } from 'utils'
import styles from './toggle-button.module.scss'
import { Button } from '../../button/button'
import { useExpandable } from '../expandable-context'

interface Props extends Omit<ComponentProps<typeof Button>, 'onClick'> {}

export const ToggleButton: FC<Props> = ({ children, className, ...props }) => {
  const { opened, toggle, open, close } = useExpandable()
  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const toggle = ref?.current

    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          close?.()
          break
        case 'ArrowUp':
          event.preventDefault()
          close?.()
          break
        case 'ArrowDown':
          event.preventDefault()
          open?.()
          break

        default:
          break
      }
    }

    toggle?.addEventListener('keydown', handleKeyDown)

    return () => {
      toggle?.removeEventListener('keydown', handleKeyDown)
    }
  }, [close, open])

  return (
    <Button
      {...props}
      ref={ref}
      onClick={toggle}
      aria-expanded={opened}
      className={cn(styles.button, className)}
    >
      {children ?? <IconExpand className={styles.icon} />}
    </Button>
  )
}
