import mapboxgl from 'mapbox-gl'
import { createContext, useContext } from 'react'

const Context = createContext<mapboxgl.Map | null>(null)

export const MapContextProvider = Context.Provider

export const useMapContext = () => {
  return useContext(Context)
}
