export function filterFileList(files?: FileList | null, accept?: string): File[] {
  if (!files?.length) return [] as File[]
  return accept ? Array.from(files).filter(createAcceptMatcher(accept)) : Array.from(files)
}

function createAcceptMatcher(accept: string) {
  return (file: File) => {
    if (!accept) return true
    const acceptList = accept.split(',').map((item) => item.trim().toLowerCase())
    for (const accept of acceptList) {
      if (['*', '*/*'].includes(accept)) return true
      if (accept === file.type) return true
      if (accept.includes('/')) {
        const [type, subtype] = accept.split('/')
        if (subtype === '*' && file.type.startsWith(type)) return true
      }
    }
    return false
  }
}
