import useResizeObserver from '@react-hook/resize-observer'
import {
  FC,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
  ComponentProps,
  CSSProperties,
} from 'react'
import { cn } from 'utils'
import styles from './expandable-container.module.scss'
import { useExpandable } from '../expandable-context'

interface Props extends ComponentProps<'div'> {
  children: ReactNode | ReactNode[]
  contentClassName?: string
}

export const ExpandableContainer: FC<Props> = ({
  children,
  contentClassName,
  className,
  ...rest
}) => {
  const { opened } = useExpandable()
  const [height, setInnerElHeightState] = useState<number | undefined>(opened ? undefined : 0)
  const innerElementRef = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    setInnerElHeightState(opened ? innerElementRef.current?.clientHeight ?? 0 : 0)
  }, [opened, children])

  useResizeObserver(innerElementRef, (entry) => setInnerElHeightState(entry.contentRect.height))

  return (
    <div
      style={{ '--height': opened ? height : 0 } as CSSProperties}
      {...rest}
      className={cn(styles.expandable, className)}
      aria-expanded={opened}
    >
      <div ref={innerElementRef} className={contentClassName}>
        {children}
      </div>
    </div>
  )
}
