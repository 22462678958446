import { FC } from 'react'
import { Props, createMediaQueryComponent } from './create-component'
import vars from './media-query.module.scss'
import { useMediaQueryMatch } from './use-media-query-match'

const MOBILE = `(max-width: ${vars.breakpointMobile})`
const NON_MOBILE = `(min-width: ${vars.breakpointMobile})`
const DESKTOP = `(min-width: ${vars.breakpointDesktop})`
const NON_DESKTOP = `(max-width: ${vars.breakpointDesktop})`

type Type = {
  Mobile: FC<Props>
  NonMobile: FC<Props>
  Desktop: FC<Props>
  NonDesktop: FC<Props>
}

export const MediaQuery: Type = {
  Mobile: createMediaQueryComponent('MediaQuery.Mobile', MOBILE),
  NonMobile: createMediaQueryComponent('MediaQuery.NonMobile', NON_MOBILE),
  Desktop: createMediaQueryComponent('MediaQuery.Desktop', DESKTOP),
  NonDesktop: createMediaQueryComponent('MediaQuery.NonDesktop', NON_DESKTOP),
} as const

export const useMediaQueryPhone = () => useMediaQueryMatch(MOBILE)
export const useMediaQueryDesktop = () => useMediaQueryMatch(DESKTOP)
