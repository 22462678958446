import { ComponentProps, FC } from 'react'
import { cn } from 'utils'
import styles from './tag.module.scss'
interface Props extends ComponentProps<'div'> {
  theme?: 'success' | 'pending' | 'primary' | 'error'
}

/**
 * CSS custom properties:\
 * `--background`\
 * `--color`
 */
export const Tag: FC<Props> = ({ className, theme, children, ...props }) => {
  return (
    <div {...props} className={cn(styles.tag, theme && styles[theme], className)}>
      {children}
    </div>
  )
}
