import { ReactElement } from 'react'
import { useFormValues } from '../form/context'

interface Props<T> {
  children: ({ values }: { values: Partial<T> }) => ReactElement
}
export function FormValue<T extends Record<string, any>>({ children }: Props<T>) {
  const values = useFormValues<T>()
  return children({ values })
}
