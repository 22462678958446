/* eslint-disable no-console */

const CSS_TITLE = `
  font-weight: normal;
  font-size: 0.5em;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  line-height: 1;
`
const CSS_FIRST_TITLE = `
  font-weight: bold;
  font-size: 0.5em;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  line-height: 1;
  margin-left: -1rem;
`
const CSS_VALUE = `
  font-size: 1em;
  font-weight: normal;
  letter-spacing: initial;
  margin: 0;
`
export class Log {
  private value = ''
  private args: any[] = []

  private css(css: string) {
    this.value += `%c`
    this.args.push(css)
    return this
  }

  text(text: string, css?: string) {
    if (css) this.css(css)
    this.value += text
    return this
  }

  title(label: string) {
    this.value += `%c${label}%c\n`
    this.args.push(CSS_FIRST_TITLE, CSS_VALUE)
    return this
  }

  heading(label: string) {
    this.value += `%c${label}%c\n`
    this.args.push(CSS_TITLE, CSS_VALUE)
    return this
  }
  stringValue(label: string) {
    this.value += `%c%s\n`
    this.args.push(CSS_VALUE, label)
    return this
  }
  objectValue(label: string | null | undefined, obj: any) {
    if (label) {
      this.heading(obj ? label : `empty ${label}`)
    }
    if (obj) {
      this.value += `%o\n`
      this.args.push(obj)
    }
    return this
  }

  reset() {
    this.value = ''
    this.args = []
    return this
  }
  log() {
    console.log(this.value, ...this.args)
    return this.reset()
  }

  groupCollapsed() {
    console.groupCollapsed(this.value, ...this.args)
    return this.reset()
  }
  group() {
    console.groupCollapsed(this.value, ...this.args)
    return this.reset()
  }
  groupEnd() {
    console.groupEnd()
    return this.reset()
  }
}
