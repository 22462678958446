import { cn } from 'utils'
import styles from '../../field.module.scss'
import { CheckboxInput, CheckboxInputProps } from '../../input-checkbox/checkbox-input'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = Omit<BaseFieldProps<boolean>, 'defaultValue'> & CheckboxInputProps
export class Field extends BaseField<boolean, Props> {
  static displayName = 'CheckboxField'

  get className() {
    return cn(super.className, styles.checkbox)
  }

  get inputClassName() {
    const { touched, validationMessage } = this.props
    return cn(
      styles.check,
      touched && styles.touched,
      this.shouldShowValidity() && validationMessage && styles.invalid,
    )
  }
  get labelClassName() {
    return cn(styles.checkboxLabel, this.props.labelClassName)
  }

  render() {
    return (
      <div
        className={this.className}
        aria-disabled={this.props.readOnly || this.props.disabled ? 'true' : 'false'}
        aria-invalid={this.shouldShowValidity() && this.props.validationMessage ? 'true' : 'false'}
        data-field-type="checkbox"
        style={this.props.style}
      >
        <CheckboxInput {...this.getInputProps()} />
        <label htmlFor={this.props.name} className={this.labelClassName}>
          {this.props.label}
          {this.props.children}
        </label>
        {this.shouldShowValidity() && this.renderValidationMessage()}
      </div>
    )
  }
}

export const CheckboxField = withFormDefaultValues<boolean, Props>(Field)
