import { DropdownMenuButton } from './button'
import { DropdownMenuCheckbox } from './checkbox'
import { DropdownContainer } from './container'
import { DropdownMenuExternalLink } from './external-link'
import { DropdownMenuGroup } from './group'
import { DropdownMenuLink } from './link'
import { DropdownMenu } from './menu'
import { DropdownToggle } from './toggle'
import { DropdownToggleInput } from './toggle-input'

type Type = {
  Checkbox: typeof DropdownMenuCheckbox
  Container: typeof DropdownContainer
  Toggle: typeof DropdownToggle
  ToggleInput: typeof DropdownToggleInput
  Menu: typeof DropdownMenu
  Item: typeof DropdownMenuLink
  External: typeof DropdownMenuExternalLink
  Button: typeof DropdownMenuButton
  Group: typeof DropdownMenuGroup
}

export const Dropdown: Type = {
  Checkbox: DropdownMenuCheckbox,
  Container: DropdownContainer,
  Toggle: DropdownToggle,
  ToggleInput: DropdownToggleInput,
  Menu: DropdownMenu,
  Item: DropdownMenuLink,
  External: DropdownMenuExternalLink,
  Button: DropdownMenuButton,
  Group: DropdownMenuGroup,
}
