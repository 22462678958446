import { Context, createContext, useContext } from 'react'

export const FormFieldEmitter = createContext<(state: FieldState) => Promise<void>>(() => {
  throw new Error('Form context is undefined')
})

export interface FieldState<T extends Record<string, any> = {}, K extends keyof T = keyof T> {
  value?: T[K] | null | undefined
  name: string
  enable?: boolean
  defaultValue?: T[K] | null
  validationMessage: string | null
  touched?: boolean
  ref?: FieldComponent
}

export type Fields<T extends Record<string, any>> = Partial<Record<keyof T, FieldState<T, keyof T>>>

export interface FormContext<T extends Record<string, any> = {}> {
  id?: string
  disabled?: boolean | null
  readOnly?: boolean | null
  defaultValues?: Partial<T> | null
  fields: Fields<T>
  pending?: boolean
  modified?: boolean
  valid?: boolean
  values?: Partial<T>
}

export interface FieldComponent {
  reset: () => unknown
  focus: () => unknown
  scrollIntoView?: HTMLDivElement['scrollIntoView']
  setValue: (value: any) => void
}

const formPropsContext = createContext({
  disabled: false,
  readOnly: false,
  defaultValues: {},
  fields: {},
  pending: false,
  // modified: false,
} as FormContext<any>)

export const FormPropsProvider = formPropsContext.Provider

export function useFormProps<T extends object>(): FormContext<T> {
  return useContext(formPropsContext)
}

function getEntryValue<T extends Record<string, any>, K extends keyof T = keyof T>([name, state]: [
  K,
  FieldState<T, K>,
]): [K, T[K] | null] {
  return [name, state?.value ?? null]
}

export function useFormValues<T extends Record<string, any>>(): Partial<T> {
  const { fields } = useFormProps<T>()
  //@ts-ignore
  return Object.fromEntries(Object.entries(fields).map(getEntryValue))
}

export function useField<T extends Record<string, any>, K extends string>(
  name: K | undefined | null,
): FieldState<T, K> | undefined {
  const { fields } = useContext<FormContext<T>>(formPropsContext as Context<FormContext<T>>)
  if (name && fields && fields.hasOwnProperty(name)) {
    return fields[name]
  }
}
