import { ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './field-dropdown-menu.module.scss'
import { Dropdown } from '../../dropdown'

interface Props<T> extends ComponentProps<typeof Dropdown.Checkbox<T>> {}

export function FieldDropdownCheckbox<T>({ className, ...props }: Props<T>) {
  return <Dropdown.Checkbox preventClosing {...props} className={cn(styles.checkbox, className)} />
}
