import { FC, ReactNode, useEffect, useId, useMemo, useState } from 'react'
import { DropdownProvider } from './context'

interface Props {
  children?: ReactNode | ReactNode[]
  defaultOpened?: boolean
}

const containers: Record<string, Api> = {}
const closeAllBut = (id: string) => {
  Object.entries(containers).forEach(([key, api]) => {
    if (key !== id) api?.close()
  })
}

export const DropdownContainer: FC<Props> = ({ children, defaultOpened = false }) => {
  const [opened, setOpened] = useState(defaultOpened)
  const [opener, setOpener] = useState<HTMLElement>()
  const id = useId()
  const api = useMemo<Api>(
    () => ({
      id,
      opened,
      opener,
      toggle: (opener?: HTMLElement) => {
        if (opened) setOpened(false)
        else {
          closeAllBut(id)
          setOpened(!opened)
          setOpener(opener)
        }
      },
      open: (opener: HTMLElement) => {
        closeAllBut(id)
        setOpened(true)
        setOpener(opener)
      },
      close: () => {
        if (opened) {
          setOpened(false)
          setOpener(undefined)
        }
      },
    }),
    [opened, opener, id],
  )
  useEffect(() => {
    containers[id] = api
    return () => {
      delete containers[id]
    }
  }, [id, api])

  return <DropdownProvider value={api}>{children}</DropdownProvider>
}

type Api = {
  id: string
  opened: boolean
  toggle(opener?: HTMLElement): void
  open(opener?: HTMLElement): void
  close(): void
  opener?: HTMLElement
}
