export const channelPrefix = import.meta.env.VITE_ABLY_CHANNEL_PREFIX

export const IS_ABLY_ENABLED = !!channelPrefix

export const getAuctionChannel = (auction_id?: string) =>
  `${channelPrefix}auction.${auction_id ?? ''}`

export const getLeaseChannel = (auction_id?: string) => `${channelPrefix}lease.${auction_id ?? ''}`
export const getChatChannel = (chat_id?: string) => `${channelPrefix}chat.${chat_id ?? ''}`
export const getUserChatChannel = (user_id?: string) =>
  `${channelPrefix}user.${user_id ?? ''}.chats`
export const getUserChannel = (user_id?: string) => `${channelPrefix}user.${user_id ?? ''}`
export const getAdminSupportChannel = () => `admin.support`
