import { FC, ComponentProps, useCallback, useState } from 'react'
import { pdfjs, Document } from 'react-pdf'
// import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

interface Props extends Omit<ComponentProps<typeof Document>, 'onError'> {
  onLoadPages?: (pages: PdfPageDefinition[]) => void
  onError?: (error: Error) => any
  pdf: Blob
}

export const PdfDocument: FC<Props> = ({ children, onLoadPages, onError, pdf, ...props }) => {
  const [error, setError] = useState(false)

  const onDocumentLoadSuccess = useCallback<LoadSuccessCallback>(
    (cfg) =>
      collectPages(cfg)
        .then(onLoadPages)
        .catch((e) => {
          setError(true)
          onError?.(e)
        }),
    [onLoadPages, onError],
  )

  if (error) return null
  return (
    <Document {...props} file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
      {children}
    </Document>
  )
}

type DocumentProps = ComponentProps<typeof Document>
type LoadSuccessCallback = Required<DocumentProps>['onLoadSuccess']
type LoadedConfig = Parameters<LoadSuccessCallback>[0]

export interface PdfPageDefinition {
  id: number
  width: number
  height: number
}

async function collectPages(cfg: LoadedConfig): Promise<PdfPageDefinition[]> {
  const pdfPages = await Promise.all(
    Array.from({ length: cfg.numPages }, (_, i) => cfg.getPage(i + 1)),
  )
  return pdfPages.map((page, id) => {
    const viewport = page.getViewport()
    const [x1, y1, x2, y2] = viewport.viewBox
    return {
      id,
      height: y2 - y1,
      width: x2 - x1,
    }
  })
}
