import { createContext, FC, ReactNode, useContext, useState } from 'react'

export interface ContextValue {
  opened?: boolean
  toggle: () => void
  open?: () => void
  close?: () => void
}

export const Context = createContext<ContextValue>({
  opened: false,
  toggle: () => {},
})

const Provider = Context.Provider

interface Props {
  children: ReactNode
  storageKey?: string | null
  defaultOpened?: boolean
}

export const ExpandebleProvider: FC<Props> = ({ children, storageKey, defaultOpened = false }) => {
  const [opened, setOpened] = useState(
    storageKey
      ? () => {
          const localData = localStorage.getItem(storageKey)
          return localData ? localData === 'true' : defaultOpened
        }
      : defaultOpened,
  )

  const handleExpandClick = () => {
    setOpened((opened) => !opened)
    if (storageKey) localStorage.setItem(storageKey, String(!opened))
  }

  const handleClose = () => {
    setOpened(false)
  }

  const handleOpen = () => {
    setOpened(true)
  }

  return (
    <Provider
      value={{
        opened,
        toggle: handleExpandClick,
        open: handleOpen,
        close: handleClose,
      }}
    >
      {children}
    </Provider>
  )
}

export const useExpandable = () => {
  return useContext(Context)
}
