import { motion, AnimatePresence } from 'framer-motion'
import {
  FC,
  useCallback,
  useEffect,
  useState,
  KeyboardEventHandler,
  ReactNode,
  useRef,
  ComponentProps,
  CSSProperties,
} from 'react'
import { cn } from 'utils'
import { useDropdownContext } from './context'
import styles from './menu.module.scss'
import { Portal } from '../portal'

interface Props extends ComponentProps<typeof motion.nav> {
  className?: string
  children?: ReactNode
  noAutoFocus?: boolean
  position?: 'fixed' | 'absolute'
  attachment?: 'left' | 'right'
  closeOnScroll?: boolean
}

export const DropdownMenu: FC<Props> = ({
  className,
  children,
  noAutoFocus,
  position = 'absolute',
  attachment = 'left',
  closeOnScroll = position === 'fixed',
  ...props
}) => {
  const dropdownContext = useDropdownContext()
  const close = dropdownContext?.close
  const ref = useRef<HTMLDivElement>(null)
  const [, setCurrentIndex] = useState<number | undefined>(0)

  const rect = position === 'fixed' && dropdownContext?.opener?.getBoundingClientRect()
  const style: CSSProperties | undefined = rect
    ? {
        position: 'fixed',
        top: rect.top + rect.height,
        ...(attachment === 'left' && { left: rect.left }),
        ...(attachment === 'right' && { right: document.body.clientWidth - rect.right }),
      }
    : undefined

  const getLinks = () => {
    if (!ref.current) return []
    return Array.from(
      ref.current.querySelectorAll(ITEMS_SELECTOR) as NodeListOf<
        HTMLAnchorElement | HTMLButtonElement
      >,
    )
  }

  const highlightByIndex = useCallback((nextIndex: number, links = getLinks()) => {
    links?.[nextIndex]?.focus()
  }, [])

  useEffect(() => {
    !noAutoFocus && dropdownContext?.opened && highlightByIndex(0)
  }, [highlightByIndex, dropdownContext?.opened, noAutoFocus])

  useEffect(() => {
    if (!close) return
    if (!dropdownContext?.opened) return

    const clickOrFocusListener = ({ target }: FocusEvent | MouseEvent) => {
      if (!ref.current || !target) return
      if ((target as HTMLAnchorElement).dataset?.dropdown) return
      if (ref.current.contains(target as Node)) return
      close()
    }
    const scrollListener = closeOnScroll ? close : undefined

    document.body.addEventListener('focus', clickOrFocusListener)
    document.body.addEventListener('click', clickOrFocusListener)
    scrollListener && window.addEventListener('scroll', scrollListener)
    return () => {
      document.body.removeEventListener('focus', clickOrFocusListener)
      document.body.removeEventListener('click', clickOrFocusListener)
      scrollListener && window.removeEventListener('scroll', scrollListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close, dropdownContext?.opened, closeOnScroll])

  const handleKeyDown: KeyboardEventHandler<HTMLUListElement> = useCallback(
    (event) => {
      if (!close) return
      switch (event.key) {
        case 'Escape':
          event.preventDefault()
          close()
          break
        case 'ArrowDown':
          event.preventDefault()
          setCurrentIndex((prevIndex) => {
            const links = getLinks()
            const nextIndex =
              prevIndex !== undefined && prevIndex < links.length - 1 ? prevIndex + 1 : 0
            highlightByIndex(nextIndex, links)
            return nextIndex
          })
          break
        case 'ArrowUp':
          event.preventDefault()
          setCurrentIndex((prevIndex) => {
            const links = getLinks()
            const nextIndex =
              prevIndex !== undefined && prevIndex > 0 ? prevIndex - 1 : links.length - 1
            highlightByIndex(nextIndex, links)
            return nextIndex
          })
          break
      }
    },
    [close, highlightByIndex],
  )

  const element = (
    <AnimatePresence mode="wait">
      {dropdownContext?.opened && (
        <motion.nav
          transition={DEFAULT_TRANSITION}
          exit={DEFAULT_EXIT}
          animate={DEFAULT_ANIMATE}
          initial={DEFAULT_INITIAL}
          {...props}
          className={cn(styles.nav, className)}
          onKeyDown={dropdownContext && handleKeyDown}
          style={style}
          ref={ref}
          data-dropdown="menu"
          id={`${dropdownContext?.id}-menu`}
        >
          {children}
        </motion.nav>
      )}
    </AnimatePresence>
  )
  return position === 'fixed' ? <Portal rootId={styles.root}>{element}</Portal> : element
}

const ITEMS_SELECTOR = ['[data-dropdown="link"]', '[data-dropdown="button"]'].join(', ')

const DEFAULT_TRANSITION = { duration: 0.16, type: 'spring', damping: 10, stiffness: 100 }
const DEFAULT_EXIT = { opacity: 0, translateY: -10, pointerEvents: 'none' } as const
const DEFAULT_ANIMATE = { opacity: 1, translateY: 0, pointerEvents: 'all' } as const
const DEFAULT_INITIAL = { opacity: 0.5, translateY: -10, pointerEvents: 'none' } as const
