import { TextInput, TextInputProps } from '../../input-text/text-input'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = Omit<TextInputProps & BaseFieldProps<string>, 'type' | 'autoComplete'> & {
  autoCompletePrefix?: string
}

export class Field extends BaseField<string, Props> {
  static displayName = 'CityField'

  renderInput(props: TextInputProps) {
    return <TextInput {...props} />
  }

  getInputProps() {
    const { autoCompletePrefix, ...props } = super.getInputProps()
    return {
      ...props,
      autoComplete: autoCompletePrefix ? `${autoCompletePrefix} address-level2` : 'address-level2',
    }
  }
}

export const CityField = withFormDefaultValues<string, Props>(Field)
