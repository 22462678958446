import { forwardRef, useMemo, FC, ComponentProps } from 'react'
import { CA, UK, US } from './const'
import { useField } from '../../form/context'
import { BaseFieldProps } from '../_base'
import { TextField } from '../text'

type PostalCodeProps = BaseFieldProps<string> &
  Omit<ComponentProps<typeof TextField>, 'autoComplete'> & {
    autoCompletePrefix?: string
    country?: string
    countryField?: string
  }

export const PostalCode: FC<PostalCodeProps> = forwardRef(
  (
    { country: _country, countryField, autoCompletePrefix, placeholder = '12345', ...props },
    ref,
  ) => {
    const countryState = useField(countryField)
    const country = _country ?? countryState?.value ?? null
    const isUS = country && country.toUpperCase() === US

    const label = isUS ? 'ZIP' : 'Postal Code'
    const autoComplete = [autoCompletePrefix, 'postal-code'].filter(Boolean).join(' ')
    const validation = useMemo(() => createValidation(country), [country])

    return (
      <TextField
        inputMode={isUS ? 'numeric' : undefined}
        {...props}
        label={label}
        placeholder={placeholder}
        autoComplete={autoComplete}
        maxLength={isUS ? 5 : undefined}
        validation={validation}
        ref={ref}
      />
    )
  },
)

PostalCode.displayName = 'PostalCodeField'

const createValidation =
  (country: string) =>
  (value: string | null): string | null => {
    if (!value) {
      return null
    }
    const reUS = /^[0-9]{5}(?:-[0-9]{4})?$/
    const reCA = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/
    const reGB = /^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$/
    switch (country && country.toUpperCase()) {
      case US:
        return reUS.test(value) ? '' : 'Invalid ZIP code'
      case CA:
        return reCA.test(value) ? '' : 'Invalid postal code'
      case UK:
        return reGB.test(value) ? '' : 'Invalid postal code'
      default:
        return null
    }
  }
