import { MouseEvent } from 'react'
import IconHide from './visibility-hide.svg?react'
import IconShow from './visibility-show.svg?react'
import { IconButton } from '../../../button/icon-button'
import styles from '../../field.module.scss'
import { TextInput, TextInputProps } from '../../input-text/text-input'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = Omit<TextInputProps, 'type'> &
  BaseFieldProps<string> & {
    current?: boolean
  }

interface State {
  visible?: boolean
}

export class Field extends BaseField<string, Props, State> {
  static displayName = 'PasswordField'
  state = {
    visible: false,
  }

  toggleVisibility = (event: MouseEvent) => {
    event.preventDefault()
    this.setState((state) => ({ visible: !state.visible }))
  }

  getInputProps() {
    const { current, ...props } = super.getInputProps()
    return {
      ...props,
      autoComplete: `${current ? 'current' : 'new'}-password`,
    }
  }

  renderInput(props: TextInputProps) {
    const { visible } = this.state
    return (
      <>
        <TextInput {...props} type={visible ? 'text' : 'password'} key="input" />
        {this.value ? (
          <IconButton
            onClick={this.toggleVisibility}
            className={styles.append}
            disabled={props.disabled}
            tabIndex={-1}
          >
            {visible ? <IconHide /> : <IconShow />}
          </IconButton>
        ) : null}
      </>
    )
  }
}

export const PasswordField = withFormDefaultValues<string, Props>(Field)
