import { ComponentProps, MouseEventHandler, ReactElement, useCallback, ReactNode } from 'react'
import { cn } from 'utils'
import styles from './checkbox.module.scss'
import { useDropdownContext } from './context'
import { Button } from '../button'

type Props<T> = Omit<ComponentProps<typeof Button>, 'children' | 'title' | 'value'> & {
  title: ReactNode
  icon?: ReactElement
  className?: string
  preventClosing?: boolean
  checked?: boolean
  onToggle: (value: T) => void
  value: T
}

export function DropdownMenuCheckbox<T>({
  className,
  title,
  onToggle,
  preventClosing,
  checked,
  disabled,
  value,
  ...props
}: Props<T>) {
  const { close } = useDropdownContext() ?? {}

  const click = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    onToggle?.(value)
    !preventClosing && close?.()
  }, [close, onToggle, preventClosing, value])

  return (
    <Button
      {...props}
      className={cn(styles.checkbox, checked && styles.checked, className)}
      onClick={click}
      data-dropdown="button"
      data-checked={checked}
      tabIndex={-1}
      disabled={disabled}
    >
      <span className={styles.check} aria-disabled={disabled ? 'true' : 'false'} />
      <span className={styles.title}>{title}</span>
    </Button>
  )
}
