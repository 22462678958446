import { IconCompleted, IconDecline, IconWarning } from 'icons'
import { ComponentProps, FC } from 'react'
import { cn } from 'utils'
import styles from './description.module.scss'

export interface Props extends ComponentProps<'div'> {
  warn?: boolean
  error?: boolean
  success?: boolean
}
export const Description: FC<Props> = ({ children, className, error, warn, success, ...props }) => {
  return (
    <div
      {...props}
      className={cn(
        styles.description,
        error && styles.error,
        warn && styles.warn,
        success && styles.success,
        className,
      )}
    >
      {error && <IconDecline />}
      {warn && <IconWarning />}
      {success && <IconCompleted />}
      {children}
    </div>
  )
}
