import { ChangeEvent, LegacyRef } from 'react'
import Input from 'react-phone-input-2'
import { BaseInput, BaseInputProps } from './base-input'

interface Props extends BaseInputProps<string> {
  country?: string
  placeholder?: string
  className?: string
  containerClass?: string
  label?: string
}

interface State {
  value: string | null
  defaultValue: string | null
  formattedValue?: string
}

const US = 'us'
export class PhoneInput extends BaseInput<string, Props, State> {
  static defaultProps = {
    country: US,
    placeholder: '+1 (555) 555-5555',
  }

  static getDerivedStateFromProps({ defaultValue }: Props, state: State): State | null {
    if (defaultValue !== state.defaultValue) {
      return {
        value: defaultValue ?? null,
        defaultValue: defaultValue ?? null,
        formattedValue: undefined,
      }
    }
    return null
  }

  element?: HTMLInputElement = undefined

  currentValue = this.props.defaultValue ?? null

  state: State = {
    value: this.props.defaultValue ?? null,
    defaultValue: this.props.defaultValue ?? null,
  }

  setElement: LegacyRef<HTMLInputElement> = (element: HTMLInputElement) => {
    this.element = element
    // let the parent form knows about this control
    this.broadcastUpdates({ value: this.state.value || null })
  }

  onChange = (_value: string, country: {}, e: ChangeEvent, formattedValue: string) => {
    const value = _value?.replace(/^(\d+)$/, '+$1')
    this.currentValue = value || ''
    this.setState({ value, formattedValue }, () => {
      // let the parent form knows about changes
      this.broadcastUpdates({ value: value || null })
    })
  }

  handleBlur = () => {
    this.validate()
  }

  reset(): void {
    this.currentValue = this.props.defaultValue ?? null
    this.setState({ value: this.props.defaultValue ?? null })
  }

  focus(): void {
    this.element?.focus()
  }

  scrollIntoView(options?: ScrollIntoViewOptions): void {
    this.element?.scrollIntoView(options)
  }

  setValue(value: string | null | undefined): void {
    this.currentValue = value ?? null
    this.setState({ value: value || null, formattedValue: undefined })
  }

  getValue() {
    return this.isEmpty(this.currentValue) ? null : this.currentValue
  }

  isEmpty(value?: string | null | undefined): boolean {
    if (!value) return true
    const trim = value.trim()
    if (trim.match(/^\+?1?$/)) return true
    return false
  }

  isValid(value = this.getValue()): boolean {
    if (!this.willValidate()) return true
    if (this.isEmpty(value)) return !this.props.required
    return !!value?.match(/\+?\d{11}/)
  }

  protected getValidationMessage(): string | null {
    if (!this.willValidate() || this.isValid()) return null
    if (this.isEmpty()) {
      return this.props.required ? 'The field is required' : null
    }
    return 'Invalid phone'
  }

  render() {
    const {
      validation,
      defaultValue,
      country,
      placeholder,
      className,
      containerClass,
      label,
      ...props
    } = this.props
    return (
      <Input
        specialLabel={label}
        placeholder={placeholder}
        value={this.state.formattedValue ?? this.state.value}
        inputClass={className}
        containerClass={containerClass}
        inputProps={{
          ...props,
          id: props.id || props.name,
          autoComplete: 'tel',
          ref: this.setElement,
        }}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        isValid={this.isValid.bind(this)}
        disableDropdown
        country={US}
        autoFormat
        disableSearchIcon
      />
    )
  }
}
