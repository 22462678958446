import { ComponentProps, ReactNode, FC } from 'react'
import { cn } from 'utils'
import styles from './card-heading.module.scss'

type Props = ComponentProps<'h3'> & {
  children?: ReactNode | ReactNode[]
}

export const CardHeading: FC<Props> = ({ children, className, ...props }) => {
  return children ? (
    <h3 {...props} className={cn(styles.heading, className)}>
      {children}
    </h3>
  ) : null
}
