import { FC, ComponentProps } from 'react'
import { Money } from './money'

interface Props extends Omit<ComponentProps<typeof Money>, 'value'> {
  min?: number | null
  max?: number | null
  proximityToZero?: number
  zero?: string | number
  divider?: React.ReactNode
}

export const MoneyRange: FC<Props> = ({
  className,
  currency,
  locales,
  integer,
  min,
  max,
  proximityToZero,
  zero = 0,
  divider = <>&nbsp;&mdash; </>,
  ...props
}) => {
  const isZero = (value?: number | null): value is Exclude<number | null | undefined, number> =>
    typeof value === 'number'
      ? typeof proximityToZero === 'number'
        ? value < proximityToZero
        : value === 0
      : true

  const minElement = isZero(min) ? null : (
    <Money currency={currency} locales={locales} integer={integer} value={min} />
  )
  const maxElement = isZero(max) ? null : (
    <Money currency={currency} locales={locales} integer={integer} value={max} />
  )

  return (
    <span {...props} className={className}>
      {minElement && maxElement ? (
        <>
          {minElement}
          {divider}
          {maxElement}
        </>
      ) : (
        minElement ?? maxElement ?? zero
      )}
    </span>
  )
}
