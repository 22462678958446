import { FC } from 'react'

interface Props {
  className?: string
  locales?: string | string[]
  currency?: Intl.NumberFormatOptions['currency']
  value?: number
  integer?: boolean | 'auto' | 'ceil' | 'round'
}

export const Money: FC<Props> = ({
  className,
  currency = 'USD',
  value,
  locales = 'en-US',
  integer = false,
  ...props
}) => {
  return (
    <span {...props} className={className}>
      {formatMoney(value, { integer, currency, locales })}
    </span>
  )
}

export function formatMoney(
  amount = 0,
  {
    integer = false,
    currency = 'USD',
    locales = 'en-US',
  }: Pick<Props, 'integer' | 'currency' | 'locales'> = {},
) {
  const fractions =
    integer === 'auto'
      ? amount % 1 === 0
        ? 0
        : 2
      : [true, 'ceil', 'round'].includes(integer)
      ? 0
      : 2
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: fractions,
    maximumFractionDigits: fractions,
    style: 'currency',
    currency: currency ?? 'USD',
  }
  return new Intl.NumberFormat(locales, options).format(
    integer === 'ceil' ? Math.ceil(amount) : integer === 'round' ? Math.round(amount) : amount,
  )
}
