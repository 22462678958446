import { FC, ReactElement } from 'react'
import { useFormProps } from '../form/context'

interface IsModifiedProps {
  children: ReactElement
  show?: boolean
  not?: boolean
}

export const IsModified: FC<IsModifiedProps> = ({ children, show, not }) => {
  const { modified } = useFormProps()

  let result = show || modified
  if (not) {
    result = !result
  }
  return result ? children : null
}
