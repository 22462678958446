import { cn } from 'utils'
import styles from '../../field.module.scss'
import { NumberInput, NumberInputProps } from '../../input-number/input-number'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = Omit<NumberInputProps, 'type' | 'name'> & BaseFieldProps<number>

export class Field extends BaseField<number, Props> {
  static displayName = 'NumberField'

  get inputClassName() {
    return cn(super.inputClassName, styles.number)
  }

  renderInput(props: NumberInputProps) {
    return <NumberInput {...props} />
  }
  setValue(value: number | null) {
    if (!this.element.current) return
    this.element.current.value = value
  }
}

export const NumberField = withFormDefaultValues<number, Props>(Field)
