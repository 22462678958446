import { Client, DeleteConfig, GetConfig, PostConfig, UPLOAD_TIMEOUT } from 'client'
import { ListQuery } from 'utils/list'

export const enum MimeType {
  jpeg = 'image/jpeg',
  jpg = 'image/jpg',
  png = 'image/png',
  webp = 'image/webp',
  pdf = 'application/pdf',
}

export const IMG = [MimeType.jpeg, MimeType.jpg, MimeType.webp, MimeType.png]
export const DOC = [MimeType.pdf]
export const ACCEPT_IMG = IMG.join(',')
export const ACCEPT_DOC = DOC.join(',')
export const ACCEPT_IMG_DOC = [ACCEPT_IMG, ACCEPT_DOC].join(',')

export interface UserFile {
  created_at: string
  file_id: string
  filename: string
  mimetype: MimeType
  size: number
  source: string
  user_id: string
}
export namespace UserFile {
  export type IdField = 'file_id'
  export type Id = Pick<UserFile, IdField>

  export type Sort = 'created_at' | 'filename' | 'mimetype' | 'size'
  export type Query = ListQuery<
    Sort,
    {
      file_id?: string[]
      filename?: string
      source?: string[]
      user_id?: string[]
    }
  >
  export type Filter = Query['filter']

  export const MSG = {
    ERR: {
      NO_ID: 'Missing file id.',
      NOT_FOUND: 'File not found.',
      UNSUPPORTED: 'Unsupported file format.',
    },
  }
}

export class UserFileBackend extends Client {
  /** @see https://api-dev.rello.co/swagger/index.html#/file/post_file_get */
  list = async (query?: UserFile.Query, config?: PostConfig): Promise<UserFile[]> => {
    const { files } = await this.post<UserFile.Query, { status: 'success'; files: UserFile[] }>(
      '/file/get',
      query ?? {},
      config,
    )
    return files
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/file/post_file_count */
  count = async ({ filter }: UserFile.Query, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<UserFile.Query, { status: 'success'; count: number }>(
      '/file/count',
      { filter },
      config,
    )
    return count
  }

  byId = async (id: string, config?: PostConfig): Promise<UserFile> => {
    const [file] = await this.list({ filter: { file_id: [id] } }, config)
    if (!file) throw new Error(UserFile.MSG.ERR.NOT_FOUND)
    return file
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/file/get_file_download */
  downloadBlobById = async (fileid: string, config?: GetConfig): Promise<Blob> => {
    const blob = await this.get<Blob, { fileid: string }>(
      '/file/download',
      { fileid },
      { ...config, responseType: 'blob' },
    )
    if (blob.size === 0) throw new Error(UserFile.MSG.ERR.NOT_FOUND)
    return blob
  }

  downloadAsURLById = async (id: string, config?: GetConfig): Promise<string> => {
    const blob = await this.downloadBlobById(id, config)
    return URL.createObjectURL(blob)
  }

  /**
   * @returns file_id
   * @see https://api-dev.rello.co/swagger/index.html#/file/post_file_upload
   */
  upload = async (data: FormData, config?: PostConfig): Promise<string> => {
    const { file_id } = await this.post<FormData, { file_id: string; status: string }>(
      '/file/upload',
      data,
      {
        timeout: UPLOAD_TIMEOUT,
        ...config,
        headers: {
          ...config?.headers,
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return file_id
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/file/delete_file_delete */
  remove = async (id: string, config?: DeleteConfig): Promise<void> => {
    await this.delete('/file/delete', {
      ...config,
      params: { ...config?.params, docid: id },
    })
  }
}

export const userFile = new UserFileBackend()
