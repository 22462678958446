import { useEffect, useRef, useState } from 'react'

export function useMediaQueryMatch(mediaQuery: string): boolean {
  const listRef = useRef(window.matchMedia(mediaQuery))
  const [isVisible, setVisible] = useState(listRef.current.matches)
  useEffect(() => {
    const list = listRef.current
    const listener = (event: MediaQueryListEvent) => setVisible(event.matches)
    list.addEventListener('change', listener)
    return () => {
      list.removeEventListener('change', listener)
    }
  })
  return isVisible
}
