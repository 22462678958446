import { ComponentProps, FC, ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import { cn } from 'utils'
import { useDropdownContext } from './context'
import styles from './link.module.scss'
import { Badge } from '../badge'
import { resolveLinkParams, NavLink } from '../link'

type Props = Omit<ComponentProps<typeof NavLink>, 'children'> &
  Pick<ComponentProps<typeof Badge>, 'severnity' | 'amount'> & {
    title: string
    icon?: ReactElement
    className?: string
    preserveSearch?: boolean
  }

export const DropdownMenuLink: FC<Props> = ({
  className,
  title,
  icon,
  onClick,
  severnity,
  amount = 0,
  state: _state,
  to: _to,
  preserveSearch,
  ...props
}) => {
  const from = useLocation()
  const dropdownContext = useDropdownContext()
  const { state, to } = resolveLinkParams({
    to: _to,
    state: _state,
    from,
    preserveSearch,
  })

  return (
    <NavLink
      {...props}
      className={cn(styles.link, className)}
      onClick={dropdownContext?.close}
      tabIndex={-1}
      data-dropdown="link"
      state={state}
      to={to}
    >
      {icon}
      <span className={styles.title}>{title}</span>
      {amount > 0 && <Badge amount={amount} severnity={severnity} className={styles.badge} />}
    </NavLink>
  )
}
