import { useLocation, useNavigation } from 'react-router-dom'

interface Config {
  ignoreSamePath?: boolean
}

export function usePathTransition(config?: Config) {
  const current = useLocation()
  const next = useNavigation()
  if (next.state !== 'loading') return false
  if (config?.ignoreSamePath) {
    if (current.pathname === next.location.pathname) return false
  }
  return true
}
