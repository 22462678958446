import { isYMD, isoToServerDate, toISODatetime } from 'utils/date'
import type { PickBoolean, PickNumber, PickString } from 'utils/type-utils'

export const NULL_DATE = '0001-01-01T00:00:00Z'

function replaceNullWithZero<T extends object>(record: T, fields?: (keyof PickNumber<T>)[]): T {
  return !fields?.length
    ? record
    : fields?.reduce((record, name) => {
        if (!Object.hasOwn(record, name)) return record
        return record[name] === null ? Object.assign(record, { [name]: 0 }) : record
      }, record)
}

function replaceNullWithString<T extends object>(record: T, fields?: (keyof PickString<T>)[]): T {
  return !fields?.length
    ? record
    : fields?.reduce((record, name) => {
        if (!Object.hasOwn(record, name)) return record
        return record[name] === null ? Object.assign(record, { [name]: '' }) : record
      }, record)
}

function removeFalseValues<T extends object>(record: T, fields?: (keyof PickBoolean<T>)[]): T {
  return !fields?.length
    ? record
    : Object.entries(record).reduce((result, [key, value]) => {
        if (fields.includes(key as keyof PickBoolean<T>)) {
          return value ? { ...result, [key]: value } : result
        }
        return { ...result, [key]: value }
      }, {} as T)
}

export function dateToServerDate(date: string | null): string {
  return (
    (!date ? NULL_DATE : isYMD(date) ? isoToServerDate(date) : toISODatetime(date)) ?? NULL_DATE
  )
}

export function replaceDateWithDateTime<T extends object>(
  record: T,
  fields?: (keyof PickString<T>)[],
): T {
  return !fields?.length
    ? record
    : fields.reduce((record, name) => {
        if (!Object.hasOwn(record, name)) return record
        const value = record[name] ? String(record[name]).trim() : null
        return Object.assign(record, { [name]: dateToServerDate(value) })
      }, record)
}

export function convertToServerData<T extends object>(
  record: T,
  options: {
    string?: (keyof PickString<T>)[]
    date?: (keyof PickString<T>)[]
    number?: (keyof PickNumber<T>)[]
    noFalse?: (keyof PickBoolean<T>)[]
  },
): T {
  let result = record
  if (options.date) result = replaceDateWithDateTime<T>(record, options.date)
  if (options.number) result = replaceNullWithZero<T>(record, options.number)
  if (options.string) result = replaceNullWithString<T>(record, options.string)
  if (options.noFalse) result = removeFalseValues<T>(record, options.noFalse)
  return result
}
