import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './field-dropdown-toggle.module.scss'
import { Dropdown } from '../../dropdown'
import IconDown from '../field/select/select-arrow.svg?react'
import fieldStyles from '../field.module.scss'

interface Props extends ComponentProps<typeof Dropdown.Toggle> {
  name: string
  placeholder?: string
}
export const FieldDropdownToggle: FC<Props> = ({
  children,
  className,
  name,
  disabled,
  placeholder,
  ...props
}) => {
  return (
    <Dropdown.Toggle
      {...props}
      className={cn(styles.toggle, fieldStyles.input, !children && styles.placeholder)}
      disabled={disabled}
      name={name}
      aria-haspopup="listbox"
    >
      <span className={styles.value}>{children ?? placeholder}</span>
      <IconDown className={styles.chevron} />
    </Dropdown.Toggle>
  )
}
