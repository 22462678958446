import { IconExpand } from 'icons'
import { ComponentProps, FC, ReactElement, ReactNode } from 'react'
import { useMatch } from 'react-router-dom'
import { cn } from 'utils'
import styles from './group.module.scss'
import { Badge } from '../badge'
import { Expandable } from '../expandable'

type Props = Pick<ComponentProps<typeof Badge>, 'severnity' | 'amount'> & {
  title: string
  icon?: ReactElement
  className?: string
  children?: ReactNode | ReactNode[]
  path: string
  defaultOpened?: boolean
}

export const DropdownMenuGroup: FC<Props> = ({
  className,
  title,
  icon,
  severnity,
  amount = 0,
  children,
  path,
  defaultOpened,
  ...props
}) => {
  const match = !!useMatch({ path, end: false })
  return (
    <Expandable.Context defaultOpened={defaultOpened ?? match}>
      <Expandable.Toggle
        {...props}
        className={cn(styles.toggle, className)}
        tabIndex={-1}
        data-dropdown="group"
        aria-current={match ? 'true' : undefined}
      >
        {icon}
        <span className={styles.title}>{title}</span>
        {amount > 0 && <Badge amount={amount} severnity={severnity} className={styles.badge} />}
        <IconExpand className={styles.shevron} />
      </Expandable.Toggle>
      <Expandable.Content className={styles.content}>{children}</Expandable.Content>
    </Expandable.Context>
  )
}
