import { createContext, Dispatch, useContext } from 'react'
import { ActionType, initialState, State } from './upload-reducer'

const UploadStateContext = createContext<State>(initialState)
const UploadDispatchContext = createContext<Dispatch<ActionType>>(() => {})

export const UploadStateProvider = UploadStateContext.Provider
export const useUploadState = () => useContext(UploadStateContext)

export const UploadDispatchProvider = UploadDispatchContext.Provider
export const useUploadDispatch = () => useContext(UploadDispatchContext)
