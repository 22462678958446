import { isCancel, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import type { FirebaseError } from 'firebase/app'

function isFirebaseError(error?: Error | AxiosError | FirebaseError): error is FirebaseError {
  if (!error) return false
  return !(error as AxiosError).isAxiosError && !!(error as FirebaseError).code
}
function isAxiosError(error?: Error | AxiosError | FirebaseError): error is AxiosError {
  if (!error) return false
  return !!(error as AxiosError).isAxiosError
}
export class BackendError extends Error {
  static is(error: any): error is BackendError {
    return error instanceof BackendError
  }

  axios?: AxiosError
  firebase?: FirebaseError
  errors?: Record<string, string>

  constructor(
    message: string,
    options?: { cause: AxiosError | FirebaseError | Error },
    errors?: Record<string, string>,
  ) {
    super(getBackendMessage(options?.cause) ?? message, { ...options })
    const error = options?.cause
    if (isAxiosError(error)) {
      this.axios = error
    } else if (isFirebaseError(error)) {
      this.firebase = error
    }
    this.errors = errors
  }

  get response(): AxiosResponse | undefined {
    return this.axios?.response
  }
  get config(): AxiosRequestConfig | undefined {
    return this.axios?.config
  }
  get isAuth(): boolean {
    return this.axios?.response?.status === 403
  }
  get isCancelled() {
    return this.axios && isCancel(this.axios)
  }
}

function getBackendMessage(error?: Error): string | undefined {
  if (isAxiosError(error)) {
    const data: { message?: string; detailed?: string; error?: string } | string =
      error?.response?.data ?? {}
    return data?.message ?? data?.detailed ?? data?.error
  }
  if (isFirebaseError(error)) {
    return error.message
  }
}
