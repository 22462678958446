import { createContext, FC, ReactNode, useContext, useMemo } from 'react'

type Value = {
  started(): void
  failed(): void
  completed(): void
  disable(): void
  enable(): void
  pause(): () => void
}

const noop = () => {}

export const LoadingContext = createContext<Value>({
  started: noop,
  failed: noop,
  completed: noop,
  disable: noop,
  enable: noop,
  pause: () => noop,
})

interface Props {
  loadingSetter: (loading: boolean) => void
  activeSetter: (loading: boolean) => void
  children?: ReactNode | ReactNode[]
}

export const LoadingContextProvider: FC<Props> = ({ loadingSetter, activeSetter, children }) => {
  const api = useMemo(
    () => ({
      started: () => loadingSetter(true),
      failed: () => loadingSetter(false),
      completed: () => loadingSetter(false),
      disable: () => activeSetter(false),
      enable: () => activeSetter(true),
      pause: () => {
        activeSetter(false)
        return () => activeSetter(true)
      },
    }),
    [loadingSetter, activeSetter],
  )

  return <LoadingContext.Provider value={api}>{children}</LoadingContext.Provider>
}

export function wrapInLoadingContext<A extends [], R>(
  context: Value,
  fn: (...args: A) => Promise<R>,
) {
  return async (...args: A): Promise<R> => {
    context.started()
    try {
      const result = await fn(...args)
      context.completed()
      return result
    } catch (e) {
      context.failed()
      throw e
    }
  }
}

export function useLoadingContextWrapper<A extends [], R>(fn: (...args: A) => Promise<R>) {
  const context = useContext(LoadingContext)
  return wrapInLoadingContext(context, fn)
}
