import { CityField } from './city'
import { PostalCode } from './postal-code'
import { RegionField } from './region'
import { StreetAddressField } from './street-address'

export const AddressField = {
  Street: StreetAddressField,
  City: CityField,
  Zip: PostalCode,
  State: RegionField,
}
