import { InputHTMLAttributes, ReactNode } from 'react'
import { BaseHtmlValidationInput, BaseHtmlValidationProps } from './base-html-validation-input'
import { BaseInputProps } from './base-input'

type ElementType = HTMLInputElement | HTMLTextAreaElement
type OverriddenAttrs<T> = Omit<T, keyof BaseInputProps<string>>

export type TextInputProps = OverriddenAttrs<InputHTMLAttributes<HTMLInputElement>> &
  BaseHtmlValidationProps<string>

export abstract class BaseTextInput<TElement extends ElementType> extends BaseHtmlValidationInput<
  string,
  TextInputProps,
  TElement
> {
  isValid() {
    //! with 'minLength' ValidityState.tooShort returns false-positive validity in Chrome
    if (isInputValueTooShort(this.props.minLength, this.element)) {
      return false
    }
    return super.isValid()
  }

  parse(value: any): string | null {
    const stringValue = String(value)
    return this.props.type === 'password' ? stringValue : stringValue.trim()
  }

  checkCustomValidity(value = this.getValue()) {
    return (
      super.checkCustomValidity(value) ||
      (isInputValueTooShort(this.props.minLength, this.element) &&
        `Enter at least ${this.props.minLength} characters`) ||
      ''
    )
  }

  getValidationMessage() {
    return this.checkValidityProps(VALIDITY_PROPS) || this.checkCustomValidity()
  }

  abstract render(): ReactNode
}

const VALIDITY_PROPS: (keyof ValidityState)[] = [
  'valueMissing',
  'typeMismatch',
  'patternMismatch',
  'tooLong',
  'tooShort',
]

function isInputValueTooShort(minLength?: number, element?: ElementType): boolean {
  if (minLength === undefined || element === undefined) return false
  return Number.isFinite(+minLength) && element.value.length < +minLength
}
