import { AxiosResponse, AxiosError } from 'axios'
import { Log } from 'utils/log'

export function logSuccessResponse(response: AxiosResponse) {
  if (import.meta.env.MODE !== 'development') return response
  try {
    const {
      config: { method, baseURL, url },
      request: { responseURL },
    } = response ?? {}

    const fullURL = responseURL ?? `${baseURL}${url}`

    const requestData = tryParseJSON(response?.config?.data)
    const requestText = requestData ? JSON.stringify(requestData, null, 2) : ''

    new Log()
      .text(`✔ ${method?.toUpperCase()} ${url}`, CSS_SUCCESS)
      .groupCollapsed()
      .stringValue(`${method?.toUpperCase()} ${fullURL}`)
      .stringValue(requestText)
      .objectValue('response', response?.data)
      .log()
      .title('axios response object')
      .objectValue('', response)
      .groupEnd()
  } catch (e) {
    //
  }
  return response
}

export function logErrorResponse(error: AxiosError) {
  try {
    const { url, data, baseURL, headers, method } = error.config ?? {}
    const fullURL = error.request?.responseURL ?? `${baseURL}${url}`
    new Log()
      .text(`✘ ${error.response?.status} ${method?.toLocaleUpperCase()} ${url}`, CSS_ERROR)
      .group()

      .title('request')
      .stringValue(`${method?.toUpperCase()} ${fullURL}`)
      .objectValue('data', tryParseJSON(data))
      .objectValue('request headers', headers)
      .log()

      .title('response')
      .stringValue(`${error.response?.status}: ${error.message}`)
      .objectValue('response data', error.response?.data)
      .objectValue('response headers', error.response?.headers)
      .log()

      .title('axios response object')
      .objectValue('', error.response)
      .log()
      .groupEnd()
  } catch (e) {
    //
  }
  return error
}

const CSS_ERROR = `
  color:red;
  font-weight:normal;
`
const CSS_SUCCESS = `
  color:#07bc0c;
  font-weight:normal;
`

const tryParseJSON = (json: any) => {
  if (typeof json !== 'string') return json
  try {
    return JSON.parse(json)
  } catch (e) {
    return json
  }
}
