import { US } from './const'
import states from './states.json'
import { useField } from '../../form/context'
import { Options } from '../../utils/options'
import { BaseFieldProps } from '../_base'
import { Select } from '../select'
import { TextField } from '../text'

type Label = BaseFieldProps<string>['label']
type RegionProps = Omit<BaseFieldProps<string>, 'label'> & {
  autoCompletePrefix?: string
  country?: string
  countryField?: string
  label?: Label | Func<Label>
}

export const US_STATE_OPTIONS: Options<string> = Object.fromEntries(
  states.map(({ code, name }) => [name, code]),
)

const VALIDATION_MESSAGE: Partial<Record<keyof ValidityState, string>> = {
  valueMissing: 'Please select a region.',
}

const US_VALIDATION_MESSAGE: Partial<Record<keyof ValidityState, string>> = {
  valueMissing: 'Please select a state.',
}

const US_LABEL = 'State'
const LABEL = 'State / Province'
const getLabel = (country?: string | null) => (country?.toUpperCase() === US ? US_LABEL : LABEL)

export const RegionField: React.FC<RegionProps> = ({
  country: _country,
  required,
  autoCompletePrefix,
  label = getLabel,
  countryField,
  ...props
}) => {
  const countryState = useField(countryField ?? props.name)
  const country = _country ?? countryState?.value ?? null
  const isUS = country?.toUpperCase() === US
  const fullLabel = callWhenAble<Label>(label, country?.toUpperCase())
  const ownProps = {
    label: fullLabel,
    placeholder: `Enter ${fullLabel}`,
    autoComplete: [autoCompletePrefix, 'address-level1'].filter(Boolean).join(' '),
  }

  return isUS ? (
    <Select
      {...props}
      {...ownProps}
      required={required}
      options={US_STATE_OPTIONS}
      validationMessages={US_VALIDATION_MESSAGE}
    />
  ) : (
    <TextField
      {...props}
      {...ownProps}
      required={required}
      validationMessages={VALIDATION_MESSAGE}
    />
  )
}

RegionField.displayName = 'RegionField'

type Func<Res> = (...args: any[]) => Res

function callWhenAble<T, Arg = T | Func<T>>(
  fn: Arg,
  ...args: Arg extends Func<T> ? Parameters<Arg> : any[]
): T {
  return typeof fn === 'function' ? fn(...args) : fn
}
