import { ComponentProps } from 'react'
import { DatetimeLocalInput } from '../../input-datetime-local'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type Props = DatetimeLocalInput.Props &
  BaseFieldProps<DatetimeLocalInput.Value> &
  Pick<ComponentProps<'input'>, 'step' | 'min' | 'max' | 'autoComplete'>

export class Field extends BaseField<string, Props> {
  static displayName = 'DatetimeLocalField'

  renderInput(props: DatetimeLocalInput.Props) {
    return <DatetimeLocalInput {...props} />
  }
}

export const DatetimeLocalField = withFormDefaultValues<DatetimeLocalInput.Value, Props>(Field)
