import { IconUpload } from 'icons'
import { ComponentProps, MouseEventHandler } from 'react'
import { cn } from 'utils'
import styles from './field-dropzone.module.scss'
import { Description } from '../../../description'
import { FileInput } from '../../input-file'
import { BaseField, BaseFieldProps, withFormDefaultValues } from '../_base'

type FileInputProps = ComponentProps<typeof FileInput>
type Props = FileInputProps &
  BaseFieldProps<FileInput.Value> & {
    entityLabel?: string
    extraDescription?: string
    wrapperClassName?: string
  }

export class Field extends BaseField<File | File[], Props> {
  static displayName = 'FieldDropzone'

  clear: MouseEventHandler = (event) => {
    const input = this.element?.current as FileInput | undefined
    if (!input) return
    event.preventDefault()
    event.stopPropagation()
    input.setValue(null)
  }

  get className() {
    return cn(styles.field, this.props.className)
  }
  get inputClassName() {
    return styles.input
  }

  getInputProps() {
    const { wrapperClassName, ...props } = super.getInputProps()
    return props
  }

  renderInput({
    className,
    multiple,
    accept,
    entityLabel = 'files',
    extraDescription,
    ...props
  }: FileInputProps & Pick<Props, 'entityLabel' | 'extraDescription'>) {
    return (
      <div className={cn(className, styles.dropzone, this.props.wrapperClassName)}>
        {props.disabled ? null : (
          <>
            <FileInput {...props} multiple={multiple} className={styles.input} accept={accept} />
            <Description className={styles.content}>
              <IconUpload className={styles.icon} aria-hidden="true" />
              <span>
                {`Drag & drop ${entityLabel} here`}
                <br /> or <span className={styles.upload}>{`choose ${entityLabel}`}</span>
              </span>
              {extraDescription && <div>{extraDescription}</div>}
            </Description>
          </>
        )}
      </div>
    )
  }
}

export const FieldDropzone = withFormDefaultValues<FileInput.Value, Props>(Field)
