import { ButtonHTMLAttributes, forwardRef } from 'react'
import { cn } from 'utils'
import styles from './icon-button.module.scss'

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
}

/**
 * `--icon-size` for the inner icon.\
 * `--button-size` equals `var(--icon-size) + var(--gap-tiny)`.\
 *
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, ...props }, ref) => (
    <button type="button" {...props} className={cn(styles.iconButton, className)} ref={ref} />
  ),
)

IconButton.displayName = 'IconButton'
