import { useId, useContext, useState, useLayoutEffect } from 'react'
import { FieldState, FormFieldEmitter, useField, useFormProps } from '../../form/context'
import { RecentState, isEqualState } from '../../utils/field-state'

interface Props<T> {
  name: string
  defaultValue?: T
  readOnly?: boolean
  disabled?: boolean
  id?: string
  touched?: boolean
  validationMessage?: string | null
  valid?: boolean
}

export function useFormEmitter<Data extends object>() {
  return useContext(FormFieldEmitter) as (state: FieldState<Data, keyof Data>) => Promise<void>
}

export function useFieldControl<T>({ name, ...props }: Props<T>) {
  type Data = Record<typeof name, T>
  const genId = useId()
  const formProps = useFormProps()
  const { touched, validationMessage } = useField(name) ?? {}
  const defaultValue =
    formProps.defaultValues && formProps.defaultValues.hasOwnProperty(name)
      ? (formProps.defaultValues as Record<typeof name, any>)[name]
      : props.defaultValue
  //   const [initialDefaultValue, setInitialDefaultValue] = useState(defaultValue)

  const readOnly = !!(formProps.readOnly || props.readOnly)
  const disabled = !!(formProps.disabled || props.disabled)

  const [state, dangerouslySetState] = useState<RecentState<T>>({
    value: defaultValue,
    valid: props.disabled ? true : !props.validationMessage,
    enable: !props.disabled,
    validationMessage: props.disabled ? null : props.validationMessage ?? null,
    defaultValue,
    touched: false,
  })

  const emitChanges = useFormEmitter<Data>()

  const setState = (update: Partial<RecentState<T>>) => {
    const newState = { ...state, ...update }
    if (isEqualState(state, newState)) return
    dangerouslySetState(newState)
  }

  useLayoutEffect(() => {
    emitChanges({ name, ...state })
    return () => {
      emitChanges({ name, enable: false, validationMessage: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, state])

  return [
    {
      defaultValue,
      readOnly,
      disabled,
      ...props,
      id: [formProps.id, props.id ?? genId].join('-'),
      touched,
      validationMessage,
      genId,
    },
    setState,
  ] as const
}
