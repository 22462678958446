import { MimeType } from 'api/document'
import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './files-preview.module.scss'
import { Card } from '../../card'
import { FileIcon } from '../../file-icon'
import { KeyValuePair } from '../../key-value-pair'

interface Props extends Omit<ComponentProps<typeof Card>, 'children'> {
  file: File
  onRemove?: () => any
}
export const FilePreview: FC<Props> = ({ className, onRemove, file, ...props }) => {
  return (
    <Card {...props} className={cn(styles.card, className)} onRemove={onRemove}>
      <KeyValuePair label="Document Name" icon={<FileIcon type={file.type as MimeType} />}>
        {file.name}
      </KeyValuePair>
    </Card>
  )
}
