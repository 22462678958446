import { FC, ReactNode } from 'react'
import { cn } from 'utils'
import styles from './badge.module.scss'

const enum Severnity {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}
type Props = {
  children?: ReactNode
  amount?: number
  className?: string
  severnity?: `${Severnity}`
}

/**
 * CSS Custom Properties:\
 * `--size` default: var(--badge-size, 1.5rem)\
 * `--color` default: var(--color-success)\
 * `--border-color` default: currentColor\
 * `--border-width` default: 2px
 */
export const Badge: FC<Props> = ({ children, className, amount, severnity = Severnity.info }) => {
  if (amount === 0 || (!amount && !children)) return null
  return (
    <span className={cn(styles.badge, SEVERNITY[severnity], className)}>{amount ?? children}</span>
  )
}

const SEVERNITY = {
  [Severnity.info]: styles.info,
  [Severnity.success]: styles.success,
  [Severnity.warning]: styles.warning,
  [Severnity.error]: styles.error,
}
